import axios from "axios";
import { IFilterAppUsers } from "../../../interfaces/Filters/IFilterAppUsers";
import { IRecordModalidad } from "../../../interfaces/Records/IRecordModalidad";
import { IResponseRecord } from "../../../interfaces/Responses/IResponseRecord";
import { IResponseRecords } from "../../../interfaces/Responses/IResponseRecords";
import { ServiceConnectorAPIBase } from "../../Core/ServiceConnectorAPIBase/ServiceConnectorAPIBase";
import { IServiceConnectorModalidades } from "./IServiceConnectorModalidades";

export class ServiceConnectorModalidades extends ServiceConnectorAPIBase implements IServiceConnectorModalidades {

    constructor() {
        super("v1/modalidades");

    }

    public async Add(record: IRecordModalidad): Promise<IResponseRecord<IRecordModalidad>> {

        const response = await axios.post<IResponseRecord<IRecordModalidad>>(this.BaseUrl, {
            "nombre": record.nombre,
            "clave": record.clave,
            "descripcion": record.descripcion,
            "active": record.active,
        });
        return response.data;

    }

    public async Update(record: IRecordModalidad): Promise<IResponseRecord<IRecordModalidad>> {

        const response = await axios.put<IResponseRecord<IRecordModalidad>>(`${this.BaseUrl}`, {
            "chainId": record.chainId,
            "nombre": record.nombre,
            "clave": record.clave,
            "descripcion": record.descripcion,
            "active": record.active,
        });
        return response.data;

    }

    public async GetByUniversidaId(universidadId: string): Promise<IResponseRecords<IRecordModalidad>> {

        const response = await axios.get<IResponseRecords<IRecordModalidad>>(`${this.BaseUrl}/universidad/${universidadId}`);
        console.info(response.data);
        return response.data;

    }

    public async GetByUniversidadLineaNegocioId(universidadId: string, lineaNegocioId: string): Promise<IResponseRecords<IRecordModalidad>> {

        const response = await axios.get<IResponseRecords<IRecordModalidad>>(`${this.BaseUrl}/universidad/${universidadId}/lineanegocio/${lineaNegocioId}`);
        console.info(response.data);
        return response.data;

    }

    public async GetAll(): Promise<IResponseRecords<IRecordModalidad>> {

        const response = await axios.get<IResponseRecords<IRecordModalidad>>(this.BaseUrl);
        console.info(response.data);
        return response.data;

    }

    public async GetActive(): Promise<IResponseRecords<IRecordModalidad>> {

        const response = await axios.get<IResponseRecords<IRecordModalidad>>(this.BaseUrl);
        console.info(response.data);
        return response.data;

    }

    public async GetById(id: number): Promise<IResponseRecords<IRecordModalidad> | undefined> {

        const response = await axios.get<IResponseRecords<IRecordModalidad>>(this.BaseUrl);
        console.info(response.data);
        return response.data;

    }

    public async GetByChainId(id: string): Promise<IResponseRecord<IRecordModalidad>> {

        const response = await axios.get<IResponseRecord<IRecordModalidad>>(`${this.BaseUrl}/${id}`);
        console.info(response.data);
        return response.data;

    }

    public async GetByFilter(filter: IFilterAppUsers): Promise<IResponseRecords<IRecordModalidad>> {

        const response = await axios.get<IResponseRecords<IRecordModalidad>>(this.BaseUrl);
        console.info(response.data);
        return response.data;

    }


}