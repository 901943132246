import axios from "axios";
import { IFilterAppUsers } from "../../../interfaces/Filters/IFilterAppUsers";
import { IResponseRecord } from "../../../interfaces/Responses/IResponseRecord";
import { IResponseRecords } from "../../../interfaces/Responses/IResponseRecords";
import { ServiceConnectorAPIBase } from "../../Core/ServiceConnectorAPIBase/ServiceConnectorAPIBase";
import { IServiceConnectorLineaNegocio } from "./IServiceConnectorLineaNegocio";
import { IRecordLineaNegocio } from "../../../interfaces/Records/IRecordLineaNegocio";



export class ServiceConnectorLineaNegocio extends ServiceConnectorAPIBase implements IServiceConnectorLineaNegocio {

    constructor() {
        super("v1/lineaNegocio");

    }

    public async Add(record: IRecordLineaNegocio): Promise<IResponseRecord<IRecordLineaNegocio>> {

        const response = await axios.post<IResponseRecord<IRecordLineaNegocio>>(this.BaseUrl, {
            active: record.active,
            clave: record.clave,
            equivalencia: record.equivalencia,
            prioridad: record.prioridad,
            nombre: record.nombre,
        });
        return response.data;

    }

    public async Update(record: IRecordLineaNegocio): Promise<IResponseRecord<IRecordLineaNegocio>> {

        const response = await axios.put<IResponseRecord<IRecordLineaNegocio>>(`${this.BaseUrl}`, {
            chainId: record.chainId,
            active: record.active,
            clave: record.clave,
            equivalencia: record.equivalencia,
            prioridad: record.prioridad,
            nombre: record.nombre,
        });
        return response.data;

    }

    public async GetAll(): Promise<IResponseRecords<IRecordLineaNegocio>> {

        const response = await axios.get<IResponseRecords<IRecordLineaNegocio>>(this.BaseUrl);
        console.info(response.data);
        return response.data;

    }

    public async GetActive(): Promise<IResponseRecords<IRecordLineaNegocio>> {

        const response = await axios.get<IResponseRecords<IRecordLineaNegocio>>(this.BaseUrl);
        console.info(response.data);
        return response.data;

    }

    public async GetById(id: number): Promise<IResponseRecords<IRecordLineaNegocio> | undefined> {

        const response = await axios.get<IResponseRecords<IRecordLineaNegocio>>(this.BaseUrl);
        console.info(response.data);
        return response.data;

    }

    public async GetByChainId(id: string): Promise<IResponseRecord<IRecordLineaNegocio>> {

        const response = await axios.get<IResponseRecord<IRecordLineaNegocio>>(`${this.BaseUrl}/${id}`);
        console.info(response.data);
        return response.data;

    }


    public async GetByUniversidaId(universidadId: string): Promise<IResponseRecords<IRecordLineaNegocio>> {

        const response = await axios.get<IResponseRecords<IRecordLineaNegocio>>(`${this.BaseUrl}/universidad/${universidadId}`);
        console.info(response.data);
        return response.data;

    }

    public async GetByFilter(filter: IFilterAppUsers): Promise<IResponseRecords<IRecordLineaNegocio>> {

        const response = await axios.get<IResponseRecords<IRecordLineaNegocio>>(this.BaseUrl);
        console.info(response.data);
        return response.data;

    }


}