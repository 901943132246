import { IRecordLineaNegocio } from "../../../interfaces/Records/IRecordLineaNegocio"
import { ITableCoreEvents } from "../../Shared/_TableCore/ITableCoreEvents";
import { TableBaseContainer } from "../../Shared/_TableCore/TableBaseContainer";
import { TableFormatAdminLineaNegocio } from "./TableFormatAdminLineaNegocio";


export const TableAdminLineaNegocio = ({

    records,
    OnEditRecord

}: ITableCoreEvents<IRecordLineaNegocio>) => {


    return (
        <TableBaseContainer<IRecordLineaNegocio>
            headers={["Acciones", "Nombre", "Clave", "Equivalencia", "Prioridad", "Activo", "Creado", "Modificado"]}
            records={records}
            OnEditRecord={OnEditRecord}
            TableRecordFormat={TableFormatAdminLineaNegocio}
            id="table-admin-linea-negocio"
        />
    )


}


