import { faSave, faUserPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useContextApiService } from "../../../../../contexts/ContextApiService/ContextApiService";
import { defaultRecordRecinto } from "../../../../../data/defaults/records/defaultRecordRecinto";
import { usePropChange } from "../../../../../hooks/Shared/usePropChange/usePropChange";
import { useValidateRecinto } from "../../../../../hooks/validators/useValidateRecinto/useValidateRencinto";
import { IRecordRecinto } from "../../../../../interfaces/Records/IRecordRecinto";
import { FormAdminRecinto } from "../../../../Forms/Admin/FormAdminRecinto/FormAdminRecinto";
import { IndicatorHeaderSection } from "../../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";
import { useModalDialogWindow } from "../../../../Shared/ModalDialog/hook/useModalDialogWindow";

export const PageAdminCampusNew = () => {

    const { id } = useParams();
    const { Record, SetRecord } = usePropChange<IRecordRecinto>(defaultRecordRecinto());
    const { ApiService } = useContextApiService();
    const { ShowModalEnviando, ShowModalError, ShowModalSuccessNew } = useModalDialogWindow();
    const { validate: validateRecinto } = useValidateRecinto();
    const navigate = useNavigate();

    const onRecordChange = (record: IRecordRecinto) => {

        if (id !== undefined) {

            record.universidadId = id;
            SetRecord(record);

        }

    }

    const onSave = async () => {

        ShowModalEnviando();
        const response = await ApiService.Admin.Recintos.Add(Record);

        if (!response.success) {

            const errorMesage = response.error ?? "Error al guardar el registro";
            ShowModalError(errorMesage);
            return;

        }

        ShowModalSuccessNew();
        navigate("../");

    }

    return (
        <Grid container  >
            <IndicatorHeaderSection
                title="Nuevo Registro"
                subtitle="Complete los campos para agregar un registro"
                icon={faUserPlus}
            />
            <Grid item xs={12}>
                <FormAdminRecinto
                    onRecordChanged={onRecordChange}
                />
            </Grid>
            <Grid item xs={12} container direction="row-reverse" sx={{ mt: "2rem" }}>
                <Grid item xs={12} md={4}>
                    <Button variant="contained" fullWidth disabled={!validateRecinto(Record)} onClick={onSave}>
                        <Typography variant="button">
                            <FontAwesomeIcon icon={faSave} /> Guardar
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )

}