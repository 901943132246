import styled from "@emotion/styled";
import { TextField } from "@mui/material";


export const TextFieldWhite = styled(TextField)({
    backgroundColor: 'white',
    '& fieldset': {
        borderColor: 'white',
    },
    '&:hover fieldset': {
        borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
        borderColor: 'white',
    },
});