import { defaultRecordAppUserDetails } from "../../data/defaults/records/defaultRecordAppUserDetails";
import { IRecordAppUserDetails } from "../Records/IRecordAppUserDetails";
import { IRecordModuleList } from "../Records/IRecordModuleList";
import { IResponseBase } from "./IResponseBase";



export interface IResponseAuth extends IResponseBase {

    id: string;
    token: string;
    email: string;
    appUserDetails: IRecordAppUserDetails;
    userNavigation: IRecordModuleList[];
    universidades: string[];

}


export const defaultResponseAuth = (): IResponseAuth => {

    return {
        id: "",
        token: "",
        email: "",
        appUserDetails: defaultRecordAppUserDetails(),
        success: false,
        userNavigation: [],
        universidades: []
    }

}