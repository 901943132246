import { Grid, Typography } from "@mui/material";
import { ControlHeaderLogo } from "../../Controls/ControlHeaderLogo/ControlHeaderLogo";
import { FormModalidadPicker } from "../../Forms/User/FormModalidadPicker/FormModalidadPicker";
import { useContextUniversidad } from "../../../contexts/ContextUniversidad/ContextUniversidad";



export const SectionCarreraSelector = () => {

    const {
        Universidad,
        ConfigStyle,
        LineaNegocio,
        UpdateContextProp
    } = useContextUniversidad();

    return (
        <Grid id="seleccion-carrera" container sx={{ minHeight: "100vh", alignContent: "flex-start" }}>
            <Grid item xs={12}>
                <ControlHeaderLogo title="Carreras Disponibles" />
            </Grid>
            <Grid item container xs={12} sx={{
                height: {
                    xs: "auto",
                    md: "100%"
                }
            }}>
                <Grid item xs={12} md={6} sx={{
                    background: `url('${ConfigStyle.urlFondoCarreras}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    height: {
                        xs: "10rem",
                        md: "100%"
                    }
                }} />
                <Grid item xs={12} md={6}>
                    <FormModalidadPicker
                        universidadId={Universidad.chainId}
                        lineanegocioId={LineaNegocio.chainId}
                        onRecordChanged={() => { }}
                        onCarreraChanged={(record) => {
                            UpdateContextProp("ConfigCarreraTemp", record);
                        }}
                        onModalidadChanged={(record) => {

                            UpdateContextProp("Modalidad", record);

                        }}
                    />
                </Grid>
            </Grid>
        </Grid>

    )

}