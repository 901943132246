import { TableCell, TableCellProps, styled, tableCellClasses } from "@mui/material";




export const TableDataCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));
