import { Typography } from "@mui/material"
import moment from "moment"
import { FORMATS } from "../../../config/FORMATS"
import { TableDataCell } from "../../Shared/_TableCore/TableDataCell"
import { ITableDataDateFormatProps } from "./ITableDataDateFormatProps";

export const TableDataDateFormat = ({

    date

}: ITableDataDateFormatProps) => {

    return (
        <TableDataCell align="center">
            <Typography variant="body1">
                {date.format(FORMATS.DATE_FORMAT_TO_TXT)}
            </Typography>
        </TableDataCell>
    )

}