import { PropsWithChildren } from "react";
import { useContextAuthStateProvider } from "../contexts/ContextAuthState/ContextAuthState";
import { Navigate, useLocation } from "react-router-dom";
import { ROUTES_KEYS } from "./ROUTES_KEYS";


export const RouteAuthProtection = ({ children }: PropsWithChildren<{}>) => {

    const { AuthState } = useContextAuthStateProvider();
    const location = useLocation();

    console.info("hasToken", AuthState.HasToken);

    return (
        <>
            {
                AuthState.HasToken ? (
                    <>
                        {children}
                    </>
                ) : (
                    <Navigate to={`/${ROUTES_KEYS.ROOT_LOGIN}`} state={{ from: location }} />
                )
            }
        </>
    );

}