import { Grid, Typography } from "@mui/material";
import { ControlHeaderLogo } from "../../Controls/ControlHeaderLogo/ControlHeaderLogo";
import { FormUserCampusPicker } from "../../Forms/User/FormUserCampusPicker/FormUserCampusPicker";
import { useContextUniversidad } from "../../../contexts/ContextUniversidad/ContextUniversidad";



export const SectionCampusSelector = () => {

    const {
        Universidad,
        ConfigCarreraTemp,
        UpdateContextProp
    } = useContextUniversidad();

    return (
        <Grid id="seleccion-campus" container sx={{ minHeight: '100vh', alignContent: "flex-start" }}>
            <Grid item xs={12}>
                <ControlHeaderLogo title="Campus Disponibles" />
            </Grid>
            <Grid item xs={12} container spacing={3}>
                <Grid item xs={12} textAlign="center">
                    <Typography variant="h5" sx={{ paddingTop: "3rem", paddingBottom: "3rem" }}>
                        3. Selecciona el campus de interes
                    </Typography>
                </Grid>
                <Grid item xs={12} container spacing={3}>
                    <Grid item xs={12}>
                        <FormUserCampusPicker
                            carreraId={ConfigCarreraTemp.chainId}
                            universidadId={Universidad.chainId}
                            onRecordChanged={(record) => {
                                UpdateContextProp("Campus", record);
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}