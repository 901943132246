import { Button, Paper, Typography } from "@mui/material";
import { useContextUniversidad } from "../../../contexts/ContextUniversidad/ContextUniversidad"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneCircle } from "@fortawesome/pro-light-svg-icons";
import { APIWebHookConnector, GAEVENTNAMES, GAReporterDataContanier, GAnalReporter } from "../../../utils/HooksUtils/HooksUtils";
import { IRecordCarreraConfig } from "../../../interfaces/Records/IRecordCarreraConfig";



export interface IControlWhatsAppButtonProps {

    record: IRecordCarreraConfig;

}


export const ControlWhatsAppButton = ({
    record
}: IControlWhatsAppButtonProps) => {

    const {

        Universidad,
        ConfigStyle,
        AppUser,
        Modalidad,
        LineaNegocio,
        Campus,
        ConfigCarreraTemp


    } = useContextUniversidad();


    const createEspecialMessage = (): string => {

        const baseLinea: string = LineaNegocio.equivalencia;
        const modalidad: string = Modalidad.nombre;
        const chainId: string = Universidad.chainId;

        if (chainId !== "7033102e-e0f5-4a2c-a8e9-10c694f0bd2a") {
            return baseLinea;
        }

        if (baseLinea === "Licenciatura Escolarizada") {

            switch (modalidad) {
                case "Mixto":
                    return "Licenciatura Ejecutiva";
                case "Sabatino":
                    return "Licenciatura Ejecutiva";
                case "Online":
                    return "Licenciatura Online";
                default:
                    return baseLinea;
            }

        }

        if (baseLinea === "Ciencias de la Salud") {

            switch (modalidad) {
                case "Nivelatorio":
                    return "Nivelatorio";
                default:
                    return baseLinea;
            }

        }

        if (baseLinea === "Posgrado") {

            switch (modalidad) {
                case "Online":
                    return "Posgrado Online";
                default:
                    return baseLinea;
            }

        }

        return baseLinea;

    }


    const createMessage = (): string => {

        // const canal = AppState.OriginURL ? AppState.OriginURL : "Sitio Web";
        const canal = "Sitio Web";
        let message = `Hola vengo de su ${canal}. Me podrían proporcionar información de `;
        message += `${ConfigCarreraTemp.nombre} en su modalidad ${createEspecialMessage()}`;
        if (Campus.chainId != "") {
            message += ` en el plantel ${Campus.nombre}`;
        }
        message += ` [Calculadora]`;
        return message;

    };


    const formatWhatsMessage = (): string => {

        let message = createMessage();
        return encodeURIComponent(message);

    };

    const ReportEvents = async (resultRecord: IRecordCarreraConfig) => {

        GAnalReporter(
            GAEVENTNAMES.CLICK_WHATS_APP,
            new GAReporterDataContanier(
                Universidad.nombre,
                1
            ).ToJSON()
        );

        const crmReporter = new APIWebHookConnector();
        await crmReporter.CreateLeadRecord(
            Universidad.urlCrm,
            Universidad.nombre,
            AppUser.nombre,
            AppUser.telefono,
            LineaNegocio.equivalencia,
            Modalidad.nombre,
            ConfigCarreraTemp.nombre,
            window.location.href,
            createMessage(),
            Campus.nombre,
            resultRecord.costoTotal,
            ""
            // AppState.OriginURL ? AppState.OriginURL : "",
        );


    }

    return (
        <Button
            color="success"
            variant="contained"
            target="_blank"
            href={`https://wa.me/521${Universidad.whatsapp}?text=${formatWhatsMessage()}`}
            onClick={() => { ReportEvents(record) }}
        >
            <Typography variant="subtitle1">
                <FontAwesomeIcon icon={faPhoneCircle} size="2x" /> Quiero más información
            </Typography>
        </Button>
    )


}