import { faPlusCircle, faUsers } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useContextApiService } from "../../../../../contexts/ContextApiService/ContextApiService";
import { useIsLoading } from "../../../../../hooks/Shared/useIsLoading/useIsLoading";
import { IRecordUniversidad } from "../../../../../interfaces/Records/IRecordUniversidad";
import { ROUTES_KEYS } from "../../../../../routes/ROUTES_KEYS";
import { IndicatorHeaderSection } from "../../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";
import { TableAdminUniversidad } from "../../../../Tables/TableAdminUniversidades/TableAdminUniversidades";

export const PageAdminUniversidadesHome = () => {

    const { ApiService } = useContextApiService();
    const { isLoading, setIsLoading } = useIsLoading(true);
    const [users, setUsers] = useState<IRecordUniversidad[]>([]);
    const navigate = useNavigate();


    useEffect(() => {

        const getUsers = async () => {

            const response = await ApiService.Admin.Universidades.GetAll();
            console.info("Usuarios", response.registros);
            setUsers(response.registros);
            setIsLoading(false);

        };

        getUsers().catch(console.error);

    }, []);


    return (
        <Grid container  >
            <IndicatorHeaderSection
                title="Universidades"
                icon={faUsers}
                color="secondary"
                subtitle="Gestión de Universidades."
            />
            <Grid container item xs={12}>
                <Grid item xs={12} md={4}>
                    <Button
                        component={Link}
                        to={ROUTES_KEYS.ROOT_ADM_NEW}
                        variant="contained"
                        color="primary"
                        fullWidth>
                        <Typography variant="button">
                            <FontAwesomeIcon icon={faPlusCircle} /> Crear Registro
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <IndicatorHeaderSection
                    title="Universidades Registradas"
                    icon={faUsers}
                    color="warning"
                />
                {
                    isLoading ? (
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    ) : (
                        <TableAdminUniversidad
                            records={users}
                            OnEditRecord={(record) => {
                                console.log("Edit", record);
                                navigate(`${ROUTES_KEYS.ROOT_ADM_EDIT}/${record.chainId}`);
                            }}
                        />
                    )
                }
            </Grid>
        </Grid>
    );

}