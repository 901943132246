import { faNewspaper, faUniversity, faTag } from "@fortawesome/pro-light-svg-icons";
import { MODULE_ADM_CATALOG } from "../../../config/MODULE_CATALOG";
import { IDrawerNavigationLink } from "../../../interfaces/Navigation/IDrawerNavigationLink";
import { ROUTES_KEYS } from "../../../routes/ROUTES_KEYS";



export const defaultNavDrawerAdminMenuPromos = (): IDrawerNavigationLink[] => {

    return [
        {
            moduleId: MODULE_ADM_CATALOG.PROMO_BANNERS,
            icon: faNewspaper,
            title: "Banners",
            path: ROUTES_KEYS.ROOT_ADM_PROMO_BANNERS
        },
        {
            moduleId: MODULE_ADM_CATALOG.PROMO_BECAS,
            icon: faUniversity,
            title: "Becas",
            path: ROUTES_KEYS.ROOT_ADM_PROMO_BECAS
        },
        {
            moduleId: MODULE_ADM_CATALOG.PROMO_PRECIO,
            icon: faTag,
            title: "Precios",
            path: ROUTES_KEYS.ROOT_ADM_PROMO_PRECIO
        },
    ];

};
