import { IStateModalWindow } from "./IStateModalWindow";


export const defaultModalWindowState = (): IStateModalWindow => {

    return {
        variant: "info",
        title: "",
        messages: [],
        onCancelClick: () => {
            console.info("Cancel Click");
        },
        onOkClick: () => {
            console.info("Ok Click");
        },
        isOpen: false
    };

};
