import moment from "moment";
import { IRecordUniversidadConfig } from "../../../interfaces/Records/IRecordUniversidadConfig";


export const defaultRecordUniversidadConfig = (): IRecordUniversidadConfig => {

    return {
        active: true,
        chainId: "",
        created: moment(),
        styleProps: "",
        updated: moment(),
        nombre: "",
        clave: "",
        universidadId: "",
        descripcion: "",
        isNew: true
    };


};


