import { IRecordPromoPrecio } from "../../../interfaces/Records/IRecordPromoPrecio";
import { IUseValidate } from "../IUseValidate";



export const useValidatePromoPrecio = (): IUseValidate<IRecordPromoPrecio> => {

    const validate = (record: IRecordPromoPrecio): boolean => {

        if (record.nombre.length <= 0) return false;
        if (record.descripcion.length <= 0) return false;
        if (record.tipoPrecioId.length <= 0) return false;
        if (record.porcentajeDescuento < 0 || record.porcentajeDescuento > 100) return false;
        if (record.vigenciaInicio.isAfter(record.vigenciaFin)) return false;
        if (record.vigenciaFin.isBefore(record.vigenciaInicio)) return false;

        return true;

    };

    return {
        validate
    };

};
