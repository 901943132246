import { IRecordPromoPrecio } from "../../../interfaces/Records/IRecordPromoPrecio";
import { ITableCoreEvents } from "../../Shared/_TableCore/ITableCoreEvents";
import { TableBaseContainer } from "../../Shared/_TableCore/TableBaseContainer";
import { TableFormatPromoPrecio } from "./TableFormatPromoPrecio";

export const TableAdminPromoPrecio = ({

    records,
    OnEditRecord

}: ITableCoreEvents<IRecordPromoPrecio>) => {


    return (
        <TableBaseContainer<IRecordPromoPrecio>
            headers={["Acciones", "Nombre", "Descripcion", "Tipo Precio Aplicable", "Porcentaje Descuento", "Vigencia Inicio", "Vigencia Fin", "Activo", "Creado", "Modificado"]}
            records={records}
            OnEditRecord={OnEditRecord}
            TableRecordFormat={TableFormatPromoPrecio}
            id="table-promos-precio"
        />
    )


}


