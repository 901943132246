import axios from "axios";
import moment from "moment";

export function GAnalReporter(eventName: string, eventParams?: any) {

    gtag('event', eventName, eventParams);

}


export const GAEVENTNAMES = {

    NUEVO_CONTACTO: "Nuevo Contacto",
    SELECCION_LINEA_NEGOCIO: "Seleccion Linea Negocio",
    SELECCION_MODALIDAD: "Seleccion Modalidad",
    SELECCION_PLANTEL: "Seleccion Plantel",
    SELECCION_CARRERA: "Seleccion Carrera",
    SELECCION_DURACION: "Seleccion Duracion",
    CLICK_WHATS_APP: "Click WhatsApp",
    CLICK_REGRESAR_SITIO: "Click Regresar Sitio",
    CLICK_COMENZAR: "Click Comenzar de Nuevo",

    PARAM_UNIVERSIDAD: "universidad_origen",
    PARAM_SELECION: "valor_seleccionado",

}

export class GAReporterDataContanier {

    uniData: string = "";
    value: any = null;

    constructor(uni: string, valor: any) {

        this.uniData = uni;
        this.value = valor;

    }


    public ToJSON() {

        return {
            "event_category": this.uniData,
            "event_label": this.value
        }

    }

}


export interface IAPIAutomateConnector {

    CreateLeadRecord: (
        urlPost: string, universidad: string, nombre: string, telefono: string,
        nivelEducativo: string, modalidad: string, carrera: string, currentUrl: string,
        mensajeDinamico: string, campus: string, precio: number, origin: string
    ) => Promise<void>;

}

export class APIWebHookConnector implements IAPIAutomateConnector {


    public async CreateLeadRecord(urlPost: string, universidad: string, nombre: string, telefono: string, nivelEducativo: string,
        modalidad: string, carrera: string, currentUrl: string, mensajeDinamico: string, campus: string, precio: number, origin: string): Promise<void> {

        const response = await axios.post(urlPost,
            {
                "universidad": universidad,
                "medio": "Digital",
                "nombre_completo": nombre,
                "campus": campus,
                "costo_lista": precio,
                "telefono": telefono,
                "nivel_educativo": nivelEducativo,
                "modalidad": modalidad,
                "carrera": carrera,
                "date_formatted": moment().format("DD/MM/YYYY"),
                "data_CRM": mensajeDinamico,
                "url": currentUrl,
                "utm_source": "",
                "utm_medium": "",
                "utm_campaign": "",
                "utm_term": "",
                "utm_content": "",
                "canal": origin === "" ? "Sitio Web" : origin,
                "origen": "Conversacion",
                "suborigen": "Calculadora"
            }

        );

        return;

    }


}