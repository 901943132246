import { useEffect, useState } from "react";
import { useContextApiService } from "../../../contexts/ContextApiService/ContextApiService";
import { useIsLoading } from "../../../hooks/Shared/useIsLoading/useIsLoading";
import { IRecordCarrera } from "../../../interfaces/Records/IRecordCarrera";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { defaultRecordCarrera } from "../../../data/defaults/records/defaultRecordCarrera";
import { useContextUniversidad } from "../../../contexts/ContextUniversidad/ContextUniversidad";
import { IRecordCarreraConfig } from "../../../interfaces/Records/IRecordCarreraConfig";
import { IControlListCarerrasProps } from "./IControlListCarerrasProps";


export const ControlListCarreras = ({

    modalidad,
    OnCarreraChange

}: IControlListCarerrasProps) => {

    const [carreras, setCarreras] = useState<IRecordCarrera[]>([]);
    const { Universidad, LineaNegocio, Modalidad } = useContextUniversidad();
    const { ApiService } = useContextApiService();
    const { isLoading, setIsLoading } = useIsLoading(true);
    const [selectedCarrera, setSelectedCarrera] = useState<IRecordCarrera>(defaultRecordCarrera());

    const onSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const selectedCarrera = carreras.find(carrera => carrera.chainId === event.target.value);
        setSelectedCarrera(selectedCarrera || defaultRecordCarrera());

    };


    useEffect(() => {

        if (selectedCarrera.chainId.length > 0) {

            OnCarreraChange(selectedCarrera);

        }

    }, [selectedCarrera]);



    useEffect(() => {

        if (modalidad.chainId.length > 0) {

            const getCarreras = async () => {

                const response = await ApiService.Admin.CarreraConfiguracion.GetByUniversidadLineaNegocioModalidad(
                    Universidad.chainId,
                    LineaNegocio.chainId,
                    Modalidad.chainId
                );

                if (response.error) {
                    return
                }

                const registrosCarreras: IRecordCarrera[] = response.registros.map((carrera: IRecordCarreraConfig) => {

                    const defailtCarrera = defaultRecordCarrera();
                    defailtCarrera.chainId = carrera.carreraId;
                    defailtCarrera.nombre = carrera.carreraNombre;

                    return defailtCarrera;

                });

                const carrerasById = registrosCarreras.reduce((acc: { [key: string]: IRecordCarrera }, carrera) => {

                    acc[carrera.chainId] = carrera;
                    return acc;

                }, {});

                const sortedCarreras = Object.values(carrerasById).sort((a, b) => a.nombre.localeCompare(b.nombre));

                setCarreras(sortedCarreras);
                setIsLoading(false);

            };

            getCarreras().catch(console.error);

        }

    }, [modalidad]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <FormControl>
                <RadioGroup
                    value={selectedCarrera.chainId}
                    onChange={onSelectionChange}
                >
                    {carreras.map(carrera => (
                        <FormControlLabel
                            key={carrera.chainId}
                            value={carrera.chainId}
                            control={<Radio />}
                            label={carrera.nombre}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </div>
    );

}