import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { ITableBaseContainerProps } from "./ITableBaseContainerProps";
import { TableDataCell } from "./TableDataCell";
import { TableRowCell } from "./TableRowCell";
import { faFileSearch } from "@fortawesome/pro-light-svg-icons";



export const TableBaseContainer = <TRecord,>({

    headers, records, TableRecordFormat, id, OnEditRecord, isReadOnly,
    defaultRowsPerPage = 5

}: ITableBaseContainerProps<TRecord>): JSX.Element => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);


    const handleChangePage = (event: unknown, newPage: number): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Grid item xs={12} container style={{ marginTop: "1.5rem" }}>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table sx={{ maxHeight: 700 }}>
                        <TableHead>
                            <TableRow>
                                {headers.map((header, index) => (
                                    <TableDataCell key={`${id}-tb-head-${index}`} align="center">
                                        {header}
                                    </TableDataCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {records.length === 0 ? (
                                <TableRowCell>
                                    <TableDataCell colSpan={headers.length} align="center">
                                        <FontAwesomeIcon icon={faFileSearch} size="5x" />
                                        <Typography variant="subtitle1">
                                            Sin Registros
                                        </Typography>
                                    </TableDataCell>
                                </TableRowCell>
                            ) : (
                                records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((record, index) => (
                                        <TableRecordFormat
                                            key={`${id}-tbl-rec-dat-${index}`}
                                            index={index}
                                            record={record}
                                            isReadOnly={isReadOnly}
                                            OnEditRecord={OnEditRecord} />
                                    ))
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={records.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage} />
                </TableContainer>
            </Grid>
        </Grid>
    );

};
