import { ReactElement } from "react";
import { IModalDialogProps } from "./IModalDialogProps";
import { faTimesCircle, faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogContent, DialogActions, Button, Typography } from "@mui/material";
import { useContextModalWindow } from "./ContextModalWindow/ContextModalWindow";

export const ModalDialog = ({

    isOpen,
    title,
    messages,
    okLabelButton,
    cancelLabelButton,
    OnOkClick,
    OnCancelClick,
    variant

}: IModalDialogProps): ReactElement => {


    const { AvaliableModals } = useContextModalWindow();
    const mStyle = AvaliableModals.GetInstance(variant);

    return (
        <>
            {
                mStyle &&
                <Dialog open={isOpen} maxWidth="sm" fullWidth>
                    {
                        mStyle.GetDialogTitle(title)
                    }
                    <DialogContent>
                        {
                            mStyle.GetContent(messages)
                        }
                    </DialogContent>
                    <DialogActions>
                        {
                            cancelLabelButton && OnCancelClick &&
                            <Button onClick={OnCancelClick} variant="contained" color="error">
                                <Typography variant="body1">
                                    <FontAwesomeIcon icon={faTimesCircle} /> {cancelLabelButton}
                                </Typography>
                            </Button>
                        }
                        {
                            okLabelButton && OnOkClick &&
                            <Button onClick={OnOkClick} variant="contained" color="success">
                                <Typography variant="body1">
                                    <FontAwesomeIcon icon={faCheckCircle} /> {okLabelButton}
                                </Typography>
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            }
        </>
    );

}