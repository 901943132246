import moment from "moment";
import { IRecordUniversidad } from "../../../interfaces/Records/IRecordUniversidad";

export const defaultRecordUniversidad = (): IRecordUniversidad => {

    return {
        active: true,
        chainId: "",
        clave: "",
        created: moment(),
        cssName: "",
        descripcion: "",
        nombre: "",
        showColegiatura: false,
        showPagoTotal: false,
        showPrimerPago: false,
        updated: moment(),
        urlCrm: "",
        urlLogo: "",
        urlPolitica: "",
        whatsapp: "",
    };

};


