import moment from "moment";
import { IRecordCarrera } from "../../../interfaces/Records/IRecordCarrera";



export const defaultRecordCarrera = (): IRecordCarrera => {

    return {
        active: true,
        chainId: "",
        clave: "",
        created: moment(),
        descripcion: "",
        lineaNegocioId: "",
        lineaNegocioNombre: "",
        nombre: "",
        updated: moment(),
        urlImagen: "",
    };

};


