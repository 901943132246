import { IRecordAppUserDetails } from "../../../interfaces/Records/IRecordAppUserDetails";


export const defaultRecordAppUserDetails = (): IRecordAppUserDetails => {

    return {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: ""
    };

};
