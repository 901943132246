import { faCogs, faHomeBlank } from "@fortawesome/pro-light-svg-icons";
import { MODULE_ADM_CATALOG } from "../../../config/MODULE_CATALOG";
import { IDrawerNavigationLink } from "../../../interfaces/Navigation/IDrawerNavigationLink";
import { ROUTES_KEYS } from "../../../routes/ROUTES_KEYS";


export const defaultNavDrawerAdminMenu = (): IDrawerNavigationLink[] => {

    return [
        {
            moduleId: MODULE_ADM_CATALOG.HOME_ROOT,
            icon: faHomeBlank,
            title: "Home",
            path: ROUTES_KEYS.ROOT_ADM_HOME
        },
        {
            moduleId: MODULE_ADM_CATALOG.CARRERAS_CONFIG_ROOT,
            icon: faCogs,
            title: "Configuración Oferta Académica",
            path: ROUTES_KEYS.ROOT_ADM_CARRERAS_CONFIG
        },
    ];


};


