import { faGraduationCap } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Chip } from "@mui/material"


export const ChipBeca = () => {

    return (
        <Chip
            sx={{
                background: "#f7941d",
                color: "white",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
                zIndex: 2
            }}
            icon={
                <FontAwesomeIcon icon={faGraduationCap} color="white" />
            }
            label="Beca"
        />
    )


}