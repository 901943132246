import { useState } from "react";
import { IUseIsLoading } from "./IUseIsLoading";

export interface IStateIsLoading {

    isLoading: boolean;
    initialLoading: boolean;

}

export const useIsLoading = (initialValue: boolean): IUseIsLoading => {

    const [currentState, setState] = useState<IStateIsLoading>({
        isLoading: initialValue,
        initialLoading: initialValue
    });

    const setIsLoading = (value: boolean) => {

        setState({
            ...currentState,
            isLoading: value
        });

    }


    return {
        stateLoading: currentState,
        isLoading: currentState.isLoading,
        setIsLoading: setIsLoading,
        setLoadingState: setState
    };

}