import axios from "axios";
import { IRecordRecinto } from "../../../interfaces/Records/IRecordRecinto";
import { IResponseRecord } from "../../../interfaces/Responses/IResponseRecord";
import { IResponseRecords } from "../../../interfaces/Responses/IResponseRecords";
import { ServiceConnectorAPIBase } from "../../Core/ServiceConnectorAPIBase/ServiceConnectorAPIBase";
import { IServiceConnectorRecintos } from "./IServiceConnectorRecintos";



export class ServiceConnectorRecintos extends ServiceConnectorAPIBase implements IServiceConnectorRecintos {

    constructor() {

        super("v1/recintos");

    }

    public async GetByCarreraId(carreraId: string, universidadId: string): Promise<IResponseRecords<IRecordRecinto>> {

        const response = await axios.get<IResponseRecords<IRecordRecinto>>(`${this.BaseUrl}/universidad/${universidadId}/carrera/${carreraId}`);
        return response.data;

    }

    public async GetByUniversidadId(universidadId: string): Promise<IResponseRecords<IRecordRecinto>> {

        const response = await axios.get<IResponseRecords<IRecordRecinto>>(`${this.BaseUrl}/universidad/${universidadId}`);
        return response.data;

    }

    public async GetAll(): Promise<IResponseRecords<IRecordRecinto>> {

        const response = await axios.get<IResponseRecords<IRecordRecinto>>(this.BaseUrl);
        console.info(response.data);
        return response.data;

    }

    public async GetById(id: string): Promise<IResponseRecord<IRecordRecinto>> {

        const response = await axios.get<IResponseRecord<IRecordRecinto>>(`${this.BaseUrl}/${id}`);
        return response.data;

    }

    public async Add(record: IRecordRecinto): Promise<IResponseRecord<IRecordRecinto>> {

        const response = await axios.post<IResponseRecord<IRecordRecinto>>(this.BaseUrl, {
            "calle": record.calle,
            "numero": record.numero,
            "delegacion": record.delegacion,
            "colonia": record.colonia,
            "codigoPostal": record.codigoPostal,
            "referencia": record.referencia,
            "urlImagen": record.urlImagen,
            "universidadId": record.universidadId,
            "whatsApp": record.whatsApp,
            "nombre": record.nombre,
        });
        return response.data;

    }

    public async Update(record: IRecordRecinto): Promise<IResponseRecord<IRecordRecinto>> {

        const response = await axios.put<IResponseRecord<IRecordRecinto>>(`${this.BaseUrl}`, {
            "chainId": record.chainId,
            "calle": record.calle,
            "numero": record.numero,
            "delegacion": record.delegacion,
            "colonia": record.colonia,
            "codigoPostal": record.codigoPostal,
            "referencia": record.referencia,
            "urlImagen": record.urlImagen,
            "universidadId": record.universidadId,
            "whatsApp": record.whatsApp,
            "nombre": record.nombre,
        });
        return response.data;

    }

}
