import { Button, Grid, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useContextApiService } from "../../../../contexts/ContextApiService/ContextApiService";
import { useIsLoading } from "../../../../hooks/Shared/useIsLoading/useIsLoading";
import { IRecordRecinto } from "../../../../interfaces/Records/IRecordRecinto";
import { IFormInteractionEditRecord } from "../../../../interfaces/Shared/Forms/IFormInteractionEditRecord";
import { ControlButtonCampus } from "../../../Controls/ControlButtonCampus/ControlButtonCampus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

export interface IFormuserCampusPickerProps extends IFormInteractionEditRecord<IRecordRecinto> {

    universidadId: string;
    carreraId: string;

}

export const FormUserCampusPicker = ({

    universidadId,
    carreraId,
    onRecordChanged


}: IFormuserCampusPickerProps) => {

    const { isLoading, setIsLoading } = useIsLoading(true);
    const { ApiService } = useContextApiService();
    const [opciones, setOpciones] = useState<IRecordRecinto[]>([]);


    useEffect(() => {

        const getOpciones = async () => {

            const response = await ApiService.Admin.Recintos.GetByCarreraId(
                carreraId,
                universidadId
            );

            if (response.error) {
                return
            }

            setOpciones(response.registros);
            setIsLoading(false);

        };

        getOpciones().catch(console.error);

    }, []);


    return (
        <Grid container spacing={3}>
            {
                isLoading ? (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                ) : (
                    <Grid item xs={12} container spacing={3}>
                        <Grid item xs={12} container justifyContent="center">
                            {
                                opciones.map((opcion, index) => (
                                    <Grid item xs={12} md={4} key={index} sx={{ display: "flex", justifyContent: "center" }}>
                                        <ControlButtonCampus
                                            campus={opcion}
                                            OnClick={onRecordChanged}
                                        />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    );

}