import { IRecordCarrera } from "../../../interfaces/Records/IRecordCarrera";
import { IUseValidate } from "../IUseValidate";

export const useValidateCarrera = (): IUseValidate<IRecordCarrera> => {

    const validateCarrera = (record: IRecordCarrera): boolean => {

        if (record.nombre === "") return false;
        if (record.clave === "") return false;

        return true;

    }

    return {
        validate: validateCarrera
    };

}