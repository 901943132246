


export const END_POINT_CATALOG = {

    //CORE: "https://localhost:7144/api/",
    //CORE: "https://nacer-calculadora-demo.azurewebsites.net/webapi/api/",
    CORE: "https://ng-calculadora.azurewebsites.net/core/api/",
    LOGIN: 'login',
    USUARIOS: 'usuarios',

}