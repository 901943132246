import { IRecordPromoBeca } from "../../../interfaces/Records/IRecordPromoBeca";
import { IUseValidate } from "../IUseValidate";


export const useValidatePromoBecas = (): IUseValidate<IRecordPromoBeca> => {

    const validate = (record: IRecordPromoBeca): boolean => {

        if (record.nombre.length <= 0) return false;
        if (record.descripcion.length <= 0) return false;
        if (record.promedioMinimo < 0 || record.promedioMinimo > 10) return false;
        if (record.promedioMaximo < 0 || record.promedioMaximo > 10) return false;
        if (record.porcentajeDescuento < 0 || record.porcentajeDescuento > 100) return false;
        if (record.vigenciaInicio.isAfter(record.vigenciaFin)) return false;
        if (record.vigenciaFin.isBefore(record.vigenciaInicio)) return false;

        return true;

    };

    return {
        validate
    };

};



