import { Grid } from "@mui/material";
import { useContextUniversidad } from "../../../contexts/ContextUniversidad/ContextUniversidad";
import { defaultRecordCarreraConfig } from "../../../data/defaults/records/defaultRecordCarreraConfig";
import { defaultRecordRecinto } from "../../../data/defaults/records/defaultRecordRecinto";
import { ControlHeaderLogo } from "../../Controls/ControlHeaderLogo/ControlHeaderLogo";
import { FormUserNivelAcademico } from "../../Forms/User/FormUserNivelAcademico/FormUserNivelAcademico";



export const SectionNivelAcademico = () => {

    const {
        Universidad,
        SetContectRecord,
        CtxRecord
    } = useContextUniversidad();

    return (
        <Grid id="nivel-academico" container sx={{ minHeight: "100vh" }}>
            <Grid item xs={12}>
                <ControlHeaderLogo title="Nivel Academico" />
            </Grid>
            <Grid item xs={12}>
                <FormUserNivelAcademico
                    universidadId={Universidad.chainId}
                    onRecordChanged={(record) => {
                        SetContectRecord({
                            ...CtxRecord,
                            LineaNegocio: record,
                            ConfigCarrera: [],
                            ConfigCarreraTemp: defaultRecordCarreraConfig(),
                            Campus: defaultRecordRecinto()
                        });
                    }}
                />
            </Grid>
        </Grid>
    )

}