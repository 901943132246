import { faUserPlus, faSave } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useContextApiService } from "../../../../../contexts/ContextApiService/ContextApiService";
import { defaultRecordPromoBanner } from "../../../../../data/defaults/records/defaultRecordPromoBanner";
import { usePropChange } from "../../../../../hooks/Shared/usePropChange/usePropChange";
import { useValidateLineaNegocio } from "../../../../../hooks/validators/useValidateLineaNegocio/useValidateLineaNegocio";
import { IRecordLineaNegocio } from "../../../../../interfaces/Records/IRecordLineaNegocio";
import { IRecordPromoBanner } from "../../../../../interfaces/Records/IRecordPromoBanner";
import { FormAdminLineaNegocio } from "../../../../Forms/Admin/FormAdminLineaNegocio/FormAdminLineaNegocio";
import { IndicatorHeaderSection } from "../../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";
import { useModalDialogWindow } from "../../../../Shared/ModalDialog/hook/useModalDialogWindow";
import { FormAdminPromoBanner } from "../../../../Forms/Admin/FormAdminPromoBanner/FormAdminPromoBanner";
import { useValidatePromoBanner } from "../../../../../hooks/validators/useValidatePromoBanner/useValidatePromoBanner";
import { useEffect } from "react";


export const PageAdminPromoBannersNew = () => {

    const { id } = useParams();
    const { Record, SetRecord } = usePropChange<IRecordPromoBanner>(defaultRecordPromoBanner());
    const { ApiService } = useContextApiService();
    const { ShowModalEnviando, ShowModalError, ShowModalSuccessNew } = useModalDialogWindow();
    const { validate } = useValidatePromoBanner();
    const navigate = useNavigate();

    const onChange = (record: IRecordPromoBanner) => {

        if (id !== undefined) {
            record.universidadId = id;
            SetRecord(record);
        }

    }

    const onSave = async () => {

        ShowModalEnviando();
        const response = await ApiService.Admin.PromosBanners.Add(Record);

        if (!response.success) {

            const errorMesage = response.error ?? "Error al guardar el registro";
            ShowModalError(errorMesage);
            return;

        }

        ShowModalSuccessNew();
        navigate("../");

    }

    useEffect(() => {

        if (id === undefined) {
            ShowModalError("No se ha seleccionado una universidad");
            navigate("../");
        }

    }, []);

    return (
        <Grid container  >
            <IndicatorHeaderSection
                title="Nuevo Registro"
                subtitle="Complete los campos para agregar un registro"
                icon={faUserPlus}
            />
            <Grid item xs={12}>
                <FormAdminPromoBanner
                    onRecordChanged={onChange}
                />
            </Grid>
            <Grid item xs={12} container direction="row-reverse" sx={{ mt: "2rem" }}>
                <Grid item xs={12} md={4}>
                    <Button variant="contained" fullWidth disabled={!validate(Record)} onClick={onSave}>
                        <Typography variant="button">
                            <FontAwesomeIcon icon={faSave} /> Guardar
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )

}