import { faUniversity } from "@fortawesome/pro-light-svg-icons";
import { Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import { defaultRecordUniversidadConfig } from "../../../../data/defaults/records/defaultRecordUniversidadConfig";
import { usePropChange } from "../../../../hooks/Shared/usePropChange/usePropChange";
import { useValidateUniversidadConfig } from "../../../../hooks/validators/useValidateUniversidadConfig/useValidateUniversidadConfig";
import { IRecordUniversidadConfig } from "../../../../interfaces/Records/IRecordUniversidadConfig";
import { IFormInteractionEditRecord } from "../../../../interfaces/Shared/Forms/IFormInteractionEditRecord";
import { IndicatorHeaderSection } from "../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";



export const FormAdminUniversidadConfig = ({

    defaultRecord,
    onRecordChanged

}: IFormInteractionEditRecord<IRecordUniversidadConfig>) => {

    const { Record, OnTextInputChanged } = usePropChange<IRecordUniversidadConfig>(
        defaultRecord ?? defaultRecordUniversidadConfig()
    );
    const { validate: validateUniversidadConfig } = useValidateUniversidadConfig();


    useEffect(() => {

        if (validateUniversidadConfig(Record)) {

            console.log("Record is valid", Record);
            onRecordChanged(Record)

        }

    }, [Record]);

    return (
        <Grid container>
            <Grid item xs={12} container>
                <IndicatorHeaderSection
                    title="Datos Generales"
                    icon={faUniversity}
                />
                <Grid item xs={12} md={12}>
                    <TextField
                        value={Record.styleProps}
                        label="Style Config"
                        variant="outlined"
                        multiline
                        rows={10}
                        fullWidth
                        name="style"
                        type="text"
                        onChange={OnTextInputChanged("styleProps")}
                        error={Record.styleProps.length <= 0}
                        required
                    />
                </Grid>
            </Grid>


        </Grid>
    );

}