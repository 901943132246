import { END_POINT_CATALOG } from "../../../config/END_POINT_CATALOG";
import { IServiceConnectorAPIBase } from "./IServiceConnectorAPIBase";


export class ServiceConnectorAPIBase implements IServiceConnectorAPIBase {

    BaseUrl: string;

    constructor(baseUrl: string) {
        this.BaseUrl = `${END_POINT_CATALOG.CORE}${baseUrl}`;
    }

    CreateActionUrl(action: string): string {
        return `${this.BaseUrl}/${action}`;
    }

}