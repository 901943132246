import moment from "moment";
import { IRecordPromoPrecio } from "../../../interfaces/Records/IRecordPromoPrecio";



export const defaultRecordPromoPrecio = (): IRecordPromoPrecio => {

    return {
        descripcion: "",
        active: true,
        chainId: "",
        clave: "",
        created: moment(),
        nombre: "",
        porcentajeDescuento: 0,
        tipoPrecioId: "",
        tipoPrecioNombre: "",
        universidadId: "",
        universidadNombre: "",
        updated: moment(),
        vigenciaFin: moment(),
        vigenciaInicio: moment(),
        recintoId: "",
        recintoNombre: "",
        lineaNegocioId: "",
        lineaNegocioNombre: ""

    };

};



