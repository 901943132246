import { faGear, faListCheck, faMoneyBill, faUniversity } from "@fortawesome/pro-light-svg-icons";
import { FormControlLabel, Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import { defaultRecordUniversidad } from "../../../../data/defaults/records/defaultRecordUniversidad";
import { usePropChange } from "../../../../hooks/Shared/usePropChange/usePropChange";
import { useValidateUniversidad } from "../../../../hooks/validators/useValidateUniversidad/useValidateUniversidad";
import { IRecordUniversidad } from "../../../../interfaces/Records/IRecordUniversidad";
import { IFormInteractionEditRecord } from "../../../../interfaces/Shared/Forms/IFormInteractionEditRecord";
import { IndicatorHeaderSection } from "../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";
import { IOSSwitch } from "../../../Shared/IOSSwitch/IOSSwitch";



export const FormAdminUniversidad = ({

    defaultRecord,
    onRecordChanged

}: IFormInteractionEditRecord<IRecordUniversidad>) => {

    const { Record, OnTextInputChanged, OnCheckBoxChanged } = usePropChange<IRecordUniversidad>(
        defaultRecord ?? defaultRecordUniversidad()
    );
    const { validate: validateUniversidad } = useValidateUniversidad();


    useEffect(() => {

        if (validateUniversidad(Record)) {

            console.log("Record is valid", Record);
            onRecordChanged(Record)

        }

    }, [Record]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} container spacing={3}>
                <IndicatorHeaderSection
                    title="Datos Generales"
                    icon={faUniversity}
                />
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.nombre}
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                        name="nombre"
                        type="text"
                        onChange={OnTextInputChanged("nombre")}
                        error={Record.nombre.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.cssName}
                        label="CSS Name"
                        variant="outlined"
                        fullWidth
                        name="cssName"
                        type="text"
                        onChange={OnTextInputChanged("cssName")}
                        error={Record.cssName.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField

                        value={Record.clave}
                        label="Clave"
                        variant="outlined"
                        fullWidth
                        name="clave"
                        type="text"
                        onChange={OnTextInputChanged("clave")}
                        error={Record.clave.length <= 0}
                        required
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={3}>
                <IndicatorHeaderSection
                    title="Datos Adicionales"
                    icon={faListCheck}
                />
                <Grid item xs={12} md={6}>
                    <TextField

                        value={Record.urlCrm}
                        label="URL CRM"
                        variant="outlined"
                        fullWidth
                        name="urlCRM"
                        type="url"
                        onChange={OnTextInputChanged("urlCrm")}
                        error={Record.urlCrm.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField

                        value={Record.urlPolitica}
                        label="URL Politica"
                        variant="outlined"
                        fullWidth
                        name="urlPolitica"
                        type="url"
                        onChange={OnTextInputChanged("urlPolitica")}
                        error={Record.urlPolitica.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField

                        value={Record.urlLogo}
                        label="URL Logo"
                        variant="outlined"
                        fullWidth
                        name="urlLogo"
                        type="url"
                        onChange={OnTextInputChanged("urlLogo")}
                        error={Record.urlLogo.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField

                        value={Record.whatsapp}
                        label="Num Whatsapp"
                        variant="outlined"
                        fullWidth
                        name="whats"
                        type="tel"
                        onChange={OnTextInputChanged("whatsapp")}
                        error={Record.whatsapp.length <= 0}
                        required
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={3}>
                <IndicatorHeaderSection
                    title="Configuracion Pagos"
                    icon={faMoneyBill}
                />
                <Grid item xs={12} md={4}>
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                checked={Record.showColegiatura}
                                onChange={OnCheckBoxChanged("showColegiatura")}
                            />
                        }
                        label="Mostrar Colegiatura"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                checked={Record.showPagoTotal}
                                onChange={OnCheckBoxChanged("showPagoTotal")}
                            />
                        }
                        label="Mostrar Pago Total"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                checked={Record.showPrimerPago}
                                onChange={OnCheckBoxChanged("showPrimerPago")}
                            />
                        }
                        label="Mostrar Primer Pago"
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={3}>
                <IndicatorHeaderSection
                    title="Configuración Registro"
                    icon={faGear}
                />
                <Grid item xs={12} md={4}>
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                checked={Record.active}
                                onChange={OnCheckBoxChanged("active")}
                            />
                        }
                        label="¿Registro Activo?"
                    />
                </Grid>
            </Grid>

        </Grid>
    );

}