import { faLink } from "@fortawesome/pro-light-svg-icons";
import { IconButton } from "@mui/material";
import { IRecordPromoBeca } from "../../../interfaces/Records/IRecordPromoBeca"
import { ITableCoreEvents } from "../../Shared/_TableCore/ITableCoreEvents";
import { TableBaseContainer } from "../../Shared/_TableCore/TableBaseContainer";
import { TableFormatPromoBecas } from "./TableFormatPromoBecas";


export const TableAdminPromoBecas = ({

    records,
    OnEditRecord

}: ITableCoreEvents<IRecordPromoBeca>) => {


    return (
        <TableBaseContainer<IRecordPromoBeca>
            headers={["Acciones", "Nombre", "Descripcion", "Campus/Linea de Negocio", "Promedio Minimo", "Promedio Maximo", "Porcentaje Descuento", "Vigencia Inicio", "Vigencia Fin", "Activo", "Creado", "Modificado"]}
            records={records}
            OnEditRecord={OnEditRecord}
            TableRecordFormat={TableFormatPromoBecas}
            id="table-promos-banners"
        />
    )


}


