import moment from "moment";
import { IRecordTipoPrecio } from "../../../interfaces/Records/IRecordTipoPrecio";



export const defaultRecordTipoPrecio = (): IRecordTipoPrecio => {

    return {
        active: true,
        chainId: "",
        clave: "",
        created: moment(),
        descripcion: "",
        nombre: "",
        updated: moment()
    };

};
