import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@mui/material";
import { IIndicatorHeaderSectionProps } from "./IIndicatorHeaderSectionProps";
import { IndicatorHeaderSectionStyler } from "./IndicatorHeaderSectionStyler";

export const IndicatorHeaderSection = ({

    title,
    icon,
    color = "secondary",
    subtitle

}: IIndicatorHeaderSectionProps) => {

    const styler = IndicatorHeaderSectionStyler(color);

    return (
        <Grid container item xs={12} sx={styler.mainContainer}>
            <Grid item xs={12}>
                <Typography variant="h6" sx={styler.headerText}>
                    {
                        icon ? (
                            <>
                                <FontAwesomeIcon icon={icon} /> {title}
                            </>
                        ) : (
                            <>
                                {title}
                            </>
                        )
                    }
                </Typography>
                {
                    subtitle && (
                        <Typography variant="subtitle1" component="p">
                            {subtitle}
                        </Typography>
                    )
                }
            </Grid>
        </Grid >
    )

}