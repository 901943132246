import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PageAdminLogin } from "../components/Pages/Admin/login/PageAdminLogin/PageAdminLogin";
import { PageUserHome } from "../components/Pages/User/PageUserHome/PageUserHome";
import { PageUserHomeUniversidad } from "../components/Pages/User/PageUserHomeUniversidad/PageUserHomeUniversidad";
import { PageUserNotFound } from "../components/Pages/User/PageUserNotFound/PageUserNotFound";
import { ROUTES_KEYS } from "./ROUTES_KEYS";
import { RoutesAdmin } from "./RoutesAdmin";
import { RoutesUser } from "./RoutesUser";


export const AppRoutes = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<RoutesUser />} />
                <Route path={ROUTES_KEYS.ROOT_LOGIN} element={<PageAdminLogin />} />
                <Route path={`/${ROUTES_KEYS.ROOT_ADMIN_PATH}/*`} element={<RoutesAdmin />} />
                <Route path="*" element={<PageUserNotFound />} />
            </Routes>
        </BrowserRouter>
    );

}