import { createTheme } from "@mui/material";
import { STYLES_APP } from "./STYLES_APP";

export const baseTheme = createTheme();

export const ThemeLight = createTheme(baseTheme, {

    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    minHeight: STYLES_APP.BUTTON_HEIGHT,
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: '"DM Sans", sans-serif !important',
                },
            },
        },
        // MuiGrid: {
        //     defaultProps: {
        //         spacing: 5
        //     },
        // },
    },

});