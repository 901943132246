import { styled, DialogTitle, DialogTitleProps } from "@mui/material";

export const DialogTitleError = styled(DialogTitle)<DialogTitleProps>(({ theme }) => ({
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    marginBottom: "1rem",
    display: "flex",
}));



