import { faCogs, faList, faTags } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, List, Skeleton, Typography } from "@mui/material";
import { defaultNavDrawerAdminMenu } from "../../../data/defaults/navs/defaultNavDrawerAdminMenu";
import { defaultNavDrawerAdminMenuCatalogos } from "../../../data/defaults/navs/defaultNavDrawerAdminMenuCatalogos";
import { defaultNavDrawerAdminMenuConfig } from "../../../data/defaults/navs/defaultNavDrawerAdminMenuConfig";
import { defaultNavDrawerAdminMenuPromos } from "../../../data/defaults/navs/defaultNavDrawerAdminMenuPromos";
import { STYLES_APP } from "../../../theme/STYLES_APP";
import { NavDrawerLinkSection } from "./NavDrawerLinkSection";
import { useContextAuthStateProvider } from "../../../contexts/ContextAuthState/ContextAuthState";
import { MODULE_ADM_CATALOG } from "../../../config/MODULE_CATALOG";


export const NavDrawerAdminMenu = () => {

    const { CurrentUser } = useContextAuthStateProvider();
    const menuLinks = defaultNavDrawerAdminMenu();
    const catalogosLinks = defaultNavDrawerAdminMenuCatalogos();
    const settingsLinks = defaultNavDrawerAdminMenuConfig();
    const promocionesLinks = defaultNavDrawerAdminMenuPromos();

    const userPromoLinks = CurrentUser.userNavigation.find(record => record.chainId.toUpperCase() === MODULE_ADM_CATALOG.PROMO_ROOT.toUpperCase());
    const userCatalogosLinks = CurrentUser.userNavigation.find(record => record.chainId.toUpperCase() === MODULE_ADM_CATALOG.CATALOGOS_ROOT.toUpperCase());
    const userSettingsLinks = CurrentUser.userNavigation.find(record => record.chainId.toUpperCase() === MODULE_ADM_CATALOG.CONFIG_ROOT.toUpperCase());


    return (
        <>
            <Box>
                <h5>Logo Aqui</h5>
            </Box>
            <Divider />
            <Box sx={{ overflow: "auto", padding: "1rem 0", minHeight: "40rem" }}>
                <List>
                    {
                        false ? (
                            <>
                                <Skeleton height={50} sx={{ bgcolor: STYLES_APP.COLOR_60 }} />
                                <Skeleton height={50} sx={{ bgcolor: STYLES_APP.COLOR_60 }} />
                                <Skeleton height={50} sx={{ bgcolor: STYLES_APP.COLOR_60 }} />
                            </>
                        ) : (
                            <>
                                <List>
                                    {
                                        menuLinks.map((link, index) => (

                                            <NavDrawerLinkSection
                                                key={`nav-drawer-link-section-${index}`}
                                                index={index}
                                                link={link}
                                                userModulesToCheck={CurrentUser.userNavigation}
                                            />

                                        ))
                                    }
                                </List>
                                <Divider />
                                <Typography sx={{ pt: "0.5rem", pl: "0.8rem", fontWeight: "bold" }} color="white" variant="caption" component="h2" gutterBottom>
                                    <FontAwesomeIcon icon={faTags} />  Promociones
                                </Typography>
                                <Divider />
                                <List>
                                    {
                                        promocionesLinks.map((link, index) => (

                                            <NavDrawerLinkSection
                                                key={`nav-drawer-link-section-${index}`}
                                                index={index}
                                                link={link}
                                                userModulesToCheck={userPromoLinks ? userPromoLinks.children : []}
                                            />

                                        ))
                                    }
                                </List>
                                <Typography sx={{ pt: "0.5rem", pl: "0.8rem", fontWeight: "bold" }} color="white" variant="caption" component="h2" gutterBottom>
                                    <FontAwesomeIcon icon={faList} />  Catalogos
                                </Typography>
                                <Divider />
                                <List>
                                    {
                                        catalogosLinks.map((link, index) => (

                                            <NavDrawerLinkSection
                                                key={`nav-drawer-link-section-${index}`}
                                                index={index}
                                                link={link}
                                                userModulesToCheck={userCatalogosLinks ? userCatalogosLinks.children : []}
                                            />

                                        ))
                                    }
                                </List>
                                <Typography sx={{ pt: "0.5rem", pl: "0.8rem", fontWeight: "bold" }} color="white" variant="caption" component="h2" gutterBottom>
                                    <FontAwesomeIcon icon={faCogs} /> Settings
                                </Typography>
                                <Divider />
                                <List>
                                    {
                                        settingsLinks.map((link, index) => (

                                            <NavDrawerLinkSection
                                                key={`nav-drawer-link-section-${index}`}
                                                index={index}
                                                link={link}
                                                userModulesToCheck={userSettingsLinks ? userSettingsLinks.children : []}
                                            />

                                        ))
                                    }
                                </List>

                            </>
                        )
                    }
                </List>
            </Box>
            <Divider />
            {/* <NavDrawerCurrentUser /> */}
        </>
    );

}