import axios from "axios";
import { IRecordTipoPrecio } from "../../../interfaces/Records/IRecordTipoPrecio";
import { IResponseRecord } from "../../../interfaces/Responses/IResponseRecord";
import { IResponseRecords } from "../../../interfaces/Responses/IResponseRecords";
import { ServiceConnectorAPIBase } from "../../Core/ServiceConnectorAPIBase/ServiceConnectorAPIBase";
import { IServiceConnectorTipoPrecio } from "./IServiceConnectorTipoPrecio";


export class ServiceConnectorTipoPrecio extends ServiceConnectorAPIBase implements IServiceConnectorTipoPrecio {

    constructor() {
        super("v1/tipoPrecio");
    }

    public async GetAll(): Promise<IResponseRecords<IRecordTipoPrecio>> {

        const response = await axios.get<IResponseRecords<IRecordTipoPrecio>>(this.BaseUrl);
        console.info(response.data);
        return response.data;

    }

    public async GetById(id: string): Promise<IResponseRecord<IRecordTipoPrecio>> {

        const response = await axios.get<IResponseRecord<IRecordTipoPrecio>>(`${this.BaseUrl}/${id}`);
        return response.data;

    }

    public async Add(record: IRecordTipoPrecio): Promise<IResponseRecord<IRecordTipoPrecio>> {

        const response = await axios.post<IResponseRecord<IRecordTipoPrecio>>(this.BaseUrl, {
            "nombre": record.nombre,
            "descripcion": record.descripcion,
            "active": true
        });
        return response.data;

    }

    public async Update(record: IRecordTipoPrecio): Promise<IResponseRecord<IRecordTipoPrecio>> {

        const response = await axios.put<IResponseRecord<IRecordTipoPrecio>>(`${this.BaseUrl}`, {
            "chainId": record.chainId,
            "descripcion": record.descripcion,
            "active": record.active
        });
        return response.data;

    }

} 