import { faPlusCircle, faTags, faUsers } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useContextApiService } from "../../../../../contexts/ContextApiService/ContextApiService";
import { useIsLoading } from "../../../../../hooks/Shared/useIsLoading/useIsLoading";
import { IRecordPromoBanner } from "../../../../../interfaces/Records/IRecordPromoBanner";
import { IRecordUniversidad } from "../../../../../interfaces/Records/IRecordUniversidad";
import { ROUTES_KEYS } from "../../../../../routes/ROUTES_KEYS";
import { ComboServiceSelector } from "../../../../Shared/ComboServiceSelector/ComboServiceSelector";
import { IndicatorHeaderSection } from "../../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";
import { TableAdminPromoBanners } from "../../../../Tables/TableAdminPromoBanners/TableAdminPromoBanners";
import { useContextAuthStateProvider } from "../../../../../contexts/ContextAuthState/ContextAuthState";



export const PageAdminPromoBannersHome = () => {

    const { ApiService } = useContextApiService();
    const { isLoading, setIsLoading } = useIsLoading(false);
    const [records, setRecords] = useState<IRecordPromoBanner[]>([]);
    const [selectedUniversidad, setSelectedUniversidad] = useState<IRecordUniversidad | undefined>(undefined);
    const navigate = useNavigate();
    const { CurrentUser } = useContextAuthStateProvider();

    const onSelectionUniversidad = async (record: IRecordUniversidad | undefined) => {


        if (record === undefined) {
            return;
        }

        console.log("Universidad", record);
        setIsLoading(true);
        const response = await ApiService.Admin.PromosBanners.GetByUniversidadId(record.chainId);
        setSelectedUniversidad(record);
        setRecords(response.registros);


    }


    useEffect(() => {

        setIsLoading(false);

    }, [records])


    return (
        <Grid container spacing={3}>
            <IndicatorHeaderSection
                title="Banners"
                icon={faUsers}
                color="secondary"
                subtitle="Gestión de Banners Promocionales."
            />
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} md={6}>
                    <ComboServiceSelector<IRecordUniversidad>
                        id="combo-universidades"
                        label="Universidades"
                        optionIdsToShow={CurrentUser.universidades}
                        queryService={() => {
                            return ApiService.Admin.Universidades.GetAll();
                        }}
                        onSelectionChange={onSelectionUniversidad}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} md={4}>
                    <Button
                        component={Link}
                        to={`${ROUTES_KEYS.ROOT_ADM_NEW}/${selectedUniversidad?.chainId}`}
                        variant="contained" color="primary" fullWidth disabled={selectedUniversidad == undefined}>
                        <Typography variant="button">
                            <FontAwesomeIcon icon={faPlusCircle} /> Crear Banner
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <IndicatorHeaderSection
                    title="Banners Registrados"
                    icon={faTags}
                    color="warning"
                />
                {
                    isLoading ? (
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    ) : (
                        <TableAdminPromoBanners
                            records={records}
                            OnEditRecord={(record) => {
                                console.log("Edit", record);
                                navigate(`${ROUTES_KEYS.ROOT_ADM_EDIT}/${record.chainId}`);
                            }}
                        />
                    )
                }
            </Grid>
        </Grid>
    );


}