import { useEffect, useState } from "react";
import { useIsLoading } from "../../../../hooks/Shared/useIsLoading/useIsLoading"
import { IRecordLineaNegocio } from "../../../../interfaces/Records/IRecordLineaNegocio"
import { useContextApiService } from "../../../../contexts/ContextApiService/ContextApiService";
import { Grid, LinearProgress, Typography } from "@mui/material";
import { ControlButtonLineaNegocio } from "../../../Controls/ControlButtonLineaNegocio/ControlButtonLineaNegocio";
import { IFormUserNivelAcademicoProps } from "./IFormUserNivelAcademicoProps";


export const FormUserNivelAcademico = ({

    onRecordChanged,
    defaultRecord,
    universidadId

}: IFormUserNivelAcademicoProps) => {

    const { ApiService } = useContextApiService();
    const { isLoading, setIsLoading } = useIsLoading(true);
    const [opciones, setOpciones] = useState<IRecordLineaNegocio[]>([]);


    useEffect(() => {

        const getOpciones = async () => {

            const response = await ApiService.Admin.LineaNegocio.GetByUniversidaId(universidadId);
            if (response.error) {
                return
            }

            setOpciones(response.registros);
            setIsLoading(false);

        };

        getOpciones().catch(console.error);

    }, []);


    return (
        <Grid container spacing={3} sx={{ padding: "1rem" }}>
            <Grid item xs={12} textAlign="center">
                <Typography variant="h5" sx={{ paddingTop: "3rem", paddingBottom: "3rem" }}>
                    1. Selecciona el nivel académico de tu interés
                </Typography>
            </Grid>
            {
                isLoading ? (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                ) : (
                    <Grid item xs={12} container spacing={3} sx={{ marginBottom: "3rem" }}>
                        {
                            opciones.map((opcion, index) => (
                                <Grid item xs={12} md={4} key={index} sx={{ display: "flex" }}>
                                    <ControlButtonLineaNegocio
                                        lineaNegocio={opcion}
                                        OnClick={onRecordChanged}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                )
            }
        </Grid>
    )

}