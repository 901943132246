import { IRecordModalidad } from "../../../interfaces/Records/IRecordModalidad";
import { IRecordUniversidad } from "../../../interfaces/Records/IRecordUniversidad";
import { ITableCoreEvents } from "../../Shared/_TableCore/ITableCoreEvents";
import { TableBaseContainer } from "../../Shared/_TableCore/TableBaseContainer";
import { TableFormatAdminModalidad } from "./TableFormatAdminModalidad";

export const TableAdminModalidades = ({

    records,
    OnEditRecord

}: ITableCoreEvents<IRecordModalidad>) => {


    return (
        <TableBaseContainer<IRecordModalidad>
            headers={["Acciones", "Nombre", "Clave", "Descripcion", "Activo", "Creado", "Modificado"]}
            records={records}
            OnEditRecord={OnEditRecord}
            TableRecordFormat={TableFormatAdminModalidad}
            id="table-admin-modalidad"
        />
    )


}



