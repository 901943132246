import { faUniversity } from "@fortawesome/pro-light-svg-icons";
import { Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import { defaultRecordCarrera } from "../../../../data/defaults/records/defaultRecordCarrera";
import { usePropChange } from "../../../../hooks/Shared/usePropChange/usePropChange";
import { useValidateCarrera } from "../../../../hooks/validators/useValidateCarrera/useValidateCarrera";
import { IRecordCarrera } from "../../../../interfaces/Records/IRecordCarrera";
import { IFormInteractionEditRecord } from "../../../../interfaces/Shared/Forms/IFormInteractionEditRecord";
import { IndicatorHeaderSection } from "../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";


export const FormAdminCarrera = ({

    defaultRecord,
    onRecordChanged

}: IFormInteractionEditRecord<IRecordCarrera>) => {

    const { Record, OnTextInputChanged } = usePropChange<IRecordCarrera>(
        defaultRecord ?? defaultRecordCarrera()
    );
    const { validate: validateCarrera } = useValidateCarrera();


    useEffect(() => {

        if (validateCarrera(Record)) {

            console.log("Record is valid", Record);
            onRecordChanged(Record)

        }

    }, [Record]);

    return (
        <Grid container spacing={3}>
            <IndicatorHeaderSection
                title="Datos Generales"
                icon={faUniversity}
            />
            <Grid item xs={12} md={6}>
                <TextField
                    value={Record.nombre}
                    label="Nombre"
                    variant="outlined"
                    fullWidth
                    name="nombre"
                    type="text"
                    onChange={OnTextInputChanged("nombre")}
                    error={Record.nombre.length <= 0}
                    required
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    value={Record.clave}
                    label="Clave"
                    variant="outlined"
                    fullWidth
                    name="clave"
                    type="text"
                    onChange={OnTextInputChanged("clave")}
                    error={Record.clave.length <= 0}
                    required
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    value={Record.urlImagen}
                    label="Url Imagen"
                    variant="outlined"
                    fullWidth
                    name="urlImagen"
                    type="url"
                    onChange={OnTextInputChanged("urlImagen")}
                    error={Record.urlImagen.length <= 0}
                    required
                />
            </Grid>
        </Grid>
    );

}