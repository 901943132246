import { faListCheck, faUniversity, faUserPen, faUserTag } from "@fortawesome/pro-light-svg-icons";
import { Grid, TextField } from "@mui/material";
import { ChangeEvent, useEffect } from "react";
import { useContextApiService } from "../../../../contexts/ContextApiService/ContextApiService";
import { defaultRecordAppUser } from "../../../../data/defaults/records/defaultRecordAppUser";
import { usePropChange } from "../../../../hooks/Shared/usePropChange/usePropChange";
import { useValidateAppUser } from "../../../../hooks/validators/useValidateAppUser/useValidateAppUser";
import { IRecordAppUser, KeysIRecordAppUser } from "../../../../interfaces/Records/IRecordAppUser";
import { IRecordAppUserDetails } from "../../../../interfaces/Records/IRecordAppUserDetails";
import { IFormInteractionEditRecord } from "../../../../interfaces/Shared/Forms/IFormInteractionEditRecord";
import { FormCheckBoxes } from "../../../Shared/FormCheckBoxes/FormCheckBoxes";
import { IndicatorHeaderSection } from "../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";

export const FormAdminAppUser = ({

    defaultRecord,
    onRecordChanged

}: IFormInteractionEditRecord<IRecordAppUser>) => {

    const { ApiService } = useContextApiService();
    const { Record, OnTextInputChanged, OnPropChanged, SetRecord } = usePropChange<IRecordAppUser>(defaultRecord ?? defaultRecordAppUser());
    const { validate: validateAppUser } = useValidateAppUser();


    const onRolesChanged = (key: KeysIRecordAppUser) => (roles: string[]) => {

        OnPropChanged(key, roles);

    }

    const onTextNestedInputChange = (propName: keyof IRecordAppUserDetails) => (event: ChangeEvent<HTMLInputElement>): void => {

        const { value } = event.target;
        SetRecord({
            ...Record,
            appUserDetails: {
                ...Record.appUserDetails,
                [propName]: value
            }
        });

    };

    useEffect(() => {

        if (validateAppUser(Record)) {

            console.log("Record is valid", Record);
            onRecordChanged(Record)

        }

    }, [Record]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} container spacing={3}>
                <IndicatorHeaderSection
                    title="Datos Generales"
                    icon={faUserTag}
                />
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.appUserDetails.nombre}
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                        name="nombre"
                        type="text"
                        onChange={onTextNestedInputChange("nombre")}
                        error={Record.appUserDetails.nombre.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.appUserDetails.apellidoPaterno}
                        label="Apellido Paterno"
                        variant="outlined"
                        fullWidth
                        name="apellidoPaterno"
                        type="text"
                        onChange={onTextNestedInputChange("apellidoPaterno")}
                        error={Record.appUserDetails.apellidoPaterno.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField

                        value={Record.appUserDetails.apellidoMaterno}
                        label="Apellido Materno"
                        variant="outlined"
                        fullWidth
                        name="apellidoMaterno"
                        type="text"
                        onChange={onTextNestedInputChange("apellidoMaterno")}
                        error={Record.appUserDetails.apellidoMaterno.length <= 0}
                        required
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={3}>
                <IndicatorHeaderSection
                    title="Datos de la cuenta"
                    icon={faUserPen}
                />
                <Grid item xs={12} md={6}>
                    <TextField

                        value={Record.email}
                        label="Correo Electrónico"
                        variant="outlined"
                        fullWidth
                        name="email"
                        type="email"
                        onChange={OnTextInputChanged("email")}
                        error={Record.email.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField

                            value={Record.password}
                            label="Contraseña"
                            variant="outlined"
                            fullWidth
                            name="password"
                            type="password"
                            onChange={OnTextInputChanged("password")}
                            error={Record.password.length <= 0}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField

                            value={Record.passwordConfirm}
                            label="Confirmar Contraseña"
                            variant="outlined"
                            fullWidth
                            name="passwordConfirm"
                            type="password"
                            onChange={OnTextInputChanged("passwordConfirm")}
                            error={Record.passwordConfirm.length <= 0}
                            required
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={3}>
                <IndicatorHeaderSection
                    title="Permisos"
                    icon={faListCheck}
                />
                <Grid item xs={12}>
                    <FormCheckBoxes
                        apiService={() => {
                            return ApiService.Admin.Roles.GetAll();
                        }}
                        formId="roles"
                        OnSelectionChange={onRolesChanged("rolesIds")}
                        defaultSelections={Record.rolesIds}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={3}>
                <IndicatorHeaderSection
                    title="Universidades Asignadas"
                    icon={faUniversity}
                />
                <Grid item xs={12}>
                    <FormCheckBoxes
                        apiService={() => {
                            return ApiService.Admin.Universidades.GetAll();
                        }}
                        formId="Universidades"
                        OnSelectionChange={onRolesChanged("universidades")}
                        defaultSelections={Record.universidades}
                    />
                </Grid>
            </Grid>

        </Grid>
    );

}