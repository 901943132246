import { Grid, LinearProgress } from "@mui/material";
import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { defaultRecordDatosContacto } from "../../data/defaults/records/defaulRecordDatosContacto";
import { defaultRecordCarreraConfig } from "../../data/defaults/records/defaultRecordCarreraConfig";
import { defaultRecordLineaNegocio } from "../../data/defaults/records/defaultRecordLineaNegocio";
import { defaultRecordModalidad } from "../../data/defaults/records/defaultRecordModalidad";
import { defaultRecordRecinto } from "../../data/defaults/records/defaultRecordRecinto";
import { defaultRecordUniversidad } from "../../data/defaults/records/defaultRecordUniversidad";
import { defaultRecordUniversidadStyle } from "../../data/defaults/records/defaultRecordUniversidadStyle";
import { useIsLoading } from "../../hooks/Shared/useIsLoading/useIsLoading";
import { IRecordCarreraConfig } from "../../interfaces/Records/IRecordCarreraConfig";
import { IRecordDatosContacto } from "../../interfaces/Records/IRecordDatosContacto";
import { IRecordLineaNegocio } from "../../interfaces/Records/IRecordLineaNegocio";
import { IRecordModalidad } from "../../interfaces/Records/IRecordModalidad";
import { IRecordRecinto } from "../../interfaces/Records/IRecordRecinto";
import { IRecordUniversidad } from "../../interfaces/Records/IRecordUniversidad";
import { IRecordUniversidadStyle } from "../../interfaces/Records/IRecordUniversidadStyle";
import { useContextApiService } from "../ContextApiService/ContextApiService";
import { IRecordPromoBanner } from "../../interfaces/Records/IRecordPromoBanner";
import { IRecordPromoPrecio } from "../../interfaces/Records/IRecordPromoPrecio";
import { IRecordPromoBeca } from "../../interfaces/Records/IRecordPromoBeca";

export interface IStateUniversidadContext {

    Universidad: IRecordUniversidad;
    ConfigStyle: IRecordUniversidadStyle;
    AppUser: IRecordDatosContacto;
    LineaNegocio: IRecordLineaNegocio;
    Modalidad: IRecordModalidad;
    ConfigCarreraTemp: IRecordCarreraConfig;
    ConfigCarrera: IRecordCarreraConfig[];
    Campus: IRecordRecinto;
    PromoBanners: IRecordPromoBanner[];
    PromoPrecio: IRecordPromoPrecio[];
    PromoBecas: IRecordPromoBeca[];

}

export interface IContextUniversidadHandlers {

    AppUser: IRecordDatosContacto;
    Universidad: IRecordUniversidad;
    LineaNegocio: IRecordLineaNegocio;
    Modalidad: IRecordModalidad;
    ConfigCarrera: IRecordCarreraConfig[];
    ConfigStyle: IRecordUniversidadStyle;
    ConfigCarreraTemp: IRecordCarreraConfig;
    Campus: IRecordRecinto;
    CtxRecord: IStateUniversidadContext;
    PromoBanners: IRecordPromoBanner[];
    PromoPrecio: IRecordPromoPrecio[];
    PromoBecas: IRecordPromoBeca[];
    UpdateContextProp: (propname: keyof IContextUniversidadHandlers, value: any) => void;
    SetContectRecord: (record: IStateUniversidadContext) => void;

}

export const ContextUniversidad = createContext<IContextUniversidadHandlers>({} as IContextUniversidadHandlers);


export const ContextUniversidadProvider = ({ children }: PropsWithChildren<{}>) => {

    const { isLoading, setIsLoading, setLoadingState, stateLoading } = useIsLoading(true);
    const { ApiService } = useContextApiService();
    const { idUniversidad } = useParams();
    const [record, SetRecord] = useState<IStateUniversidadContext>({
        AppUser: defaultRecordDatosContacto(),
        Universidad: defaultRecordUniversidad(),
        LineaNegocio: defaultRecordLineaNegocio(),
        Modalidad: defaultRecordModalidad(),
        ConfigStyle: defaultRecordUniversidadStyle(),
        ConfigCarrera: [],
        ConfigCarreraTemp: defaultRecordCarreraConfig(),
        Campus: defaultRecordRecinto(),
        PromoBanners: [],
        PromoPrecio: [],
        PromoBecas: []
    });
    const navigate = useNavigate();


    const onPropChanged = (propName: keyof IContextUniversidadHandlers, value: any): void => {

        SetRecord({
            ...record,
            [propName]: value
        });

    }

    const setContextRecord = (record: IStateUniversidadContext): void => {

        SetRecord(record);

    }


    const getUniversidadData = async () => {

        const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;

        if (idUniversidad === undefined || !guidRegex.test(idUniversidad)) {

            navigate("/");
            return;

        }

        const UniversidadConfig = await ApiService.Admin.UniversidadConfiguracion.GetByUniversidadId(idUniversidad);
        const UniversidadData = await ApiService.Admin.Universidades.GetById(idUniversidad);
        const PromoBanners = await ApiService.Admin.PromosBanners.GetByUniversidadIdValidaVigencia(idUniversidad);
        const PromoPrecio = await ApiService.Admin.PromosPrecios.GetByUniversidadIdValidaVigencia(idUniversidad);
        const PromoBecas = await ApiService.Admin.PromosBecas.GetByUniversidadIdValidaVigencia(idUniversidad);

        SetRecord({
            ...record,
            ConfigStyle: JSON.parse(UniversidadConfig.registro.styleProps),
            Universidad: UniversidadData.registro,
            PromoBanners: PromoBanners.registros,
            PromoPrecio: PromoPrecio.registros,
            PromoBecas: PromoBecas.registros
        });


    };


    useEffect(() => {

        if (record.Universidad.chainId.length > 0) {

            setLoadingState({
                isLoading: false,
                initialLoading: false
            })
        }

    }, [record]);


    useEffect(() => {

        const getUniversidadProps = async () => {

            await getUniversidadData();

        };

        getUniversidadProps().catch((error) => {

            console.error("Error al obtener la informacion de la universidad", error);

        });

    }, []);

    return (
        <ContextUniversidad.Provider value={{
            Universidad: record.Universidad,
            ConfigStyle: record.ConfigStyle,
            AppUser: record.AppUser,
            LineaNegocio: record.LineaNegocio,
            Modalidad: record.Modalidad,
            ConfigCarrera: record.ConfigCarrera,
            ConfigCarreraTemp: record.ConfigCarreraTemp,
            Campus: record.Campus,
            CtxRecord: record,
            PromoBanners: record.PromoBanners,
            PromoPrecio: record.PromoPrecio,
            PromoBecas: record.PromoBecas,
            UpdateContextProp: onPropChanged,
            SetContectRecord: setContextRecord
        }}>
            {
                isLoading ? (
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        {children}
                    </>
                )
            }
        </ContextUniversidad.Provider>
    );

}

export const useContextUniversidad = (): IContextUniversidadHandlers => {

    const context = useContext(ContextUniversidad);

    if (context === undefined) {
        throw new Error('useContextContextUniversidadProvider debe usarse dentro de ContextUniversidadProviderProvider');
    }

    return context;

}