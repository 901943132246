import { ChangeEvent, FocusEvent, useState } from "react";
import { IUsePropChange } from "./IUsePropChange";

export const usePropChange = <T extends object>(initialValue: T): IUsePropChange<T> => {

    const [record, setRecord] = useState<T>(initialValue);

    const onPropChanged = (propName: keyof T, value: any): void => {

        setRecord({
            ...record,
            [propName]: value
        });

    }


    const onCheckboxChange = (propName: keyof T) => (event: React.ChangeEvent<HTMLInputElement>): void => {

        const { checked } = event.target;
        onPropChanged(propName, checked);

    }

    const onTextInputChange = (propName: keyof T) => (event: ChangeEvent<HTMLInputElement>): void => {

        const { value } = event.target;
        onPropChanged(propName, value);

    };


    const onTextBlurChanged = (propName: keyof T) => (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {

        const { value } = event.target;
        onPropChanged(propName, value);

    }

    return {
        Record: record,
        SetRecord: setRecord,
        OnPropChanged: onPropChanged,
        OnTextInputChanged: onTextInputChange,
        OnCheckBoxChanged: onCheckboxChange,
        OnTextBlurChanged: onTextBlurChanged,
    };

}