import moment from "moment";
import { IRecordCarreraConfig } from "../../../interfaces/Records/IRecordCarreraConfig";



export const defaultRecordCarreraConfig = (universidadId?: string): IRecordCarreraConfig => {

    return {
        active: true,
        carreraId: "",
        carreraNombre: "",
        chainId: "",
        clave: "",
        colegiatura: 0,
        costoTotal: 0,
        created: moment(),
        descripcion: "",
        duracion: 0,
        lineaNegocioId: "",
        modalidadId: "",
        modalidadNombre: "",
        nombre: "",
        primerPago: 0,
        recintoId: "",
        recintoNombre: "",
        universidadId: universidadId || "",
        updated: moment(),
        vigenciaFin: moment(),
        vigenciaInicio: moment(),
    };

};
