import { faLink, faPencil } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton, Typography } from "@mui/material";
import { IRecordPromoBanner } from "../../../interfaces/Records/IRecordPromoBanner";
import { ITableRecordFormater } from "../../Shared/_TableCore/ITableRecordFormater";
import { TableDataCell } from "../../Shared/_TableCore/TableDataCell";
import { TableRowCell } from "../../Shared/_TableCore/TableRowCell";
import { TableDataDateFormat } from "../TableDataDateFormat/TableDataDateFormat";
import { TableDataTrueFalse } from "../TableDataTrueFalse/TableDataTrueFalse";


export const TableFormatPromoBanners = ({

    record, OnEditRecord

}: ITableRecordFormater<IRecordPromoBanner>) => {

    return (
        <TableRowCell hover>
            <TableDataCell>
                <Button variant="outlined" color="secondary" onClick={() => { OnEditRecord(record); }}>
                    <FontAwesomeIcon icon={faPencil} />
                </Button>
            </TableDataCell>
            <TableDataCell align="left">
                <Typography variant="body1">
                    {record.nombre}
                </Typography>
            </TableDataCell>
            <TableDataCell align="left">
                <Typography variant="body1">
                    {record.mensaje}
                </Typography>
            </TableDataCell>
            <TableDataCell align="center">
                <IconButton
                    href={record.urlImagen}
                    target="_blank"
                    size="small">
                    <FontAwesomeIcon icon={faLink} />
                </IconButton>
            </TableDataCell>
            <TableDataDateFormat date={record.vigenciaInicio} />
            <TableDataDateFormat date={record.vigenciaFin} />
            <TableDataTrueFalse value={record.active} trueLabel="Activo" falseLabel="Inactivo" />
            <TableDataDateFormat date={record.created} />
            <TableDataDateFormat date={record.updated} />
        </TableRowCell>
    );

};
