import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import { TableDataCell } from "../../Shared/_TableCore/TableDataCell"
import { ITableDataTrueFalseProps } from "./ITableDataTrueFalseProps";



export const TableDataTrueFalse = ({
    value,
    trueLabel,
    falseLabel

}: ITableDataTrueFalseProps) => {

    return (
        <TableDataCell align="center">
            {value ? (
                <Typography variant="body2" color="success.main">
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" /> {trueLabel}
                </Typography>
            ) : (
                <Typography variant="body2" color="error.main">
                    <FontAwesomeIcon icon={faTimesCircle} size="2x" /> {falseLabel}
                </Typography>
            )}
        </TableDataCell>
    )

}