import { IStateDrawerNavigation } from "../../../interfaces/States/IStateDrawerNavigation";



export const defaultStateDrawerNavigation = (): IStateDrawerNavigation => {

    return {
        isDrawerOpen: false
    };

};


