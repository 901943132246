

export const STYLES_APP = {

    DRAWER_WIDTH: 273,
    COLOR_60: "#d2daff",
    COLOR_30: "#000024",
    COLOR_10: "#522ED6",

    BORDER_RADIUS: "24px",
    BUTTON_HEIGHT: "44px",

    NAV_DRAWER_BUTTON_HOVER: "#6B7FF2",
    NAV_DRAWER_BUTTON_SELECTED: "#263973",

    ROOT_BODY_BG: "#fff",
    MAIN_CONTAINER_PANEL: "#ededed",
    MAIN_CONTAINER_PANEL_BORDER_COLOR: "#ededed",

}