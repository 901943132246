import moment from "moment";
import { IRecordPromoBeca } from "../../../interfaces/Records/IRecordPromoBeca";



export const defaultRecordPromoBeca = (): IRecordPromoBeca => {

    return {
        descripcion: "",
        active: true,
        chainId: "",
        clave: "",
        created: moment(),
        nombre: "",
        porcentajeDescuento: 0,
        promedioMaximo: 0,
        promedioMinimo: 0,
        universidadId: "",
        universidadNombre: "",
        updated: moment(),
        vigenciaFin: moment(),
        vigenciaInicio: moment(),
        recintoId: "",
        recintoNombre: "",
        lineaNegocioId: "",
        lineaNegocioNombre: ""
    };

};
