import { PropsWithChildren } from "react";


export interface IComponentAllowerProps {

    componentId: string;
    userComponentsList: string[];

}


export const ComponentAllower = ({ componentId, children, userComponentsList }: PropsWithChildren<IComponentAllowerProps>) => {

    const IsComponentAllowed = (): boolean => {


        const isAllowed = userComponentsList.some(component => {
            return component.toUpperCase() === componentId.toUpperCase();
        });

        return isAllowed;

    }

    return (
        <>
            {
                IsComponentAllowed() ?
                    (
                        <>
                            {children}
                        </>
                    ) : (
                        <>
                        </>
                    )
            }
        </>
    );

}