import moment from "moment";
import { IRecordLineaNegocio } from "../../../interfaces/Records/IRecordLineaNegocio";



export const defaultRecordLineaNegocio = (): IRecordLineaNegocio => {

    return {
        clave: "",
        chainId: "",
        descripcion: "",
        nombre: "",
        equivalencia: "",
        prioridad: 1,
        active: true,
        created: moment(),
        updated: moment()
    };

};
