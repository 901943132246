import { useEffect, useState } from "react";
import { OPTIONS_DEFAULT } from "../../../config/OPTIONS_DEFAULT";
import { useDidMount } from "../../../hooks/Shared/useDidMount/useDidMount";
import { useIsLoading } from "../../../hooks/Shared/useIsLoading/useIsLoading";
import { IRecordAppChainId } from "../../../interfaces/Records/IRecordAppChainId";
import { LinearProgress, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { IComboServiceSelector } from "./IComboServiceSelector";


export const ComboServiceSelector = <TRecord extends IRecordAppChainId>({

    id,
    label,
    defaultRecord,
    showAllOption,
    isRequired,
    isReadOnly,
    optionIdsToShow,
    queryService,
    onSelectionChange

}: IComboServiceSelector<TRecord>) => {

    const { didMount } = useDidMount();
    const [options, setOptions] = useState<TRecord[]>([]);
    const { isLoading, setIsLoading } = useIsLoading(true);
    const [selectedId, setSelectedId] = useState<string>(defaultRecord ? defaultRecord.chainId : OPTIONS_DEFAULT.NO_SELECTED_OPTION_CHAIN_ID);

    const shouldShowOption = (option: TRecord): boolean => {

        if (optionIdsToShow) {

            const shouldShowItem = optionIdsToShow.some(id => {
                return id === option.chainId;
            });

            return shouldShowItem;

        }

        return true;

    }


    const GetSelectedItem = (selection: string): TRecord | undefined => {

        const results = options.filter(item => {
            return item.chainId === selection;
        });

        if (results.length > 0) {
            return results[0];
        }

        return undefined;

    };


    const OnSetSelectedChange = (selection: string): void => {

        setSelectedId(selection);
        if (onSelectionChange) {
            const response = GetSelectedItem(selection);
            onSelectionChange(response);
        }

    };



    useEffect(() => {

        const getData = async (): Promise<void> => {

            const response = await queryService();
            setOptions(response.registros);
            setIsLoading(false);

        };

        getData().catch(error => {

            console.error(`Error SERVICE del Combo ${id}`, error);
            setIsLoading(false);

        });

    }, []);


    return (
        <>
            {
                isLoading ? (
                    <LinearProgress />
                ) : (
                    <FormControl variant="outlined" fullWidth
                        error={isRequired ? (selectedId === OPTIONS_DEFAULT.NO_SELECTED_OPTION_CHAIN_ID ? true : false) : false}
                    >
                        <InputLabel id={`labelCombo-${id}`}>{label}</InputLabel>
                        <Select
                            labelId={`labelCombo-${id}`}
                            label={label}
                            disabled={isReadOnly}
                            value={selectedId}
                            onChange={e => {
                                const { value } = e.target;
                                OnSetSelectedChange(value);
                            }}
                        >
                            {
                                !showAllOption ? (
                                    <MenuItem value={OPTIONS_DEFAULT.NO_SELECTED_OPTION_CHAIN_ID}>
                                        Selecciona una Opción
                                    </MenuItem>
                                ) : (
                                    <MenuItem value={OPTIONS_DEFAULT.NO_SELECTED_OPTION_CHAIN_ID}>
                                        Mostrar Todos
                                    </MenuItem>
                                )
                            }
                            {
                                options.map((item: TRecord, index) => (
                                    shouldShowOption(item) && (
                                        <MenuItem key={`${id}-${item.chainId}-${index}`} value={item.chainId}>
                                            {item.nombre}
                                        </MenuItem>
                                    )
                                ))
                            }
                        </Select>
                    </FormControl>
                )
            }
        </>
    );

}


