import { faBookmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Typography } from "@mui/material";
import { formatCurrency } from "../../../utils/MonetFormater/MoneyFormater";


export interface IControlRenderResultPreguntaProps {

    pregunta: string;
    title: string;
    cantidad: number;
    colorMain: string;
    colorBg: string;

}


export const ControlRenderResultPregunta = ({

    pregunta,
    title,
    cantidad,
    colorMain,
    colorBg

}: IControlRenderResultPreguntaProps) => {

    return (
        <Grid container sx={{ background: colorBg, position: "relative", border: "solid 2.5px #e1e1e1", minHeight: "14rem" }}>
            <FontAwesomeIcon icon={faBookmark} color={colorMain} size="2x" style={{ position: "absolute", top: "0", right: "2rem" }} />
            <Grid item xs={12} container sx={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
                <Grid item xs={12}>
                    <Typography variant="h6" textAlign="center" fontWeight="bold">
                        {pregunta}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" textAlign="center" textTransform="uppercase">
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" textAlign="center" color={colorMain}>
                        {formatCurrency(cantidad)}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )

}