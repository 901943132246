import { faSave, faUserPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, LinearProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useContextApiService } from "../../../../../contexts/ContextApiService/ContextApiService";
import { defaultRecordModalidad } from "../../../../../data/defaults/records/defaultRecordModalidad";
import { useIsLoading } from "../../../../../hooks/Shared/useIsLoading/useIsLoading";
import { usePropChange } from "../../../../../hooks/Shared/usePropChange/usePropChange";
import { useValidateModalidad } from "../../../../../hooks/validators/useValidateModalidad/useValidateModalidad";
import { IRecordModalidad } from "../../../../../interfaces/Records/IRecordModalidad";
import { FormAdminModalidad } from "../../../../Forms/Admin/FormAdminModalidad/FormAdminModalidad";
import { IndicatorHeaderSection } from "../../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";
import { useModalDialogWindow } from "../../../../Shared/ModalDialog/hook/useModalDialogWindow";


export const PageAdminModalidadesEdit = () => {

    const { Record, SetRecord } = usePropChange<IRecordModalidad>(defaultRecordModalidad());
    const { isLoading, stateLoading, setLoadingState } = useIsLoading(true);
    const { ApiService } = useContextApiService();
    const { ShowModalEnviando, ShowModalError, ShowModalSuccessEdit } = useModalDialogWindow();
    const { validate } = useValidateModalidad();
    const navigate = useNavigate();
    const { id } = useParams();

    const onUserChange = (record: IRecordModalidad) => {

        SetRecord(record);

    }

    useEffect(() => {

        const getData = async () => {

            if (id === undefined) {
                navigate("/");
                return;
            }

            const record = await ApiService.Admin.Modalidades.GetByChainId(id);
            if (!record.success) {

                navigate("/");
                return;
            }

            SetRecord({
                ...record.registro,
                chainId: id
            });


        }

        getData().catch(console.error);

    }, []);

    const onSave = async () => {

        ShowModalEnviando();
        const response = await ApiService.Admin.Modalidades.Update(Record);

        if (!response.success) {

            const errorMesage = response.error ?? "Error al guardar el registro";
            ShowModalError(errorMesage);
            return;

        }

        ShowModalSuccessEdit();
        navigate("../");

    }

    useEffect(() => {

        if (Record.chainId.length <= 0) {
            return;
        }

        if (stateLoading.initialLoading) {

            setLoadingState({
                isLoading: false,
                initialLoading: false
            });

        }

    }, [Record]);

    return (
        <Grid container spacing={3}>
            {
                isLoading ? (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                ) : (
                    <>
                        <IndicatorHeaderSection
                            title="Editar Registro"
                            subtitle="Complete los campos para agregar un registro"
                            icon={faUserPlus}
                        />
                        <Grid item xs={12}>
                            <FormAdminModalidad
                                defaultRecord={Record}
                                onRecordChanged={onUserChange}
                            />
                        </Grid>
                        <Grid item xs={12} container direction="row-reverse" sx={{ mt: "2rem" }}>
                            <Grid item xs={12} md={4}>
                                <Button variant="contained" fullWidth disabled={!validate(Record)} onClick={onSave}>
                                    <Typography variant="button">
                                        <FontAwesomeIcon icon={faSave} /> Guardar
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )
            }

        </Grid>
    )



}