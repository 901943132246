import { IRecordPromoBanner } from "../../../interfaces/Records/IRecordPromoBanner";
import { ITableCoreEvents } from "../../Shared/_TableCore/ITableCoreEvents";
import { TableBaseContainer } from "../../Shared/_TableCore/TableBaseContainer";
import { TableFormatPromoBanners } from "./TableFormatPromoBanners";



export const TableAdminPromoBanners = ({

    records,
    OnEditRecord

}: ITableCoreEvents<IRecordPromoBanner>) => {


    return (
        <TableBaseContainer<IRecordPromoBanner>
            headers={["Acciones", "Nombre", "Descripcion", "Url Imagen", "Vigencia Inicio", "Vigencia Fin", "Activo", "Creado", "Modificado"]}
            records={records}
            OnEditRecord={OnEditRecord}
            TableRecordFormat={TableFormatPromoBanners}
            id="table-promos-banners"
        />
    )


}


