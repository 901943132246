import axios from "axios";
import { IRecordCarrera } from "../../../interfaces/Records/IRecordCarrera";
import { IResponseRecord } from "../../../interfaces/Responses/IResponseRecord";
import { IResponseRecords } from "../../../interfaces/Responses/IResponseRecords";
import { ServiceConnectorAPIBase } from "../../Core/ServiceConnectorAPIBase/ServiceConnectorAPIBase";
import { IServiceConnectorCarreras } from "./IServiceConnectorCarreras";


export class ServiceConnectorCarreras extends ServiceConnectorAPIBase implements IServiceConnectorCarreras {

    constructor() {

        super("v1/carreras");

    }

    public async GetByLineaNegocioId(lineaNegocioId: string): Promise<IResponseRecords<IRecordCarrera>> {

        const response = await axios.get<IResponseRecords<IRecordCarrera>>(`${this.BaseUrl}/lineaNegocio/${lineaNegocioId}`);
        return response.data;

    }

    public async GetAll(): Promise<IResponseRecords<IRecordCarrera>> {

        const response = await axios.get<IResponseRecords<IRecordCarrera>>(this.BaseUrl);
        console.info(response.data);
        return response.data;

    }

    public async GetById(id: string): Promise<IResponseRecord<IRecordCarrera>> {

        const response = await axios.get<IResponseRecord<IRecordCarrera>>(`${this.BaseUrl}/${id}`);
        return response.data;

    }

    public async Add(record: IRecordCarrera): Promise<IResponseRecord<IRecordCarrera>> {

        const response = await axios.post<IResponseRecord<IRecordCarrera>>(this.BaseUrl, {
            "clave": record.clave,
            "urlImagen": record.urlImagen,
            "lineaNegocioId": record.lineaNegocioId,
            "nombre": record.nombre,
        });
        return response.data;

    }

    public async Update(record: IRecordCarrera): Promise<IResponseRecord<IRecordCarrera>> {

        const response = await axios.put<IResponseRecord<IRecordCarrera>>(`${this.BaseUrl}`, {
            "chainId": record.chainId,
            "clave": record.clave,
            "urlImagen": record.urlImagen,
            "lineaNegocioId": record.lineaNegocioId,
            "nombre": record.nombre,
        });
        return response.data;

    }

}
