import { faSave, faUserPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useContextApiService } from "../../../../../contexts/ContextApiService/ContextApiService";
import { defaultRecordPromoPrecio } from "../../../../../data/defaults/records/defaultRecordPromoPrecio";
import { usePropChange } from "../../../../../hooks/Shared/usePropChange/usePropChange";
import { useValidatePromoPrecio } from "../../../../../hooks/validators/useValidatePromoBanner/useValidatePromoPrecio";
import { IRecordPromoPrecio } from "../../../../../interfaces/Records/IRecordPromoPrecio";
import { FormAdminPromoPrecio } from "../../../../Forms/Admin/FormAdminPromoPrecio/FormAdminPromoPrecio";
import { IndicatorHeaderSection } from "../../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";
import { useModalDialogWindow } from "../../../../Shared/ModalDialog/hook/useModalDialogWindow";


export const PageAdminPromoPrecioNew = () => {

    const { id } = useParams();
    const { Record, SetRecord } = usePropChange<IRecordPromoPrecio>({
        ...defaultRecordPromoPrecio(),
        universidadId: id ?? ""
    });
    const { ApiService } = useContextApiService();
    const { ShowModalEnviando, ShowModalError, ShowModalSuccessNew } = useModalDialogWindow();
    const { validate } = useValidatePromoPrecio();
    const navigate = useNavigate();

    const onChange = (record: IRecordPromoPrecio) => {

        if (id !== undefined) {
            record.universidadId = id;
            SetRecord(record);
        }

    }

    const onSave = async () => {

        ShowModalEnviando();
        const response = await ApiService.Admin.PromosPrecios.Add(Record);

        if (!response.success) {

            const errorMesage = response.error ?? "Error al guardar el registro";
            ShowModalError(errorMesage);
            return;

        }

        ShowModalSuccessNew();
        navigate("../");

    }

    useEffect(() => {

        if (id === undefined) {
            ShowModalError("No se ha seleccionado una universidad");
            navigate("../");
        }

    }, []);

    return (
        <Grid container  >
            <IndicatorHeaderSection
                title="Nuevo Registro"
                subtitle="Complete los campos para agregar un registro"
                icon={faUserPlus}
            />
            <Grid item xs={12}>
                <FormAdminPromoPrecio
                    defaultRecord={Record}
                    onRecordChanged={onChange}
                />
            </Grid>
            <Grid item xs={12} container direction="row-reverse" sx={{ mt: "2rem" }}>
                <Grid item xs={12} md={4}>
                    <Button variant="contained" fullWidth disabled={!validate(Record)} onClick={onSave}>
                        <Typography variant="button">
                            <FontAwesomeIcon icon={faSave} /> Guardar
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )

}