import axios from "axios";
import { IRecordCarreraConfig } from "../../../interfaces/Records/IRecordCarreraConfig";
import { IResponseRecord } from "../../../interfaces/Responses/IResponseRecord";
import { IResponseRecords } from "../../../interfaces/Responses/IResponseRecords";
import { ServiceConnectorAPIBase } from "../../Core/ServiceConnectorAPIBase/ServiceConnectorAPIBase";
import { IServiceConnectorCarrerasConfiguracion } from "./IServiceConnectorCarrerasConfiguracion";
import moment from "moment";


export class ServiceConnectorCarrerasConfig extends ServiceConnectorAPIBase implements IServiceConnectorCarrerasConfiguracion {

    constructor() {

        super("v1/carreraConfig");

    }

    public async GetByUniversidadLineaNegocioModalidad(universidadId: string, lineaNegocioId: string, modalidadId: string): Promise<IResponseRecords<IRecordCarreraConfig>> {

        const response = await axios.get<IResponseRecords<IRecordCarreraConfig>>(`${this.BaseUrl}/universidad/${universidadId}/linea/${lineaNegocioId}/modalidad/${modalidadId}`);
        return response.data;

    }


    public async GetByRecintoModalidadCarrera(recintoId: string, modalidadId: string, carreraId: string): Promise<IResponseRecords<IRecordCarreraConfig>> {

        const response = await axios.get<IResponseRecords<IRecordCarreraConfig>>(`${this.BaseUrl}/recinto/${recintoId}/modalidad/${modalidadId}/carrera/${carreraId}`);
        return response.data;

    }


    public async GetAll(): Promise<IResponseRecords<IRecordCarreraConfig>> {

        const response = await axios.get<IResponseRecords<IRecordCarreraConfig>>(this.BaseUrl);
        console.info(response.data);
        return response.data;

    }


    public async GetById(id: string): Promise<IResponseRecord<IRecordCarreraConfig>> {

        const response = await axios.get<IResponseRecord<any>>(`${this.BaseUrl}/${id}`);
        const record = response.data.registro;
        return {
            success: response.data.success,
            registro: {
                chainId: record.chainId,
                clave: record.clave,
                descripcion: record.descripcion,
                lineaNegocioId: record.lineaNegocioId,
                nombre: record.nombre,
                universidadId: record.universidadId,
                modalidadId: record.modalidadId,
                modalidadNombre: record.modalidadNombre,
                carreraId: record.carreraId,
                carreraNombre: record.carreraNombre,
                recintoId: record.campusId,
                recintoNombre: record.campusNombre,
                duracion: record.duracion,
                primerPago: record.primerPago,
                costoTotal: record.costoTotal,
                colegiatura: record.colegiatura,
                vigenciaInicio: moment(record.vigenciaInicio),
                vigenciaFin: moment(record.vigenciaFin),
                active: record.active,
                created: moment(record.created),
                updated: moment(record.updated)
            }
        };

    }


    public async GetByUniversidadId(universidadId: string, active?: boolean | undefined): Promise<IResponseRecords<IRecordCarreraConfig>> {

        const response = await axios.get<IResponseRecords<IRecordCarreraConfig>>(`${this.BaseUrl}/universidad/${universidadId}`);
        return response.data;

    }


    public async GetByCampusId(campusId: string, active?: boolean | undefined): Promise<IResponseRecords<IRecordCarreraConfig>> {

        const response = await axios.get<IResponseRecords<IRecordCarreraConfig>>(`${this.BaseUrl}/campus/${campusId}`);
        return response.data;

    }


    public async Add(record: IRecordCarreraConfig): Promise<IResponseRecord<IRecordCarreraConfig>> {

        const response = await axios.post<IResponseRecord<IRecordCarreraConfig>>(this.BaseUrl, {
            "modalidadId": record.modalidadId,
            "carreraId": record.carreraId,
            "campusId": record.recintoId,
            "duracion": record.duracion,
            "primerPago": record.primerPago,
            "costoTotal": record.costoTotal,
            "colegiatura": record.colegiatura,
            "vigenciaInicio": record.vigenciaInicio.format("YYYY-MM-DD"),
            "vigenciaFin": record.vigenciaFin.format("YYYY-MM-DD"),
        });
        return response.data;

    }


    public async Update(record: IRecordCarreraConfig): Promise<IResponseRecord<IRecordCarreraConfig>> {

        const response = await axios.put<IResponseRecord<IRecordCarreraConfig>>(`${this.BaseUrl}`, record);
        return response.data;

    }

}