import { Box, Button, Card, CardActionArea, CardActions, CardContent, Typography } from "@mui/material";
import { IControlButtonCampusProps } from "./IControlButtonCampusProps";
import { useContextUniversidad } from "../../../contexts/ContextUniversidad/ContextUniversidad";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faGraduationCap, faMapMarkerPlus, faTags } from "@fortawesome/pro-light-svg-icons";
import { ChipPromo } from "../../Chips/ChipPromo/ChipPromo";
import { ChipBeca } from "../../Chips/ChipBeca/ChipBeca";


export const ControlButtonCampus = ({

    campus,
    OnClick

}: IControlButtonCampusProps) => {

    const {

        ConfigStyle,
        Campus,
        PromoPrecio,
        PromoBecas

    } = useContextUniversidad();


    const checkForBecaActive = () => {

        return PromoBecas.some(x => x.recintoId === campus.chainId);

    }

    const checkPromoActive = () => {

        return PromoPrecio.some(x => x.recintoId === campus.chainId);

    }

    const onClick = () => {

        OnClick(campus);

    }


    const createBoxShadow = () => {

        return {
            boxShadow: `10px 10px 20px ${ConfigStyle.boxShadowColor}`
        }

    }

    const createOutlineSelected = () => {

        if (Campus.chainId !== campus.chainId) {
            return {};
        }

        return createBoxShadow();

    }

    return (
        <Card sx={{
            "&:hover": {
                boxShadow: createBoxShadow()
            },
            display: "flex",
            background: ConfigStyle.bgColor,
            boxShadow: createOutlineSelected(),
            maxWidth: "90%",
            minWidth: "90%",
            marginBottom: "1.5rem"
        }}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", position: "relative" }}>
                <Box sx={{
                    width: "100%",
                    height: "10rem",
                    position: "relative"
                }}>
                    <Box sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        zIndex: 1
                    }} />
                    <Box sx={{
                        height: "10rem",
                        width: "100%",
                        backgroundImage: `url(${campus.urlImagen})`,
                        backgroundSize: "cover",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        paddingRight: "0.5rem",
                        paddingTop: "0.5rem"
                    }}>
                        <Box sx={{
                            display: "flex",
                            minHeight: "2rem",
                            width: "100%",
                            paddingTop: "1rem",
                            justifyContent: "flex-end",
                        }}>
                            {
                                checkPromoActive() &&
                                <ChipPromo />
                            }{
                                checkForBecaActive() &&
                                <ChipBeca />
                            }
                        </Box>
                    </Box>
                </Box>
                <CardContent>
                    <Typography variant="h5" color="white">
                        {campus.nombre}
                    </Typography>
                    <Typography variant="body1" color="white">
                        {`${campus.calle} #${campus.numero}`}
                    </Typography>
                    <Typography variant="body1" color="white">
                        {`Del: ${campus.calle} - ${campus.numero} CP: ${campus.codigoPostal}`}
                    </Typography>

                    <CardActions>
                        {
                            campus.urlGoogleMaps && campus.urlGoogleMaps.length > 0 &&
                            <Button size="small" variant="contained" color="warning" href={campus.urlGoogleMaps} target="_blank">
                                <Typography variant="button">
                                    <FontAwesomeIcon icon={faMapMarkerPlus} /> Ver en Google Maps
                                </Typography>
                            </Button>
                        }
                        <Button size="small" variant="contained" color="success" onClick={onClick}>
                            <Typography variant="button">
                                <FontAwesomeIcon icon={faCheck} /> Seleccionar
                            </Typography>
                        </Button>
                    </CardActions>

                </CardContent>
            </Box>
        </Card>
    );


}