import { jwtDecode } from "jwt-decode";
import { PropsWithChildren, createContext, useContext, useState } from "react";
import { defaultContextAuthState } from "../../data/defaults/states/defaultContextAuthState";
import { IResponseAuth } from "../../interfaces/Responses/IResponseAuth";


export interface IContextAuthState {

    User: IResponseAuth;
    HasToken: boolean;

}


export interface IContextAuthStateHandlers {

    AuthState: IContextAuthState;
    CurrentUser: IResponseAuth;
    UpdateUser: (user: IResponseAuth) => void;


}


export const ContextAuthState = createContext<IContextAuthStateHandlers>({} as IContextAuthStateHandlers);

export const ContextAuthStateProvider = ({ children }: PropsWithChildren<{}>) => {

    const [authState, setAuthState] = useState<IContextAuthState>(defaultContextAuthState());

    const updateUser = (user: IResponseAuth) => {

        const decodeToken = jwtDecode(user.token);

        console.info("Token decoded", decodeToken);

        setAuthState({
            ...authState,
            User: user,
            HasToken: user.token.length > 0
        });

    }

    return (
        <ContextAuthState.Provider value={{
            AuthState: authState,
            CurrentUser: authState.User,
            UpdateUser: updateUser
        }}>
            {children}
        </ContextAuthState.Provider>
    )

}

export const useContextAuthStateProvider = (): IContextAuthStateHandlers => {

    const context = useContext(ContextAuthState);

    if (context === undefined) {
        throw new Error('useContextContextAuthStateProvider debe usarse dentro de ContextAuthStateProviderProvider');
    }

    return context;

}