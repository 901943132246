import axios from "axios";
import { END_POINT_CATALOG } from "../../../config/END_POINT_CATALOG";
import { ServiceConnectorAPIBase } from "../../Core/ServiceConnectorAPIBase/ServiceConnectorAPIBase";
import { IServiceConnectorAuth } from "./IServiceConnectorAuth";
import { IResponseAuth } from "../../../interfaces/Responses/IResponseAuth";



export class ServiceConnectorAuth extends ServiceConnectorAPIBase implements IServiceConnectorAuth {

    constructor() {

        super(`v1/${END_POINT_CATALOG.LOGIN}`);

    }

    public async Login(email: string, password: string): Promise<IResponseAuth> {

        const response = await axios.post(this.BaseUrl, {
            "email": email,
            "password": password,
        });

        console.log("Respuesta login:", response.data);
        return response.data;

    }

}
