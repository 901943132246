import { faGear, faUniversity } from "@fortawesome/pro-light-svg-icons";
import { FormControlLabel, Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import { defaultRecordLineaNegocio } from "../../../../data/defaults/records/defaultRecordLineaNegocio";
import { usePropChange } from "../../../../hooks/Shared/usePropChange/usePropChange";
import { useValidateModalidad } from "../../../../hooks/validators/useValidateModalidad/useValidateModalidad";
import { IRecordLineaNegocio } from "../../../../interfaces/Records/IRecordLineaNegocio";
import { IFormInteractionEditRecord } from "../../../../interfaces/Shared/Forms/IFormInteractionEditRecord";
import { IndicatorHeaderSection } from "../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";
import { IOSSwitch } from "../../../Shared/IOSSwitch/IOSSwitch";

export const FormAdminLineaNegocio = ({

    defaultRecord,
    onRecordChanged

}: IFormInteractionEditRecord<IRecordLineaNegocio>) => {

    const { Record, OnTextInputChanged, OnCheckBoxChanged, SetRecord } = usePropChange<IRecordLineaNegocio>(
        defaultRecord ?? defaultRecordLineaNegocio()
    );
    const { validate } = useValidateModalidad();


    useEffect(() => {

        if (validate(Record)) {

            console.log("Record is valid", Record);
            onRecordChanged(Record)

        }

    }, [Record]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} container spacing={3}>
                <IndicatorHeaderSection
                    title="Datos Generales"
                    icon={faUniversity}
                />
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.nombre}
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                        name="nombre"
                        type="text"
                        onChange={OnTextInputChanged("nombre")}
                        error={Record.nombre.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.clave}
                        label="Clave"
                        variant="outlined"
                        fullWidth
                        name="clave"
                        type="text"
                        onChange={OnTextInputChanged("clave")}
                        error={Record.clave.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.equivalencia}
                        label="Equivalencia"
                        variant="outlined"
                        fullWidth
                        name="equivalencia"
                        type="text"
                        onChange={OnTextInputChanged("equivalencia")}
                        error={Record.equivalencia.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.prioridad}
                        label="Prioridad"
                        variant="outlined"
                        fullWidth
                        name="clave"
                        type="number"
                        onChange={OnTextInputChanged("prioridad")}
                        error={Record.prioridad == 0}
                        required
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={3}>
                <IndicatorHeaderSection
                    title="Configuración Registro"
                    icon={faGear}
                />
                <Grid item xs={12} md={4}>
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                checked={Record.active}
                                onChange={OnCheckBoxChanged("active")}
                            />
                        }
                        label="¿Registro Activo?"
                    />
                </Grid>
            </Grid>

        </Grid>
    );

}