import { faUserPlus, faSave } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Button, Typography, LinearProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useContextApiService } from "../../../../../contexts/ContextApiService/ContextApiService";
import { defaultRecordUniversidadConfig } from "../../../../../data/defaults/records/defaultRecordUniversidadConfig";
import { usePropChange } from "../../../../../hooks/Shared/usePropChange/usePropChange";
import { useValidateUniversidadConfig } from "../../../../../hooks/validators/useValidateUniversidadConfig/useValidateUniversidadConfig";
import { IRecordUniversidadConfig } from "../../../../../interfaces/Records/IRecordUniversidadConfig";
import { FormAdminUniversidadConfig } from "../../../../Forms/Admin/FormAdminUniversidadConfig/FormAdminUniversidadConfig";
import { IndicatorHeaderSection } from "../../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";
import { useModalDialogWindow } from "../../../../Shared/ModalDialog/hook/useModalDialogWindow";
import { useEffect } from "react";
import { IResponseRecord } from "../../../../../interfaces/Responses/IResponseRecord";
import { useIsLoading } from "../../../../../hooks/Shared/useIsLoading/useIsLoading";

export const PageAdminUniversidadesConfigEdit = () => {

    const { Record, SetRecord } = usePropChange<IRecordUniversidadConfig>(defaultRecordUniversidadConfig());
    const { isLoading, setIsLoading } = useIsLoading(true);
    const { ApiService } = useContextApiService();
    const { ShowModalEnviando, ShowModalError, ShowModalSuccessNew } = useModalDialogWindow();
    const { validate: validateUniversidad } = useValidateUniversidadConfig();
    const navigate = useNavigate();
    const { id } = useParams();

    const onUserChange = (record: IRecordUniversidadConfig) => {

        SetRecord(record);

    }

    const onSave = async () => {

        ShowModalEnviando();
        let response: IResponseRecord<IRecordUniversidadConfig>;

        if (Record.isNew) {

            response = await ApiService.Admin.UniversidadConfiguracion.Add(Record);

        } else {

            response = await ApiService.Admin.UniversidadConfiguracion.Update(Record);

        }


        if (!response.success) {

            const errorMesage = response.error ?? "Error al guardar el registro";
            ShowModalError(errorMesage);
            return;

        }

        ShowModalSuccessNew();
        navigate("../");

    }

    useEffect(() => {

        const getData = async () => {

            if (id === undefined) {
                navigate("/");
                return;
            }

            const record = await ApiService.Admin.UniversidadConfiguracion.GetByUniversidadId(id);
            if (!record.success) {
                SetRecord({
                    ...Record,
                    chainId: id,
                });
                setTimeout(() => {

                    setIsLoading(false);

                }, 500);
                return;
            }

            SetRecord({
                ...record.registro,
                isNew: false
            });
            setTimeout(() => {

                setIsLoading(false);

            }, 500);

        }

        getData().catch(console.error);

    }, []);

    return (
        <Grid container  >
            <IndicatorHeaderSection
                title="Editar Registro"
                subtitle="Complete los campos para editar el registro"
                icon={faUserPlus}
            />
            <Grid item xs={12}>
                {
                    isLoading ? (
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    ) : (
                        <FormAdminUniversidadConfig
                            defaultRecord={Record}
                            onRecordChanged={onUserChange}
                        />
                    )
                }
            </Grid>
            <Grid item xs={12} container direction="row-reverse" sx={{ mt: "2rem" }}>
                <Grid item xs={12} md={4}>
                    <Button variant="contained" fullWidth disabled={!validateUniversidad(Record)} onClick={onSave}>
                        <Typography variant="button">
                            <FontAwesomeIcon icon={faSave} /> Guardar
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )

}