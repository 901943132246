import { IRecordCarreraConfig } from "../../../interfaces/Records/IRecordCarreraConfig";
import { IUseValidate } from "../IUseValidate";


export const useValidateCarreraConfig = (): IUseValidate<IRecordCarreraConfig> => {

    const validateCarreraConfig = (record: IRecordCarreraConfig): boolean => {

        console.log("Validating", record);

        if (record.carreraId.length <= 0) return false;
        if (record.modalidadId.length <= 0) return false;
        if (record.recintoId.length <= 0) return false;
        if (record.duracion <= 0) return false;
        if (record.primerPago <= 0) return false;
        if (record.costoTotal <= 0) return false;
        if (record.colegiatura <= 0) return false;
        if (record.vigenciaInicio === undefined) return false;
        if (record.vigenciaFin === undefined) return false;

        return true;

    }

    return {
        validate: validateCarreraConfig
    };

}