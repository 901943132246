import { Grid, Typography } from "@mui/material";

export const PageUserHome = () => {

    return (
        <Grid container  >
            <Grid item xs={12}>
                <Typography variant="h4" component="h1">
                    Bienvenido a la Calculadora Seleccione una opción
                </Typography>
            </Grid>
        </Grid>
    );

}