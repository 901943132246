import { Outlet, Route, Routes } from "react-router-dom"
import { PageUserHome } from "../components/Pages/User/PageUserHome/PageUserHome";
import { PageUserHomeUniversidad } from "../components/Pages/User/PageUserHomeUniversidad/PageUserHomeUniversidad";
import { PageUserNotFound } from "../components/Pages/User/PageUserNotFound/PageUserNotFound";
import { ContextUniversidadProvider } from "../contexts/ContextUniversidad/ContextUniversidad";



export const RoutesUser = () => {

    return (
        <Routes>
            <Route path="/" element={<PageUserHome />} />
            <Route path="/uni" element={<PageUserNotFound />} />
            <Route path="/uni/:idUniversidad" element={<Outlet />}>
                <Route index element={
                    <ContextUniversidadProvider>
                        <PageUserHomeUniversidad />
                    </ContextUniversidadProvider>
                } />
            </Route>
            <Route path="*" element={<PageUserNotFound />} />
        </Routes>
    );

}