import { faFaceSmile } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Chip } from "@mui/material"



export const ChipPromo = () => {

    return (
        <Chip
            sx={{
                background: "#3cb878",
                color: "white",
                zIndex: 2
            }}
            icon={
                <FontAwesomeIcon icon={faFaceSmile} color="white" />
            }
            label="Promoción"
        />
    )

}