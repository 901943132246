import { IRecordAppUser } from "../../../interfaces/Records/IRecordAppUser";
import { IUseValidate } from "../IUseValidate";


export const useValidateAppUser = (): IUseValidate<IRecordAppUser> => {

    const validateAppUser = (appUser: IRecordAppUser): boolean => {

        if (appUser.appUserDetails.nombre === "") return false;
        if (appUser.appUserDetails.apellidoPaterno === "") return false;
        if (appUser.appUserDetails.apellidoMaterno === "") return false;
        if (appUser.email === "") return false;
        if (appUser.password.length <= 8) return false;
        if (appUser.passwordConfirm.length <= 8) return false;
        if (appUser.password !== appUser.passwordConfirm) return false;
        if (appUser.rolesIds.length === 0) return false;

        return true;

    }

    return {
        validate: validateAppUser
    };

}