import { IRecordUniversidadConfig } from "../../../interfaces/Records/IRecordUniversidadConfig";
import { IUseValidate } from "../IUseValidate";

export const useValidateUniversidadConfig = (): IUseValidate<IRecordUniversidadConfig> => {

    const validate = (record: IRecordUniversidadConfig): boolean => {

        if (record.styleProps.length <= 0) return false;

        try {
            JSON.parse(record.styleProps);
        } catch (e) {
            return false;
        }

        return true;

    }

    return {
        validate: validate
    };

}