import axios from "axios";
import { IRecordUniversidad } from "../../../interfaces/Records/IRecordUniversidad";
import { IResponseRecord } from "../../../interfaces/Responses/IResponseRecord";
import { IResponseRecords } from "../../../interfaces/Responses/IResponseRecords";
import { ServiceConnectorAPIBase } from "../../Core/ServiceConnectorAPIBase/ServiceConnectorAPIBase";
import { IServiceConnectorUniversidades } from "./IServiceConnectorUniversidades";


export class ServiceConnectorUniversidades extends ServiceConnectorAPIBase implements IServiceConnectorUniversidades {

    constructor() {
        super("v1/universidades");
    }

    public async GetAll(): Promise<IResponseRecords<IRecordUniversidad>> {

        const response = await axios.get<IResponseRecords<IRecordUniversidad>>(this.BaseUrl);
        console.info(response.data);
        return response.data;

    }

    public async GetById(id: string): Promise<IResponseRecord<IRecordUniversidad>> {

        const response = await axios.get<IResponseRecord<IRecordUniversidad>>(`${this.BaseUrl}/${id}`);
        return response.data;

    }

    public async Add(record: IRecordUniversidad): Promise<IResponseRecord<IRecordUniversidad>> {

        const response = await axios.post<IResponseRecord<IRecordUniversidad>>(this.BaseUrl, {
            "nombre": record.nombre,
            "cssName": record.cssName,
            "urlPolitica": record.urlPolitica,
            "clave": record.clave,
            "urlCrm": record.urlCrm,
            "urlLogo": record.urlLogo,
            "whatsapp": record.whatsapp,
            "showColegiatura": record.showColegiatura,
            "showPagoTotal": record.showPagoTotal,
            "showPrimerPago": record.showPrimerPago,
            "active": true
        });
        return response.data;

    }

    public async Update(record: IRecordUniversidad): Promise<IResponseRecord<IRecordUniversidad>> {

        const response = await axios.put<IResponseRecord<IRecordUniversidad>>(`${this.BaseUrl}`, {
            "chainId": record.chainId,
            "nombre": record.nombre,
            "cssName": record.cssName,
            "urlPolitica": record.urlPolitica,
            "clave": record.clave,
            "urlCrm": record.urlCrm,
            "urlLogo": record.urlLogo,
            "whatsapp": record.whatsapp,
            "showColegiatura": record.showColegiatura,
            "showPagoTotal": record.showPagoTotal,
            "showPrimerPago": record.showPrimerPago,
            "active": record.active
        });
        return response.data;

    }

}