import { IServiceConnectorUniversidadesConfiguracion } from "../../Admin/ServiceConnectorUniversidadConfig/IServiceConnectorUniversidadesConfiguracion";
import { ServiceConnectorUniversidadConfig } from "../../Admin/ServiceConnectorUniversidadConfig/ServiceConnectorUniversidadConfig";


export interface IServiceConnectorAPIUser {

    UniversidadConfig: IServiceConnectorUniversidadesConfiguracion;

}

export class ServiceConnectorAPIUser implements IServiceConnectorAPIUser {

    private _UniversidadConfig: IServiceConnectorUniversidadesConfiguracion;

    constructor() {

        this._UniversidadConfig = new ServiceConnectorUniversidadConfig();

    }

    public get UniversidadConfig(): IServiceConnectorUniversidadesConfiguracion {
        return this._UniversidadConfig;
    }
    public set UniversidadConfig(v: IServiceConnectorUniversidadesConfiguracion) {
        this._UniversidadConfig = v;
    }

}