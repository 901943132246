
export const IndicatorHeaderSectionStyler = (color: string) => {

    return {
        mainContainer: {
            marginBottom: "1.5rem"
        },


        headerText: {
            borderBottom: "0.5rem solid",
            borderColor: `${color}.main`,
            color: "primary.main"
        }
    };
};
