import { IRecordRecinto } from "../../../interfaces/Records/IRecordRecinto"
import { ITableCoreEvents } from "../../Shared/_TableCore/ITableCoreEvents"
import { TableBaseContainer } from "../../Shared/_TableCore/TableBaseContainer"
import { TableFormatAdminRecintos } from "./TableFormatAdminRecintos"


export const TableAdminRecinto = ({

    records,
    OnEditRecord

}: ITableCoreEvents<IRecordRecinto>) => {


    return (
        <TableBaseContainer<IRecordRecinto>
            headers={["Acciones", "Nombre", "Universidad", "Calle", "Whatsapp", "Colonia", "C.P.", "Activo", "Creado", "Modificado"]}
            records={records}
            OnEditRecord={OnEditRecord}
            TableRecordFormat={TableFormatAdminRecintos}
            id="table-admin-recintos"
        />
    )


}



