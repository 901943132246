import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContextUniversidad } from "../../../../contexts/ContextUniversidad/ContextUniversidad";
import { ControlHeaderLogo } from "../../../Controls/ControlHeaderLogo/ControlHeaderLogo";
import { FormModalidadPicker } from "../../../Forms/User/FormModalidadPicker/FormModalidadPicker";
import { FormUserCampusPicker } from "../../../Forms/User/FormUserCampusPicker/FormUserCampusPicker";
import { SectionFormularioInicial } from "../../../Sections/SectionFormularioInicial/SectionFormularioInicial";
import { SectionNivelAcademico } from "../../../Sections/SectionNivelAcademico/SectionNivelAcademico";
import { SectionCarreraSelector } from "../../../Sections/SectionCarrera/SectionCarreraSelector";
import { SectionCampusSelector } from "../../../Sections/SectionCampus/SectionCampusSelector";
import { SectionResultado } from "../../../Sections/SectionResultado/SectionResultado";


export const PageUserHomeUniversidad = () => {

    const { idUniversidad } = useParams();
    const {
        AppUser,
        LineaNegocio,
        ConfigCarreraTemp,
        Campus,
    } = useContextUniversidad();
    const navigate = useNavigate();
    const location = useLocation();

    const { hash } = useLocation();

    useEffect(() => {

        if (hash === '') {
            window.scrollTo(0, 0);
        } else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }

    }, [hash]);

    useEffect(() => {

        if (AppUser.chainId.length > 0) {

            console.info("AppUser", AppUser);
            navigate(`#nivel-academico`);

        }

    }, [AppUser]);

    useEffect(() => {

        if (LineaNegocio.chainId.length > 0) {

            console.info("Selected linea de negocio,", LineaNegocio);
            navigate(`#seleccion-carrera`);

        }

    }, [LineaNegocio]);

    useEffect(() => {

        if (ConfigCarreraTemp.chainId.length > 0) {

            navigate(`#seleccion-campus`);

        }

    }, [ConfigCarreraTemp]);


    useEffect(() => {

        if (Campus.chainId.length > 0) {

            navigate(`#seleccion-colegiatura`);

        }

    }, [Campus]);


    useEffect(() => {
        if (location.hash) {
            const id = location.hash.replace('#', '');
            const elemento = document.getElementById(id);
            if (elemento) {
                elemento.scrollIntoView();
            }
        }
    }, [location]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} container>
                <SectionFormularioInicial />
            </Grid>
            {
                AppUser.chainId.length > 0 &&
                <Grid item xs={12} container>
                    <SectionNivelAcademico />
                </Grid>
            }
            {
                LineaNegocio.chainId.length > 0 &&
                <Grid item xs={12} container>
                    <SectionCarreraSelector />
                </Grid>
            }
            {
                ConfigCarreraTemp.chainId.length > 0 &&
                <Grid item xs={12} container>
                    <SectionCampusSelector />
                </Grid>
            }
            {
                Campus.chainId.length > 0 &&
                <Grid item xs={12} container>
                    <SectionResultado />
                </Grid>
            }
        </Grid>
    );

}