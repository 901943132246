import { faChevronRight, faKey, faUser } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, InputAdornment, Typography } from "@mui/material";
import { useContextApiService } from "../../../../contexts/ContextApiService/ContextApiService";
import { defaultRecordAppUser } from "../../../../data/defaults/records/defaultRecordAppUser";
import { usePropChange } from "../../../../hooks/Shared/usePropChange/usePropChange";
import { IRecordAppUser } from "../../../../interfaces/Records/IRecordAppUser";
import { TextFieldWhite } from "../../../Styled/TextFieldWhite";
import { useValidateAppUserLogin } from "../../../../hooks/validators/useValidateAppUserLogin/useValidateAppUserLogin";
import { useModalDialogWindow } from "../../../Shared/ModalDialog/hook/useModalDialogWindow";
import { useContextAuthStateProvider } from "../../../../contexts/ContextAuthState/ContextAuthState";
import { useEffect } from "react";
import { ROUTES_KEYS } from "../../../../routes/ROUTES_KEYS";
import { useNavigate } from "react-router-dom";



export const FormAdminLogin = () => {

    const { ApiService } = useContextApiService();
    const { UpdateUser, CurrentUser } = useContextAuthStateProvider();
    const { ShowModalError, ShowModalProgress, ResetModal } = useModalDialogWindow();
    const { Record, OnTextInputChanged } = usePropChange<IRecordAppUser>(defaultRecordAppUser());
    const { validate } = useValidateAppUserLogin();
    const navigate = useNavigate();

    const onLogIn = async () => {

        ShowModalProgress("Iniciando sesión");

        const response = await ApiService.Admin.Auth.Login(Record.email, Record.password);
        console.log(response);

        if (!response.success) {

            ShowModalError(response.error || "Error desconocido");
            return;
        }

        UpdateUser(response);
        navigate(`../${ROUTES_KEYS.ROOT_ADMIN_PATH}`);
        ResetModal();

    }

    useEffect(() => {

        if (CurrentUser.id.length > 0) {

        }


    }, [CurrentUser])

    return (
        <Grid container spacing={5} sx={{ paddingTop: "45%" }}>
            <Grid container spacing={5} item xs={12}>
                <Grid item xs={12}>
                    <TextFieldWhite
                        value={Record.email}
                        label="Usuario"
                        variant="filled"
                        fullWidth
                        name="nombre"
                        type="email"
                        onChange={OnTextInputChanged("email")}
                        error={Record.email.length <= 0}
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faUser} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWhite
                        value={Record.password}
                        label="Password"
                        variant="filled"
                        fullWidth
                        name="nombre"
                        type="password"
                        onChange={OnTextInputChanged("password")}
                        error={Record.password.length <= 0}
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faKey} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={5} item xs={12} justifyContent="flex-end">
                <Grid item xs={12} md={4}>
                    <Button fullWidth variant="contained" color="success" disabled={!validate(Record)} onClick={onLogIn}>
                        <Typography variant="button">
                            <FontAwesomeIcon icon={faChevronRight} /> Siguiente
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )

}