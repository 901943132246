import { IRecordPromoBanner } from "../../../interfaces/Records/IRecordPromoBanner";
import { IUseValidate } from "../IUseValidate";


export const useValidatePromoBanner = (): IUseValidate<IRecordPromoBanner> => {

    const validate = (record: IRecordPromoBanner): boolean => {

        if (record.nombre.length <= 0) return false;
        if (record.mensaje.length <= 0) return false;
        if (record.vigenciaInicio.isAfter(record.vigenciaFin)) return false;
        if (record.vigenciaFin.isBefore(record.vigenciaInicio)) return false;

        const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocolo
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // nombre de dominio
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // puerto y path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        if (!urlPattern.test(record.urlImagen)) return false;

        return true;

    }

    return {
        validate
    };

}

