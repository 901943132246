

export const ROUTES_KEYS = {

    ROOT_LOGIN: "authlogin",
    ROOT_ADMIN_PATH: "/dash",
    ROOT_ADM_UNIVERSIDADES: "universidades",
    ROOT_ADM_UNIVERSIDADES_CONFIG: "univ-config",
    ROOT_ADM_LINEA_NEGOCIO: "linea-negocio",
    ROOT_ADM_CAMPUS: "campus",
    ROOT_ADM_CARRERAS: "carreras",
    ROOT_ADM_CARRERAS_CONFIG: "carrera-config",
    ROOT_ADM_MODALIDADES: "modalidades",
    ROOT_ADM_USUARIOS: "usuarios",
    ROOT_ADM_PROMO_BANNERS: "banners",
    ROOT_ADM_PROMO_BECAS: "becas",
    ROOT_ADM_PROMO_PRECIO: "promo-precio",
    ROOT_ADM_HOME: "/",
    ROOT_ADM_NEW: "new",
    ROOT_ADM_EDIT: "edit",

}
