import { faFaceSmile, faGraduationCap } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, CardActionArea, Chip, Typography } from "@mui/material";
import { useContextUniversidad } from "../../../contexts/ContextUniversidad/ContextUniversidad";
import { IControlButtonLineaNegocioProps } from "./IControlButtonLineaNegocioProps";
import { ChipPromo } from "../../Chips/ChipPromo/ChipPromo";
import { ChipBeca } from "../../Chips/ChipBeca/ChipBeca";

export const ControlButtonLineaNegocio = ({

    lineaNegocio,
    OnClick

}: IControlButtonLineaNegocioProps) => {

    const {
        ConfigStyle,
        LineaNegocio,
        PromoPrecio,
        PromoBecas
    } = useContextUniversidad();

    const checkBecaActive = () => {

        return PromoBecas.some(x => x.lineaNegocioId === lineaNegocio.chainId);

    }

    const checkPromoActive = () => {

        return PromoPrecio.some(x => x.lineaNegocioId === lineaNegocio.chainId);

    }

    const onClick = () => {

        OnClick(lineaNegocio);

    }

    const getUrlBgImage = () => {

        if (ConfigStyle.lineaStyles.length <= 0) {
            return "";
        }

        var response = ConfigStyle.lineaStyles.find(x => x.id.toUpperCase() === lineaNegocio.chainId.toUpperCase());
        if (response) {
            return response.urlBg;
        }

        return "";

    }


    const createBoxShadow = () => {

        return {
            boxShadow: `2px 2px 20px ${ConfigStyle.boxShadowColor}`
        }

    }

    const createSelectedOption = () => {

        if (LineaNegocio.chainId !== lineaNegocio.chainId) {
            return {};
        }

        return createBoxShadow();



    }

    return (
        <Card sx={{
            display: "flex",
            backgroundImage: `url(${getUrlBgImage()})`,
            backgroundSize: "cover",
            backgroundPosition: "center certer",
            boxShadow: createSelectedOption(),
            maxWidth: "100%",
            minWidth: "100%",
            minHeight: "20rem",
            '&:hover': {
                boxShadow: createBoxShadow()
            }
        }}>
            <CardActionArea onClick={onClick}>
                <Box sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 1
                }} />
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "100%",
                    width: "100%",
                }}>
                    <Box sx={{
                        display: "flex",
                        minHeight: "2rem",
                        width: "100%",
                        paddingTop: "1rem",
                        paddingRight: "1rem",
                        justifyContent: "flex-end",
                    }}>
                        {
                            checkBecaActive() &&
                            <ChipBeca />
                        }
                        {
                            checkPromoActive() &&
                            <ChipPromo />
                        }
                    </Box>
                    <Typography variant="h6" color="white" sx={{ zIndex: 2 }}>
                        {lineaNegocio.nombre}
                    </Typography>
                </Box>
            </CardActionArea>
        </Card>
    );


}