import axios from "axios";
import { IRecordAppRole } from "../../../interfaces/Records/IRecordAppRole";
import { IResponseRecords } from "../../../interfaces/Responses/IResponseRecords";
import { ServiceConnectorAPIBase } from "../../Core/ServiceConnectorAPIBase/ServiceConnectorAPIBase";
import { IServiceConnectorRoles } from "./IServiceConnectorRoles";


export class ServiceConnectorRoles extends ServiceConnectorAPIBase implements IServiceConnectorRoles {

    constructor() {

        super(`v1/roles`);

    }

    public async GetAll(): Promise<IResponseRecords<IRecordAppRole>> {

        const response = await axios.get<IResponseRecords<IRecordAppRole>>(this.BaseUrl);

        console.info(response.data);

        return response.data;

    }


}