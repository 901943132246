import { Outlet, Route, Routes } from "react-router-dom";
import { PageAdminCampusHome } from "../components/Pages/Admin/campus/PageAdminCampusHome/PageAdminCampusHome";
import { PageAdminCarrerasHome } from "../components/Pages/Admin/carreras/PageAdminCarrerasHome/PageAdminCarrerasHome";
import { PageAdminDashboard } from "../components/Pages/Admin/container/PageAdminDashboard/PageAdminDashboard";
import { PageAdminLineaNegocioHome } from "../components/Pages/Admin/lineaNegocio/PageAdminLineaNegocioHome/PageAdminLineaNegocioHome";
import { PageAdminLineaNegocioNew } from "../components/Pages/Admin/lineaNegocio/PageAdminLineaNegocioNew/PageAdminLineaNegocioNew";
import { PageAdminModalidadesHome } from "../components/Pages/Admin/modalidades/PageAdminModalidadesHome/PageAdminModalidadesHome";
import { PageAdminModalidadesdNew } from "../components/Pages/Admin/modalidades/PageAdminModalidadesNew/PageAdminModalidadesNew";
import { PageAdminUniversidadesHome } from "../components/Pages/Admin/universidades/PageAdminUniversidadesHome/PageAdminUniversidadesHome";
import { PageAdminUniversidadNew } from "../components/Pages/Admin/universidades/PageAdminUniversidadesNew/PageAdminUniversidadesNew";
import { PageAdminUsuariosHome } from "../components/Pages/Admin/usuarios/PageAdminUsuariosHome/PageAdminUsuariosHome";
import { PageAdminUsuariosNew } from "../components/Pages/Admin/usuarios/PageAdminUsuariosNew/PageAdminUsuariosNew";
import { PageUserNotFound } from "../components/Pages/User/PageUserNotFound/PageUserNotFound";
import { ROUTES_KEYS } from "./ROUTES_KEYS";
import { PageAdminCampusNew } from "../components/Pages/Admin/campus/PageAdminCampusNew/PageAdminCampusNew";
import { PageAdminCarrerasNew } from "../components/Pages/Admin/carreras/PageAdminCarrerasNew/PageAdminCarrerasNew";
import { PageAdminCarreraConfigHome } from "../components/Pages/Admin/carrerasConfig/PageAdminCarreraConfigHome/PageAdminCarreraConfigHome";
import { PageAdminCarreraConfigNew } from "../components/Pages/Admin/carrerasConfig/PageAdminCarreraConfigNew/PageAdminCarreraConfigNew";
import { PageAdminUniversidadesConfigHome } from "../components/Pages/Admin/universidadesConfig/PageAdminUniversidadesConfigHome/PageAdminUniversidadesConfigHome";
import { PageAdminUniversidadesConfigNew } from "../components/Pages/Admin/universidadesConfig/PageAdminUniversidadesConfigNew/PageAdminUniversidadesConfigNew";
import { PageAdminUniversidadesConfigEdit } from "../components/Pages/Admin/universidadesConfig/PageAdminUniversidadesConfigEdit/PageAdminUniversidadesConfigEdit";
import { PageAdminUsuariosEdit } from "../components/Pages/Admin/usuarios/PageAdminUsuariosEdit/PageAdminUsuariosEdit";
import { PageAdminHome } from "../components/Pages/Admin/home/PageAdminHome";
import { PageAdminUniversidadesEdit } from "../components/Pages/Admin/universidades/PageAdminUniversidadesEdit/PageAdminUniversidadesEdit";
import { PageAdminLineaNegocioEdit } from "../components/Pages/Admin/lineaNegocio/PageAdminLineaNegocioEdit/PageAdminLineaNegocioEdit";
import { PageAdminModalidadesEdit } from "../components/Pages/Admin/modalidades/PageAdminModalidadesEdit/PageAdminModalidadesEdit";
import { PageAdminPromoBannersHome } from "../components/Pages/Admin/promoBanners/PageAdminPromoBannersHome/PageAdminPromoBannersHome";
import { PageAdminPromoBannersNew } from "../components/Pages/Admin/promoBanners/PageAdminPromoBannersNew/PageAdminPromoBannersNew";
import { PageAdminPromoBannersEdit } from "../components/Pages/Admin/promoBanners/PageAdminPromoBannersEdit/PageAdminPromoBannersEdit";
import { PageAdminPromoBecasHome } from "../components/Pages/Admin/promoBecas/PageAdminPromoBecasHome/PageAdminPromoBecasHome";
import { PageAdminPromoBecasNew } from "../components/Pages/Admin/promoBecas/PageAdminPromoBecasNew/PageAdminPromoBecasNew";
import { PageAdminPromoBecasEdit } from "../components/Pages/Admin/promoBecas/PageAdminPromoBecasEdit/PageAdminPromoBecasEdit";
import { PageAdminPromoPrecioHome } from "../components/Pages/Admin/promoPrecio/PageAdminPromoPrecioHome/PageAdminPromoPrecioHome";
import { PageAdminPromoPrecioNew } from "../components/Pages/Admin/promoPrecio/PageAdminPromoPrecioNew/PageAdminPromoPrecioNew";
import { PageAdminPromoPrecioEdit } from "../components/Pages/Admin/promoPrecio/PageAdminPromoPrecioEdit/PageAdminPromoPrecioEdit";
import { PageAdminCampusEdit } from "../components/Pages/Admin/campus/PageAdminCampusEdit/PageAdminCampusEdit";
import { PageAdminCarrerasEdit } from "../components/Pages/Admin/carreras/PageAdminCarrerasEdit/PageAdminCarrerasEdit";
import { PageAdminCarreraConfigEdit } from "../components/Pages/Admin/carrerasConfig/PageAdminCarreraConfigEdit/PageAdminCarreraConfigEdit";



export const RoutesAdmin = () => {

    return (
        <Routes>
            <Route path="/" element={<PageAdminDashboard />}>
                <Route index element={<PageAdminHome />} />

                <Route path={ROUTES_KEYS.ROOT_ADM_UNIVERSIDADES} element={<Outlet />}>
                    <Route index element={<PageAdminUniversidadesHome />} />
                    <Route path={ROUTES_KEYS.ROOT_ADM_NEW} element={<PageAdminUniversidadNew />} />
                    <Route path={`${ROUTES_KEYS.ROOT_ADM_EDIT}/:id`} element={<PageAdminUniversidadesEdit />} />
                </Route>

                <Route path={ROUTES_KEYS.ROOT_ADM_UNIVERSIDADES_CONFIG} element={<Outlet />}>
                    <Route index element={<PageAdminUniversidadesConfigHome />} />
                    <Route path={ROUTES_KEYS.ROOT_ADM_NEW} element={<PageAdminUniversidadesConfigNew />} />
                    <Route path={`${ROUTES_KEYS.ROOT_ADM_EDIT}/:id`} element={<PageAdminUniversidadesConfigEdit />} />
                </Route>

                <Route path={ROUTES_KEYS.ROOT_ADM_LINEA_NEGOCIO} element={<Outlet />}>
                    <Route index element={<PageAdminLineaNegocioHome />} />
                    <Route path={ROUTES_KEYS.ROOT_ADM_NEW} element={<PageAdminLineaNegocioNew />} />
                    <Route path={`${ROUTES_KEYS.ROOT_ADM_EDIT}/:id`} element={<PageAdminLineaNegocioEdit />} />
                </Route>

                <Route path={ROUTES_KEYS.ROOT_ADM_CAMPUS} element={<Outlet />}>
                    <Route index element={<PageAdminCampusHome />} />
                    <Route path={`${ROUTES_KEYS.ROOT_ADM_NEW}/:id`} element={<PageAdminCampusNew />} />
                    <Route path={`${ROUTES_KEYS.ROOT_ADM_EDIT}/:id`} element={<PageAdminCampusEdit />} />
                </Route>

                <Route path={ROUTES_KEYS.ROOT_ADM_CARRERAS} element={<Outlet />}>
                    <Route index element={<PageAdminCarrerasHome />} />
                    <Route path={`${ROUTES_KEYS.ROOT_ADM_NEW}/:id`} element={<PageAdminCarrerasNew />} />
                    <Route path={`${ROUTES_KEYS.ROOT_ADM_EDIT}/:id`} element={<PageAdminCarrerasEdit />} />
                </Route>

                <Route path={ROUTES_KEYS.ROOT_ADM_CARRERAS_CONFIG} element={<Outlet />}>
                    <Route index element={<PageAdminCarreraConfigHome />} />
                    <Route path={`${ROUTES_KEYS.ROOT_ADM_NEW}/:id`} element={<PageAdminCarreraConfigNew />} />
                    <Route path={`${ROUTES_KEYS.ROOT_ADM_EDIT}/:id`} element={<PageAdminCarreraConfigEdit />} />
                </Route>

                <Route path={ROUTES_KEYS.ROOT_ADM_MODALIDADES} element={<Outlet />}>
                    <Route index element={<PageAdminModalidadesHome />} />
                    <Route path={ROUTES_KEYS.ROOT_ADM_NEW} element={<PageAdminModalidadesdNew />} />
                    <Route path={`${ROUTES_KEYS.ROOT_ADM_EDIT}/:id`} element={<PageAdminModalidadesEdit />} />
                </Route>

                <Route path={ROUTES_KEYS.ROOT_ADM_USUARIOS} element={<Outlet />}>
                    <Route index element={<PageAdminUsuariosHome />} />
                    <Route path={ROUTES_KEYS.ROOT_ADM_NEW} element={<PageAdminUsuariosNew />} />
                    <Route path={`${ROUTES_KEYS.ROOT_ADM_EDIT}/:id`} element={<PageAdminUsuariosEdit />} />
                </Route>

                <Route path={ROUTES_KEYS.ROOT_ADM_PROMO_BANNERS} element={<Outlet />}>
                    <Route index element={<PageAdminPromoBannersHome />} />
                    <Route path={`${ROUTES_KEYS.ROOT_ADM_NEW}/:id`} element={<PageAdminPromoBannersNew />} />
                    <Route path={`${ROUTES_KEYS.ROOT_ADM_EDIT}/:id`} element={<PageAdminPromoBannersEdit />} />
                </Route>

                <Route path={ROUTES_KEYS.ROOT_ADM_PROMO_BECAS} element={<Outlet />}>
                    <Route index element={<PageAdminPromoBecasHome />} />
                    <Route path={`${ROUTES_KEYS.ROOT_ADM_NEW}/:id`} element={<PageAdminPromoBecasNew />} />
                    <Route path={`${ROUTES_KEYS.ROOT_ADM_EDIT}/:id`} element={<PageAdminPromoBecasEdit />} />
                </Route>

                <Route path={ROUTES_KEYS.ROOT_ADM_PROMO_PRECIO} element={<Outlet />}>
                    <Route index element={<PageAdminPromoPrecioHome />} />
                    <Route path={`${ROUTES_KEYS.ROOT_ADM_NEW}/:id`} element={<PageAdminPromoPrecioNew />} />
                    <Route path={`${ROUTES_KEYS.ROOT_ADM_EDIT}/:id`} element={<PageAdminPromoPrecioEdit />} />
                </Route>

                <Route path="*" element={<PageUserNotFound />} />
            </Route>
        </Routes>
    );

};
