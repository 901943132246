import { faPlusCircle, faUsers } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useContextApiService } from "../../../../../contexts/ContextApiService/ContextApiService";
import { useIsLoading } from "../../../../../hooks/Shared/useIsLoading/useIsLoading";
import { IRecordAppUser } from "../../../../../interfaces/Records/IRecordAppUser";
import { ROUTES_KEYS } from "../../../../../routes/ROUTES_KEYS";
import { IndicatorHeaderSection } from "../../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";
import { TableAppUsers } from "../../../../Tables/TableAppUsers/TableAppUsers";

export const PageAdminUsuariosHome = () => {

    const { ApiService } = useContextApiService();
    const { isLoading, setIsLoading } = useIsLoading(true);
    const [users, setUsers] = useState<IRecordAppUser[]>([]);
    const navigate = useNavigate();


    useEffect(() => {

        const getUsers = async () => {

            const response = await ApiService.Admin.Usuarios.GetAll();
            console.info("Usuarios", response.registros);
            setUsers(response.registros);
            setIsLoading(false);

        };

        getUsers().catch(console.error);

    }, []);


    return (
        <Grid container spacing={3}>
            <IndicatorHeaderSection
                title="Usuarios y Perfiles"
                icon={faUsers}
                color="secondary"
                subtitle="Gestión de usuarios y perfiles."
            />
            <Grid container item xs={12}>
                <Grid item xs={12} md={4}>
                    <Link to={ROUTES_KEYS.ROOT_ADM_NEW} style={{ textDecoration: 'none' }}>
                        <Button variant="contained" color="primary" fullWidth>
                            <Typography variant="button">
                                <FontAwesomeIcon icon={faPlusCircle} /> Crear usuario
                            </Typography>
                        </Button>
                    </Link>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <IndicatorHeaderSection
                    title="Usuarios Registrados"
                    icon={faUsers}
                    color="warning"
                />
                {
                    isLoading ? (
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    ) : (
                        <TableAppUsers
                            records={users}
                            OnEditRecord={(record) => {
                                console.log("Edit", record);
                                navigate(`${ROUTES_KEYS.ROOT_ADM_EDIT}/${record.chainId}`);
                            }}
                        />
                    )
                }
            </Grid>
        </Grid>
    );

}