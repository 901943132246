import { IRecordCarreraConfig } from "../../../interfaces/Records/IRecordCarreraConfig";
import { ITableCoreEvents } from "../../Shared/_TableCore/ITableCoreEvents";
import { TableBaseContainer } from "../../Shared/_TableCore/TableBaseContainer";
import { TableFormatAdminCarrerasConfig } from "./TableFormatAdminCarrerasConfig";



export const TableAdminCarrerasConfig = ({

    records,
    OnEditRecord

}: ITableCoreEvents<IRecordCarreraConfig>) => {


    return (
        <TableBaseContainer<IRecordCarreraConfig>
            headers={["Acciones", "Carrera", "Modalidad", "Campus", "Duracion", "Primer Pago", "Costo Total", "Colegiatura", "Vigencia", "Activo"]}
            records={records}
            OnEditRecord={OnEditRecord}
            TableRecordFormat={TableFormatAdminCarrerasConfig}
            id="table-admin-carrera"
        />
    )


}


