

export const FORMATS = {

    DATE_FORMAT_UNDERSCORE: "YYYY_MM_DDTHH_mm_ss",
    DATE_TO_SQL: "YYYY-MM-DDTHH:mm:ss",
    DATE_FORMAT_TO_TXT: "DD/MM/YYYY",
    DATE_FORMAT_TO_TXT_WITH_TIME: "DD/MM/YYYY HH:mm:ss",
    TIME_FORMAT_TO_TXT: "HH:mm:ss"

}
