import { IServiceConnectorAPIAdmin } from "../ServiceConnectorAPIAdmin/IServiceConnectorAPIAdmin";
import { ServiceConnectorAPIAdmin } from "../ServiceConnectorAPIAdmin/ServiceConnectorAPIAdmin";
import { IServiceConnectorAPIUser, ServiceConnectorAPIUser } from "../ServiceConnectorAPIUser/ServiceConnectorAPIUser";
import { IServiceConnectorAPI } from "./IServiceConnectorAPI";


export class ServiceConnectorAPI implements IServiceConnectorAPI {


    private _Admin: IServiceConnectorAPIAdmin;
    private _User: IServiceConnectorAPIUser;

    constructor() {

        this._Admin = new ServiceConnectorAPIAdmin();
        this._User = new ServiceConnectorAPIUser();

    }

    public get User(): IServiceConnectorAPIUser {
        return this._User;
    }
    public set User(v: IServiceConnectorAPIUser) {
        this._User = v;
    }

    public get Admin(): IServiceConnectorAPIAdmin {
        return this._Admin;
    }
    public set Admin(v: IServiceConnectorAPIAdmin) {
        this._Admin = v;
    }


}