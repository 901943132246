import { Grid, Typography } from "@mui/material"
import { FormAdminLogin } from "../../../Forms/Admin/FormAdminLogin/FormAdminLogin"



export const PageAdminHome = () => {

    return (
        <Grid id="form-inicial" container spacing={5} sx={{ padding: "3rem" }}>
            <Grid item xs={12} container spacing={5}>
                <Typography variant="h3">
                    Bienvenido
                </Typography>
            </Grid>
            <Grid item xs={12} container spacing={5}>
                <Typography variant="h5">
                    Seleccione una opción del menú para comenzar.
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{
                paddingTop: "0 !important",
                display: { xs: "none", sm: "none", md: "flex" },
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                flexWrap: "wrap",
            }}>
                <img src={`${process.env.PUBLIC_URL}/CalculadoraLogoVerde.png`} alt="login calculadora" style={{ objectFit: 'cover' }} />
            </Grid>
        </Grid>
    )

}