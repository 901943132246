import { faSave, faUserPlus } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useContextApiService } from "../../../../../contexts/ContextApiService/ContextApiService"
import { defaultRecordAppUser } from "../../../../../data/defaults/records/defaultRecordAppUser"
import { usePropChange } from "../../../../../hooks/Shared/usePropChange/usePropChange"
import { useValidateAppUser } from "../../../../../hooks/validators/useValidateAppUser/useValidateAppUser"
import { IRecordAppUser } from "../../../../../interfaces/Records/IRecordAppUser"
import { FormAdminAppUser } from "../../../../Forms/Admin/FormAdminAppUser/FormAdminAppUser"
import { IndicatorHeaderSection } from "../../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection"
import { useModalDialogWindow } from "../../../../Shared/ModalDialog/hook/useModalDialogWindow"

export const PageAdminUsuariosNew = () => {

    const { Record, SetRecord } = usePropChange<IRecordAppUser>(defaultRecordAppUser());
    const { ApiService } = useContextApiService();
    const { ShowModalEnviando, ShowModalError, ShowModalSuccessNew } = useModalDialogWindow();
    const { validate: validateAppUser } = useValidateAppUser();
    const navigate = useNavigate();

    const onUserChange = (record: IRecordAppUser) => {

        SetRecord(record);

    }

    const onSave = async () => {

        ShowModalEnviando();
        const response = await ApiService.Admin.Usuarios.Add(Record);

        if (!response.success) {

            const errorMesage = response.error ?? "Error al guardar el usuario";
            ShowModalError(errorMesage);
            return;

        }

        ShowModalSuccessNew();
        navigate("../");

    }

    return (
        <Grid container  >
            <IndicatorHeaderSection
                title="Nuevo usuario"
                subtitle="Complete los campos para agregar un nuevo usuario"
                icon={faUserPlus}
            />
            <Grid item xs={12}>
                <FormAdminAppUser
                    onRecordChanged={onUserChange}
                />
            </Grid>
            <Grid item xs={12} container direction="row-reverse" sx={{ mt: "2rem" }}>
                <Grid item xs={12} md={4}>
                    <Button variant="contained" fullWidth disabled={!validateAppUser(Record)} onClick={onSave}>
                        <Typography variant="button">
                            <FontAwesomeIcon icon={faSave} /> Guardar
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )

}