import { IRecordUniversidad } from "../../../interfaces/Records/IRecordUniversidad";
import { ITableCoreEvents } from "../../Shared/_TableCore/ITableCoreEvents";
import { TableBaseContainer } from "../../Shared/_TableCore/TableBaseContainer";
import { TableFormatAdminUniversidad } from "./TableFormatAdminUniversidad";


export const TableAdminUniversidad = ({

    records,
    OnEditRecord

}: ITableCoreEvents<IRecordUniversidad>) => {


    return (
        <TableBaseContainer<IRecordUniversidad>
            headers={["Acciones", "Nombre", "Whatsapp", "Mostrar Primer Pago", "Mostrar Colegiatura", "Mostrar Pago Total", "Activo", "Creado", "Modificado"]}
            records={records}
            OnEditRecord={OnEditRecord}
            TableRecordFormat={TableFormatAdminUniversidad}
            id="table-admin-universidad"
        />
    )


}


