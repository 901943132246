import { faInfo, faTimesCircle, faWarning, faCheckCircle, faTimer } from "@fortawesome/pro-light-svg-icons";
import { FactoryRegister } from "../../../utils/FactoryRegister/FactoryRegister";
import { IModalBrandingProps } from "./ContextModalWindow/IModalBrandingProps";
import { ModalDialogVariantType } from "./ModalDialogVariantType";
import { ModalDialogBrandingError } from "./Variants/ModalDialogBrandingError";
import { ModalDialogBrandingProgress } from "./Variants/ModalDialogBrandingProgress";
import { ModalDialogBrandingSuccess } from "./Variants/ModalDialogBrandingSuccess";
import { ModalDialogBrandingText } from "./Variants/ModalDialogBrandingText";

export const defaultModalContainer = (): FactoryRegister<ModalDialogVariantType, IModalBrandingProps> => {

    console.info("Create Default Modal Container");

    const modalContainer = new FactoryRegister<ModalDialogVariantType, IModalBrandingProps>();
    modalContainer.Register("info", new ModalDialogBrandingText(faInfo));
    modalContainer.Register("error", new ModalDialogBrandingError(faTimesCircle));
    modalContainer.Register("warning", new ModalDialogBrandingText(faWarning));
    modalContainer.Register("success", new ModalDialogBrandingSuccess(faCheckCircle));
    modalContainer.Register("progress", new ModalDialogBrandingProgress(faTimer));

    return modalContainer;

}
