import { useEffect, useRef } from "react";
import { IUseDidMount } from "./IUseDidMount";

export const useDidMount = (): IUseDidMount => {

    const didMount = useRef(false);

    useEffect(() => {

        if (!didMount.current) {
            didMount.current = true;
        }

    }, []);

    return {
        didMount
    };

};