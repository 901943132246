import { ThemeProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { esES } from '@mui/x-date-pickers/locales';
import { ContextModalWindowProvider } from './components/Shared/ModalDialog/ContextModalWindow/ContextModalWindow';
import { defaultModalContainer } from './components/Shared/ModalDialog/defaultModalContainer';
import { ContextApiServiceProvider } from './contexts/ContextApiService/ContextApiService';
import { ContextAuthStateProvider } from './contexts/ContextAuthState/ContextAuthState';
import { AppRoutes } from './routes/AppRoutes';
import { ThemeLight } from './theme/ThemeLight';

function App() {

  const modalContainer = defaultModalContainer();

  return (
    <ThemeProvider theme={ThemeLight}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <ContextApiServiceProvider>
          <ContextAuthStateProvider>
            <ContextModalWindowProvider modalFactory={modalContainer}>
              <AppRoutes />
            </ContextModalWindowProvider>
          </ContextAuthStateProvider>
        </ContextApiServiceProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
