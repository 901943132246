import { faUniversity, faListDropdown, faSchoolFlag, faListTree, faTimesToSlot } from "@fortawesome/pro-light-svg-icons";
import { MODULE_ADM_CATALOG } from "../../../config/MODULE_CATALOG";
import { IDrawerNavigationLink } from "../../../interfaces/Navigation/IDrawerNavigationLink";
import { ROUTES_KEYS } from "../../../routes/ROUTES_KEYS";



export const defaultNavDrawerAdminMenuCatalogos = (): IDrawerNavigationLink[] => {

    return [
        {
            moduleId: MODULE_ADM_CATALOG.UNIVERSIDADES,
            icon: faUniversity,
            title: "Universidades",
            path: ROUTES_KEYS.ROOT_ADM_UNIVERSIDADES
        },
        {
            moduleId: MODULE_ADM_CATALOG.LINEAS_NEGOCIO,
            icon: faListDropdown,
            title: "Lineas de Negocio",
            path: ROUTES_KEYS.ROOT_ADM_LINEA_NEGOCIO
        },
        {
            moduleId: MODULE_ADM_CATALOG.CAMPUS,
            icon: faSchoolFlag,
            title: "Campus",
            path: ROUTES_KEYS.ROOT_ADM_CAMPUS
        },
        {
            moduleId: MODULE_ADM_CATALOG.CARERAS,
            icon: faListTree,
            title: "Carreras",
            path: ROUTES_KEYS.ROOT_ADM_CARRERAS
        },
        {
            moduleId: MODULE_ADM_CATALOG.MODALIDADES,
            icon: faTimesToSlot,
            title: "Modalidades",
            path: ROUTES_KEYS.ROOT_ADM_MODALIDADES
        },
    ];

};
