import moment from "moment";
import { IRecordModalidad } from "../../../interfaces/Records/IRecordModalidad";



export const defaultRecordModalidad = (): IRecordModalidad => {

    return {
        chainId: "",
        clave: "",
        nombre: "",
        descripcion: "",
        active: true,
        created: moment(),
        updated: moment()
    };

};
