import axios from "axios";
import moment from "moment";
import { FORMATS } from "../../../config/FORMATS";
import { IRecordPromoPrecio } from "../../../interfaces/Records/IRecordPromoPrecio";
import { IResponseRecord } from "../../../interfaces/Responses/IResponseRecord";
import { IResponseRecords } from "../../../interfaces/Responses/IResponseRecords";
import { ServiceConnectorAPIBase } from "../../Core/ServiceConnectorAPIBase/ServiceConnectorAPIBase";
import { IServiceConnectorPromoPrecio } from "./IServiceConnectorPromoPrecio";


export class ServiceConnectorPromoPrecio extends ServiceConnectorAPIBase implements IServiceConnectorPromoPrecio {

    constructor() {
        super("v1/promoPrecio");

    }

    public async GetByUniversidadId(universidadId: string): Promise<IResponseRecords<IRecordPromoPrecio>> {

        const response = await axios.get<IResponseRecords<IRecordPromoPrecio>>(`${this.BaseUrl}/universidad/${universidadId}`);
        console.info(response.data);
        response.data.registros = response.data.registros.map(this.FormatRecordFromSQL);
        return response.data;

    }

    public async GetByUniversidadIdValidaVigencia(universidadId: string): Promise<IResponseRecords<IRecordPromoPrecio>> {

        const response = await axios.get<IResponseRecords<IRecordPromoPrecio>>(`${this.BaseUrl}/universidad/${universidadId}/true`);
        console.info(response.data);
        response.data.registros = response.data.registros.map(this.FormatRecordFromSQL);
        return response.data;

    }


    public async GetByChainId(chainId: string): Promise<IResponseRecord<IRecordPromoPrecio>> {

        const response = await axios.get<IResponseRecord<IRecordPromoPrecio>>(`${this.BaseUrl}/${chainId}`);
        console.info(response.data);
        response.data.registro = this.FormatRecordFromSQL(response.data.registro);
        return response.data;

    }


    public async Add(record: IRecordPromoPrecio): Promise<IResponseRecord<IRecordPromoPrecio>> {

        const response = await axios.post<IResponseRecord<IRecordPromoPrecio>>(this.BaseUrl, {
            nombre: record.nombre,
            vigenciaInicio: record.vigenciaInicio.format(FORMATS.DATE_TO_SQL),
            vigenciaFin: record.vigenciaFin.format(FORMATS.DATE_TO_SQL),
            descripcion: record.descripcion,
            porcentajeDescuento: record.porcentajeDescuento,
            universidadId: record.universidadId,
            tipoPrecioId: record.tipoPrecioId,
            recintoId: record.recintoId,
            lineaNegocioId: record.lineaNegocioId,
        });
        return response.data;

    }


    public async Update(record: IRecordPromoPrecio): Promise<IResponseRecord<IRecordPromoPrecio>> {

        const response = await axios.put<IResponseRecord<IRecordPromoPrecio>>(`${this.BaseUrl}`, {
            nombre: record.nombre,
            chainId: record.chainId,
            vigenciaInicio: record.vigenciaInicio.format(FORMATS.DATE_TO_SQL),
            vigenciaFin: record.vigenciaFin.format(FORMATS.DATE_TO_SQL),
            descripcion: record.descripcion,
            porcentajeDescuento: record.porcentajeDescuento,
            universidadId: record.universidadId,
            tipoPrecioId: record.tipoPrecioId,
            recintoId: record.recintoId,
            lineaNegocioId: record.lineaNegocioId,
            active: record.active
        });
        return response.data;

    }


    public async GetAll(): Promise<IResponseRecords<IRecordPromoPrecio>> {

        const response = await axios.get<IResponseRecords<IRecordPromoPrecio>>(this.BaseUrl);
        console.info(response.data);
        response.data.registros = response.data.registros.map(this.FormatRecordFromSQL);
        return response.data;

    }


    public async GetActive(): Promise<IResponseRecords<IRecordPromoPrecio>> {

        const response = await axios.get<IResponseRecords<IRecordPromoPrecio>>(this.BaseUrl);
        console.info(response.data);
        response.data.registros = response.data.registros.map(this.FormatRecordFromSQL);
        return response.data;

    }

    public async GetById(id: number): Promise<IResponseRecords<IRecordPromoPrecio> | undefined> {

        const response = await axios.get<IResponseRecords<IRecordPromoPrecio>>(this.BaseUrl);
        console.info(response.data);
        response.data.registros = response.data.registros.map(this.FormatRecordFromSQL);
        return response.data;

    }


    private FormatRecordFromSQL(record: IRecordPromoPrecio): IRecordPromoPrecio {

        return {
            ...record,
            vigenciaInicio: moment(record.vigenciaInicio),
            vigenciaFin: moment(record.vigenciaFin),
            created: moment(record.created),
            updated: moment(record.updated)
        };

    }

}