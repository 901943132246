import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";
import moment from "moment";
import { FORMATS } from "../../../config/FORMATS";
import { IRecordUniversidad } from "../../../interfaces/Records/IRecordUniversidad";
import { ITableRecordFormater } from "../../Shared/_TableCore/ITableRecordFormater";
import { TableDataCell } from "../../Shared/_TableCore/TableDataCell";
import { TableRowCell } from "../../Shared/_TableCore/TableRowCell";
import { TableDataTrueFalse } from "../TableDataTrueFalse/TableDataTrueFalse";


export const TableFormatAdminUniversidad = ({

    record, OnEditRecord

}: ITableRecordFormater<IRecordUniversidad>) => {

    return (
        <TableRowCell hover>
            <TableDataCell>
                <Button variant="outlined" color="secondary" onClick={() => { OnEditRecord(record); }}>
                    <FontAwesomeIcon icon={faPencil} />
                </Button>
            </TableDataCell>
            <TableDataCell align="left">
                <Typography variant="body1">
                    {record.nombre}
                </Typography>
            </TableDataCell>
            <TableDataCell align="left">
                <Typography variant="body1">
                    {record.whatsapp}
                </Typography>
            </TableDataCell>
            <TableDataTrueFalse value={record.showPrimerPago} trueLabel="Mostrar" falseLabel="Ocultar" />
            <TableDataTrueFalse value={record.showColegiatura} trueLabel="Mostrar" falseLabel="Ocultar" />
            <TableDataTrueFalse value={record.showPagoTotal} trueLabel="Mostrar" falseLabel="Ocultar" />
            <TableDataTrueFalse value={record.active} trueLabel="Activo" falseLabel="Inactivo" />
            <TableDataCell align="center">
                <Typography variant="body1">
                    {moment(record.created).format(FORMATS.DATE_FORMAT_TO_TXT)}
                </Typography>
            </TableDataCell>
            <TableDataCell align="center">
                <Typography variant="body1">
                    {moment(record.updated).format(FORMATS.DATE_FORMAT_TO_TXT)}
                </Typography>
            </TableDataCell>
        </TableRowCell>
    );

};
