import axios from "axios";
import moment from "moment";
import { FORMATS } from "../../../config/FORMATS";
import { IRecordPromoBeca } from "../../../interfaces/Records/IRecordPromoBeca";
import { IResponseRecord } from "../../../interfaces/Responses/IResponseRecord";
import { IResponseRecords } from "../../../interfaces/Responses/IResponseRecords";
import { ServiceConnectorAPIBase } from "../../Core/ServiceConnectorAPIBase/ServiceConnectorAPIBase";
import { IServiceConnectorPromosBeca } from "./IServiceConnectorPromosBeca";

export class ServiceConnectorPromoBecas extends ServiceConnectorAPIBase implements IServiceConnectorPromosBeca {

    constructor() {
        super("v1/promoBeca");

    }

    public async GetByUniversidadId(universidadId: string): Promise<IResponseRecords<IRecordPromoBeca>> {

        const response = await axios.get<IResponseRecords<IRecordPromoBeca>>(`${this.BaseUrl}/universidad/${universidadId}`);
        console.info(response.data);
        response.data.registros = response.data.registros.map(this.FormatRecordFromSQL);
        return response.data;

    }

    public async GetByUniversidadIdValidaVigencia(universidadId: string): Promise<IResponseRecords<IRecordPromoBeca>> {

        const response = await axios.get<IResponseRecords<IRecordPromoBeca>>(`${this.BaseUrl}/universidad/${universidadId}/true`);
        console.info(response.data);
        response.data.registros = response.data.registros.map(this.FormatRecordFromSQL);
        return response.data;

    }


    public async GetByChainId(chainId: string): Promise<IResponseRecord<IRecordPromoBeca>> {

        const response = await axios.get<IResponseRecord<IRecordPromoBeca>>(`${this.BaseUrl}/${chainId}`);
        console.info(response.data);
        response.data.registro = this.FormatRecordFromSQL(response.data.registro);
        return response.data;

    }


    public async Add(record: IRecordPromoBeca): Promise<IResponseRecord<IRecordPromoBeca>> {

        const response = await axios.post<IResponseRecord<IRecordPromoBeca>>(this.BaseUrl, {
            nombre: record.nombre,
            vigenciaInicio: record.vigenciaInicio.format(FORMATS.DATE_TO_SQL),
            vigenciaFin: record.vigenciaFin.format(FORMATS.DATE_TO_SQL),
            promedioMinimo: record.promedioMinimo,
            promedioMaximo: record.promedioMaximo,
            porcentajeDescuento: record.porcentajeDescuento,
            descripcion: record.descripcion,
            universidadId: record.universidadId,
            recintoId: record.recintoId,
            lineaNegocioId: record.lineaNegocioId,
        });
        return response.data;

    }


    public async Update(record: IRecordPromoBeca): Promise<IResponseRecord<IRecordPromoBeca>> {

        const response = await axios.put<IResponseRecord<IRecordPromoBeca>>(`${this.BaseUrl}`, {
            nombre: record.nombre,
            chainId: record.chainId,
            vigenciaInicio: record.vigenciaInicio.format(FORMATS.DATE_TO_SQL),
            vigenciaFin: record.vigenciaFin.format(FORMATS.DATE_TO_SQL),
            promedioMinimo: record.promedioMinimo,
            promedioMaximo: record.promedioMaximo,
            porcentajeDescuento: record.porcentajeDescuento,
            descripcion: record.descripcion,
            universidadId: record.universidadId,
            recintoId: record.recintoId,
            lineaNegocioId: record.lineaNegocioId,
            active: record.active
        });
        return response.data;

    }


    public async GetAll(): Promise<IResponseRecords<IRecordPromoBeca>> {

        const response = await axios.get<IResponseRecords<IRecordPromoBeca>>(this.BaseUrl);
        console.info(response.data);
        response.data.registros = response.data.registros.map(this.FormatRecordFromSQL);
        return response.data;

    }


    public async GetActive(): Promise<IResponseRecords<IRecordPromoBeca>> {

        const response = await axios.get<IResponseRecords<IRecordPromoBeca>>(this.BaseUrl);
        console.info(response.data);
        response.data.registros = response.data.registros.map(this.FormatRecordFromSQL);
        return response.data;

    }

    public async GetById(id: number): Promise<IResponseRecords<IRecordPromoBeca> | undefined> {

        const response = await axios.get<IResponseRecords<IRecordPromoBeca>>(this.BaseUrl);
        console.info(response.data);
        response.data.registros = response.data.registros.map(this.FormatRecordFromSQL);
        return response.data;

    }


    private FormatRecordFromSQL(record: IRecordPromoBeca): IRecordPromoBeca {

        return {
            ...record,
            vigenciaInicio: moment(record.vigenciaInicio),
            vigenciaFin: moment(record.vigenciaFin),
            created: moment(record.created),
            updated: moment(record.updated)
        };

    }

}