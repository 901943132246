import { faChevronRight, faPhoneCircle, faUser } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Grid, InputAdornment, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useContextApiService } from "../../../../contexts/ContextApiService/ContextApiService"
import { defaultRecordDatosContacto } from "../../../../data/defaults/records/defaulRecordDatosContacto"
import { usePropChange } from "../../../../hooks/Shared/usePropChange/usePropChange"
import { useValidateDatosContacto } from "../../../../hooks/validators/useValidateDatosContacto/useValidateDatosContacto"
import { IRecordDatosContacto } from "../../../../interfaces/Records/IRecordDatosContacto"
import { IFormInteractionEditRecord } from "../../../../interfaces/Shared/Forms/IFormInteractionEditRecord"
import { TextFieldWhite } from "../../../Styled/TextFieldWhite"



export const FormUserInitialForm = ({

    onRecordChanged,
    defaultRecord

}: IFormInteractionEditRecord<IRecordDatosContacto>) => {


    const { Record, OnTextInputChanged } = usePropChange<IRecordDatosContacto>(defaultRecordDatosContacto());
    const { ApiService } = useContextApiService();
    const { validate } = useValidateDatosContacto();
    const theme = useTheme();
    const isXsOrSm = useMediaQuery(theme.breakpoints.down('sm'));


    const onNextClic = async () => {

        const response = await ApiService.Admin.Contacto.Add(Record);
        onRecordChanged(response.registro);

    }

    const inputStyleResponsive = () => {

        return {
            width: isXsOrSm ? "80%" : "60%",
        }

    }


    return (
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Typography variant={isXsOrSm ? "h6" : "h5"} textAlign="center" color="white">
                    Calcula tu colegiatura
                </Typography>
                <Typography variant={isXsOrSm ? "h6" : "h5"} textAlign="center" color="white">
                    y empieza a alcanzar tus metas.
                </Typography>
                <Typography variant={isXsOrSm ? "h6" : "h5"} textAlign="center" color="white">
                    Compartenos los siguientes datos:
                </Typography>
            </Grid>
            <Grid container spacing={3} item xs={12}>
                <Grid item xs={12} textAlign="center">
                    <TextFieldWhite
                        sx={inputStyleResponsive()}
                        value={Record.nombre}
                        label="Nombre y Apellidos"
                        variant="filled"
                        fullWidth
                        name="nombre"
                        type="text"
                        onChange={OnTextInputChanged("nombre")}
                        error={Record.nombre.length <= 0}
                        required
                        inputProps={{
                            maxLength: 50
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faUser} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} textAlign="center">
                    <TextFieldWhite
                        sx={inputStyleResponsive()}
                        value={Record.telefono}
                        label="Whatsapp (10 digitos)"
                        variant="filled"
                        fullWidth
                        name="nombre"
                        type="text"
                        onChange={OnTextInputChanged("telefono")}
                        error={Record.telefono.length <= 0}
                        required
                        inputProps={{
                            maxLength: 10
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faPhoneCircle} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} sx={{
                paddingTop: "2rem"
            }}>
                <Grid item xs={12} textAlign="center">
                    <Button fullWidth sx={inputStyleResponsive()} variant="contained" color="success" disabled={!validate(Record)} onClick={onNextClic}>
                        <Typography variant="button">
                            <FontAwesomeIcon icon={faChevronRight} /> Siguiente
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>

    )

}