import axios from "axios";
import { IRecordPromoBanner } from "../../../interfaces/Records/IRecordPromoBanner";
import { IResponseRecord } from "../../../interfaces/Responses/IResponseRecord";
import { IResponseRecords } from "../../../interfaces/Responses/IResponseRecords";
import { IServiceGetterFilter } from "../../../interfaces/Services/IServiceGetterFilter";
import { ServiceConnectorAPIBase } from "../../Core/ServiceConnectorAPIBase/ServiceConnectorAPIBase";
import { FORMATS } from "../../../config/FORMATS";
import { IServiceConnectorPromosBanners } from "./IServiceConnectorPromosBanners";
import moment from "moment";


export class ServiceConnectorPromoBanners extends ServiceConnectorAPIBase implements IServiceConnectorPromosBanners {

    constructor() {
        super("v1/promoBanners");

    }

    public async GetByUniversidadId(universidadId: string): Promise<IResponseRecords<IRecordPromoBanner>> {

        const response = await axios.get<IResponseRecords<IRecordPromoBanner>>(`${this.BaseUrl}/universidad/${universidadId}`);
        console.info(response.data);
        response.data.registros = response.data.registros.map(this.FormatRecordFromSQL);
        return response.data;

    }

    public async GetByUniversidadIdValidaVigencia(universidadId: string): Promise<IResponseRecords<IRecordPromoBanner>> {

        const response = await axios.get<IResponseRecords<IRecordPromoBanner>>(`${this.BaseUrl}/universidad/${universidadId}/true`);
        console.info(response.data);
        response.data.registros = response.data.registros.map(this.FormatRecordFromSQL);
        return response.data;

    }


    public async GetByChainId(chainId: string): Promise<IResponseRecord<IRecordPromoBanner>> {

        const response = await axios.get<IResponseRecord<IRecordPromoBanner>>(`${this.BaseUrl}/${chainId}`);
        console.info(response.data);
        response.data.registro = this.FormatRecordFromSQL(response.data.registro);
        return response.data;

    }


    public async Add(record: IRecordPromoBanner): Promise<IResponseRecord<IRecordPromoBanner>> {

        const response = await axios.post<IResponseRecord<IRecordPromoBanner>>(this.BaseUrl, {
            nombre: record.nombre,
            vigenciaInicio: record.vigenciaInicio.format(FORMATS.DATE_TO_SQL),
            vigenciaFin: record.vigenciaFin.format(FORMATS.DATE_TO_SQL),
            urlImagen: record.urlImagen,
            mensaje: record.mensaje,
            universidadId: record.universidadId
        });
        return response.data;

    }


    public async Update(record: IRecordPromoBanner): Promise<IResponseRecord<IRecordPromoBanner>> {

        const response = await axios.put<IResponseRecord<IRecordPromoBanner>>(`${this.BaseUrl}`, {
            nombre: record.nombre,
            chainId: record.chainId,
            vigenciaInicio: record.vigenciaInicio.format(FORMATS.DATE_TO_SQL),
            vigenciaFin: record.vigenciaFin.format(FORMATS.DATE_TO_SQL),
            urlImagen: record.urlImagen,
            mensaje: record.mensaje,
            universidadId: record.universidadId,
            active: record.active
        });
        return response.data;

    }


    public async GetAll(): Promise<IResponseRecords<IRecordPromoBanner>> {

        const response = await axios.get<IResponseRecords<IRecordPromoBanner>>(this.BaseUrl);
        console.info(response.data);
        response.data.registros = response.data.registros.map(this.FormatRecordFromSQL);
        return response.data;

    }


    public async GetActive(): Promise<IResponseRecords<IRecordPromoBanner>> {

        const response = await axios.get<IResponseRecords<IRecordPromoBanner>>(this.BaseUrl);
        console.info(response.data);
        response.data.registros = response.data.registros.map(this.FormatRecordFromSQL);
        return response.data;

    }

    public async GetById(id: number): Promise<IResponseRecords<IRecordPromoBanner> | undefined> {

        const response = await axios.get<IResponseRecords<IRecordPromoBanner>>(this.BaseUrl);
        console.info(response.data);
        response.data.registros = response.data.registros.map(this.FormatRecordFromSQL);
        return response.data;

    }


    private FormatRecordFromSQL(record: IRecordPromoBanner): IRecordPromoBanner {

        return {
            ...record,
            vigenciaInicio: moment(record.vigenciaInicio),
            vigenciaFin: moment(record.vigenciaFin),
            created: moment(record.created),
            updated: moment(record.updated)
        };

    }

}