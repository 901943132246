import { IRecordAppUser } from "../../../interfaces/Records/IRecordAppUser"
import { ITableCoreEvents } from "../../Shared/_TableCore/ITableCoreEvents"
import { TableBaseContainer } from "../../Shared/_TableCore/TableBaseContainer"
import { TableFormatAppUsers } from "./TableFormatAppUsers"



export const TableAppUsers = ({

    records,
    OnEditRecord

}: ITableCoreEvents<IRecordAppUser>) => {


    return (
        <TableBaseContainer<IRecordAppUser>
            headers={["Acciones", "Email/User", "Nombre", "Roles", "Activo", "Creado", "Modificado"]}
            records={records}
            OnEditRecord={OnEditRecord}
            TableRecordFormat={TableFormatAppUsers}
            id="table-app-users"
        />
    )


}

