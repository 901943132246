import { faPlusCircle, faUsers } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, LinearProgress, Typography } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useContextApiService } from "../../../../../contexts/ContextApiService/ContextApiService";
import { useIsLoading } from "../../../../../hooks/Shared/useIsLoading/useIsLoading";
import { IRecordCarreraConfig } from "../../../../../interfaces/Records/IRecordCarreraConfig";
import { IRecordRecinto } from "../../../../../interfaces/Records/IRecordRecinto";
import { IRecordUniversidad } from "../../../../../interfaces/Records/IRecordUniversidad";
import { ROUTES_KEYS } from "../../../../../routes/ROUTES_KEYS";
import { ComboServiceSelector } from "../../../../Shared/ComboServiceSelector/ComboServiceSelector";
import { IndicatorHeaderSection } from "../../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";
import { TableAdminCarrerasConfig } from "../../../../Tables/TableAdminCarrerasConfig/TableAdminCarrerasConfig";
import { useContextAuthStateProvider } from "../../../../../contexts/ContextAuthState/ContextAuthState";



export const PageAdminCarreraConfigHome = () => {

    const { ApiService } = useContextApiService();
    const { isLoading, setIsLoading } = useIsLoading(false);
    const [records, setRecords] = useState<IRecordCarreraConfig[]>([]);
    const [selectedUniversidad, setSelectedUniversidad] = useState<IRecordUniversidad | undefined>(undefined);
    const [realoadCampus, setReloadCampus] = useState<boolean>(false);
    const { CurrentUser } = useContextAuthStateProvider();
    const navigate = useNavigate();


    const onSelectionUniversidad = async (record: IRecordUniversidad | undefined) => {


        if (record === undefined) {
            return;
        }

        console.log("Universidad", record);
        setSelectedUniversidad(undefined);
        setIsLoading(true);
        setReloadCampus(true);
        const response = await ApiService.Admin.CarreraConfiguracion.GetByUniversidadId(record.chainId);
        setRecords(response.registros);

        setTimeout(() => {

            setSelectedUniversidad(record);
            setIsLoading(false);
            setReloadCampus(false);

        }, 500);


    }

    const onRecintoChange = async (record: IRecordRecinto | undefined) => {

        if (record === undefined) {
            return;
        }

        console.log("Recinto", record);
        setIsLoading(true);
        const records = await ApiService.Admin.CarreraConfiguracion.GetByCampusId(record.chainId);
        setRecords(records.registros);

        setTimeout(() => {

            setIsLoading(false);

        }, 500);
    }


    const checkIfDisabled = () => {

        if (selectedUniversidad === undefined) {
            return true;
        }

        if (selectedUniversidad.chainId.length <= 0) {
            return true;
        }

        return false;

    }



    return (

        <Grid container spacing={3}>
            <IndicatorHeaderSection
                title="Configuración Oferta Educativa"
                icon={faUsers}
                color="secondary"
                subtitle="Configurar Oferta Educativa"
            />
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} md={6}>
                    <ComboServiceSelector<IRecordUniversidad>
                        id="combo-universidades"
                        label="Universidades"
                        optionIdsToShow={CurrentUser.universidades}
                        queryService={() => {
                            return ApiService.Admin.Universidades.GetAll();
                        }}
                        onSelectionChange={onSelectionUniversidad}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} md={4}>
                    <Button
                        component={Link}
                        to={`${ROUTES_KEYS.ROOT_ADM_NEW}/${selectedUniversidad?.chainId}`}
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={checkIfDisabled()}>
                        <Typography variant="button">
                            <FontAwesomeIcon icon={faPlusCircle} /> Crear Registro
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <IndicatorHeaderSection
                    title="Oferta Registrada"
                    icon={faUsers}
                    color="warning"
                />
                <Grid container item xs={12}>
                    <Grid item xs={12} md={6}>
                        {
                            !realoadCampus ? (
                                <ComboServiceSelector<IRecordRecinto>
                                    id="combo-recintos"
                                    label="Campus"
                                    queryService={() => {
                                        return ApiService.Admin.Recintos.GetByUniversidadId(
                                            selectedUniversidad === undefined ? "" :
                                                selectedUniversidad.chainId
                                        );
                                    }}
                                    onSelectionChange={onRecintoChange}
                                />) : (
                                <LinearProgress />
                            )
                        }
                    </Grid>
                </Grid>
                {
                    isLoading ? (
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    ) : (
                        <TableAdminCarrerasConfig
                            records={records}
                            OnEditRecord={(record) => {
                                console.log("Edit", record);
                                navigate(`${ROUTES_KEYS.ROOT_ADM_EDIT}/${record.chainId}`)
                            }}
                        />
                    )
                }
            </Grid>
        </Grid>

    );

}