import axios from "axios";
import { IRecordUniversidad } from "../../../interfaces/Records/IRecordUniversidad";
import { IRecordUniversidadConfig } from "../../../interfaces/Records/IRecordUniversidadConfig";
import { IResponseRecord } from "../../../interfaces/Responses/IResponseRecord";
import { ServiceConnectorAPIBase } from "../../Core/ServiceConnectorAPIBase/ServiceConnectorAPIBase";
import { IServiceConnectorUniversidadesConfiguracion } from "./IServiceConnectorUniversidadesConfiguracion";

export class ServiceConnectorUniversidadConfig extends ServiceConnectorAPIBase implements IServiceConnectorUniversidadesConfiguracion {

    constructor() {
        super("v1/universidadConfig");
    }

    public async GetByUniversidadId(id: string): Promise<IResponseRecord<IRecordUniversidadConfig>> {

        const response = await axios.get<IResponseRecord<IRecordUniversidadConfig>>(`${this.BaseUrl}/${id}`);
        return response.data;

    }


    public async Add(record: IRecordUniversidadConfig): Promise<IResponseRecord<IRecordUniversidadConfig>> {

        const response = await axios.post<IResponseRecord<IRecordUniversidadConfig>>(this.BaseUrl, {
            "chainId": record.chainId,
            "styleProps": record.styleProps,
            "active": true
        });
        return response.data;

    }

    public async Update(record: IRecordUniversidadConfig): Promise<IResponseRecord<IRecordUniversidadConfig>> {

        const response = await axios.put<IResponseRecord<IRecordUniversidadConfig>>(`${this.BaseUrl}`, record);
        return response.data;

    }

}