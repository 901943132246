import { useEffect, useState } from "react";
import { IRecordAppChainId } from "../../../interfaces/Records/IRecordAppChainId";
import { useDidMount } from "../../../hooks/Shared/useDidMount/useDidMount";
import { useIsLoading } from "../../../hooks/Shared/useIsLoading/useIsLoading";
import { LinearProgress, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { IFormCheckBoxesProps } from "./IFormCheckBoxesProps";

export const FormCheckBoxes = <TRecord extends IRecordAppChainId>({

    formId,
    defaultSelections,
    OnSelectionChange,
    apiService

}: IFormCheckBoxesProps<TRecord>) => {

    const { didMount } = useDidMount();
    const [options, setOptions] = useState<TRecord[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<string[]>(defaultSelections);
    const { isLoading, setIsLoading } = useIsLoading(true);

    const OnCheckBoxChange = (item: TRecord) => (event: React.ChangeEvent<HTMLInputElement>): void => {

        if (event.target.checked) {

            setSelectedOptions([...selectedOptions, item.chainId]);

        } else {

            setSelectedOptions(selectedOptions.filter((option) => option !== item.chainId));

        }

    }

    const SetPreviosChecked = (record: TRecord): boolean => {

        const index = selectedOptions.findIndex((modulo) => modulo === record.chainId);
        return index > -1;

    }

    useEffect(() => {

        if (didMount.current) {
            OnSelectionChange(selectedOptions);
        }

    }, [selectedOptions]);


    useEffect(() => {

        const getOptions = async (): Promise<void> => {

            const response = await apiService();
            if (response === undefined) {

                setOptions([]);

            } else {

                setOptions(response.registros);

            }

            setIsLoading(false);

        };

        getOptions().catch((error) => {
            console.error(`Error al obtener las opciones en: ${formId}`, error);
            setIsLoading(false);
        });

    }, []);

    return (
        <>
            {
                isLoading ? (
                    <LinearProgress />
                ) : (
                    <>
                        {
                            options.length === 0 ? (
                                <>
                                </>
                            ) : (
                                <FormGroup>
                                    {
                                        options.map((option, index) => (
                                            <FormControlLabel
                                                key={`frm-${formId}-control-${option.chainId}-${index}`}
                                                label={option.nombre}
                                                control={
                                                    <Checkbox
                                                        checked={SetPreviosChecked(option)}
                                                        onChange={OnCheckBoxChange(option)}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        ))
                                    }
                                </FormGroup>
                            )
                        }
                    </>
                )
            }
        </>
    );

}