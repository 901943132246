import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Slide } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { STYLES_APP } from "../../../theme/STYLES_APP";
import { ComponentAllower } from "../../Shared/ComponentAllower/ComponentAllower";
import { ROUTES_KEYS } from "../../../routes/ROUTES_KEYS";
import { INavDrawerAdminMenuProps } from "./INavDrawerAdminMenuProps";


export const NavDrawerLinkSection = ({

    link,
    index,
    userModulesToCheck

}: INavDrawerAdminMenuProps) => {

    const location = useLocation();
    const navigate = useNavigate();

    const navigateTo = (path: string): void => {

        if (path === ROUTES_KEYS.ROOT_ADM_HOME) {
            navigate("./");
            return;
        }

        navigate(`./${path}`);

    };

    const checkSelection = (path: string): boolean => {

        if (path === ROUTES_KEYS.ROOT_ADM_HOME) {
            console.info("path", path, "location.pathname", location.pathname, "location.pathname === ''");
            return location.pathname === "";
        }

        return location.pathname.includes(path);

    };

    return (
        <ComponentAllower key={`component-allower-${index}`} componentId={link.moduleId} userComponentsList={userModulesToCheck.map(record => record.chainId)}>
            <Slide key={`link-nav-${index}`} direction="right" in={true} mountOnEnter unmountOnExit>
                <ListItem
                    sx={{ paddingTop: ".2rem", paddingLeft: "1rem", paddingBottom: ".1rem" }}
                    onClick={() => navigateTo(link.path)}
                    color="white"
                >
                    <ListItemButton selected={checkSelection(link.path)} sx={{
                        // borderRadius: STYLES_APP.BORDER_RADIUS,                                                        
                        textAlign: "left",
                        color: "white",
                        '&, & .MuiListItemIcon-root': {
                            color: "white"
                        },
                        "&:hover": {
                            backgroundColor: STYLES_APP.NAV_DRAWER_BUTTON_HOVER,
                            '&, & .MuiListItemIcon-root': {},
                        },
                        "&.Mui-selected": {
                            backgroundColor: STYLES_APP.NAV_DRAWER_BUTTON_SELECTED,
                            '&, & .MuiListItemIcon-root': {},
                        },
                        "&.Mui-selected:hover": {
                            backgroundColor: STYLES_APP.NAV_DRAWER_BUTTON_HOVER,
                            '&, & .MuiListItemIcon-root': {},
                        },
                    }}>
                        <ListItemIcon sx={{ minWidth: "30px" }}>
                            <FontAwesomeIcon icon={link.icon} size="1x" style={{ height: "1rem" }} />
                        </ListItemIcon>
                        <ListItemText primary={link.title} />
                    </ListItemButton>
                </ListItem>
            </Slide>
        </ComponentAllower>
    );

};
