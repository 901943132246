import { ReactNode } from "react";
import { IModalBrandingProps } from "../ContextModalWindow/IModalBrandingProps";

export abstract class BaseModalDialogBranding implements IModalBrandingProps {


    private _icon: any;

    constructor(icon: any) {

        this._icon = icon;
    }

    public get icon(): any {
        return this._icon;
    }
    public set icon(v: any) {
        this._icon = v;
    }


    public abstract GetContent(messages: string[]): ReactNode;
    public abstract GetDialogTitle(title: string): ReactNode;

}



