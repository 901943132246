import { IRecordCarrera } from "../../../interfaces/Records/IRecordCarrera"
import { ITableCoreEvents } from "../../Shared/_TableCore/ITableCoreEvents"
import { TableBaseContainer } from "../../Shared/_TableCore/TableBaseContainer"
import { TableFormatAdminCarreras } from "./TableFormatAdminCarreras"



export const TableAdminCarreras = ({

    records,
    OnEditRecord

}: ITableCoreEvents<IRecordCarrera>) => {


    return (
        <TableBaseContainer<IRecordCarrera>
            headers={["Acciones", "Nombre", "Clave", "Linea Negocio", "Activo", "Creado", "Modificado"]}
            records={records}
            OnEditRecord={OnEditRecord}
            TableRecordFormat={TableFormatAdminCarreras}
            id="table-admin-carrera"
        />
    )


}


