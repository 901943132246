import { createContext, PropsWithChildren, useContext } from "react";
import { ServiceConnectorAPI } from "../../services/Core/ServiceConnectorAPI/ServiceConnectorAPI";
import { IContextApiServiceHandlers } from "./IContextApiServiceHandlers";

export const ContextApiService = createContext<IContextApiServiceHandlers>({} as IContextApiServiceHandlers);

export const ContextApiServiceProvider = ({ children }: PropsWithChildren<{}>) => {

    console.log("ContextApiServiceProvider creation");

    const service = new ServiceConnectorAPI();

    return (
        <ContextApiService.Provider value={{
            ApiService: service
        }}>
            {children}
        </ContextApiService.Provider>
    )

}

export const useContextApiService = (): IContextApiServiceHandlers => {

    const context = useContext(ContextApiService);

    if (context === undefined) {
        throw new Error('useContextContextApiService debe usarse dentro de ContextApiServiceProvider');
    }

    return context;

}