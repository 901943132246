import { faSave, faUserPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useContextApiService } from "../../../../../contexts/ContextApiService/ContextApiService";
import { defaultRecordCarreraConfig } from "../../../../../data/defaults/records/defaultRecordCarreraConfig";
import { usePropChange } from "../../../../../hooks/Shared/usePropChange/usePropChange";
import { useValidateCarreraConfig } from "../../../../../hooks/validators/useValidateCarreraConfig/useValidateCarreraConfig";
import { IRecordCarreraConfig } from "../../../../../interfaces/Records/IRecordCarreraConfig";
import { FormAdminCarreraConfig } from "../../../../Forms/Admin/FormAdminCarreraConfig/FormAdminCarreraConfig";
import { IndicatorHeaderSection } from "../../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";
import { useModalDialogWindow } from "../../../../Shared/ModalDialog/hook/useModalDialogWindow";



export const PageAdminCarreraConfigNew = () => {

    const { id } = useParams();
    const { Record, SetRecord } = usePropChange<IRecordCarreraConfig>(defaultRecordCarreraConfig(id));
    const { ApiService } = useContextApiService();
    const { ShowModalEnviando, ShowModalError, ShowModalSuccessNew } = useModalDialogWindow();
    const { validate: validateCarrera } = useValidateCarreraConfig();
    const navigate = useNavigate();

    const onRecordChange = (record: IRecordCarreraConfig) => {

        if (id !== undefined) {

            SetRecord(record);

        }

    }

    const onSave = async () => {

        ShowModalEnviando();
        const response = await ApiService.Admin.CarreraConfiguracion.Add(Record);

        if (!response.success) {

            const errorMesage = response.error ?? "Error al guardar el registro";
            ShowModalError(errorMesage);
            return;

        }

        ShowModalSuccessNew();
        navigate("../");

    }

    return (
        <Grid container  >
            <IndicatorHeaderSection
                title="Nuevo Registro"
                subtitle="Complete los campos para agregar un registro"
                icon={faUserPlus}
            />
            <Grid item xs={12}>
                <FormAdminCarreraConfig
                    defaultRecord={Record}
                    onRecordChanged={onRecordChange}
                />
            </Grid>
            <Grid item xs={12} container direction="row-reverse" sx={{ mt: "2rem" }}>
                <Grid item xs={12} md={4}>
                    <Button variant="contained" fullWidth disabled={!validateCarrera(Record)} onClick={onSave}>
                        <Typography variant="button">
                            <FontAwesomeIcon icon={faSave} /> Guardar
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )

}