import { faUniversity } from "@fortawesome/pro-light-svg-icons";
import { Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import { defaultRecordRecinto } from "../../../../data/defaults/records/defaultRecordRecinto";
import { usePropChange } from "../../../../hooks/Shared/usePropChange/usePropChange";
import { useValidateRecinto } from "../../../../hooks/validators/useValidateRecinto/useValidateRencinto";
import { IRecordRecinto } from "../../../../interfaces/Records/IRecordRecinto";
import { IFormInteractionEditRecord } from "../../../../interfaces/Shared/Forms/IFormInteractionEditRecord";
import { IndicatorHeaderSection } from "../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";

export const FormAdminRecinto = ({

    defaultRecord,
    onRecordChanged

}: IFormInteractionEditRecord<IRecordRecinto>) => {

    const { Record, OnTextInputChanged } = usePropChange<IRecordRecinto>(
        defaultRecord ?? defaultRecordRecinto()
    );
    const { validate: validateRecinto } = useValidateRecinto();


    useEffect(() => {

        if (validateRecinto(Record)) {

            console.log("Record is valid", Record);
            onRecordChanged(Record)

        }

    }, [Record]);

    return (
        <Grid container>
            <Grid item xs={12} container spacing={3}>
                <IndicatorHeaderSection
                    title="Datos Generales"
                    icon={faUniversity}
                />
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.nombre}
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                        name="nombre"
                        type="text"
                        onChange={OnTextInputChanged("nombre")}
                        error={Record.nombre.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.calle}
                        label="Calle"
                        variant="outlined"
                        fullWidth
                        name="calle"
                        type="text"
                        onChange={OnTextInputChanged("calle")}
                        error={Record.calle.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.numero}
                        label="Numero"
                        variant="outlined"
                        fullWidth
                        name="numero"
                        type="text"
                        onChange={OnTextInputChanged("numero")}
                        error={Record.numero.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.delegacion}
                        label="Delegación"
                        variant="outlined"
                        fullWidth
                        name="delegacion"
                        type="text"
                        onChange={OnTextInputChanged("delegacion")}
                        error={Record.delegacion.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.colonia}
                        label="Colonia"
                        variant="outlined"
                        fullWidth
                        name="colonia"
                        type="text"
                        onChange={OnTextInputChanged("colonia")}
                        error={Record.colonia.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.codigoPostal}
                        label="Codigo Postal"
                        variant="outlined"
                        fullWidth
                        name="codigoPostal"
                        type="text"
                        onChange={OnTextInputChanged("codigoPostal")}
                        error={Record.codigoPostal.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.referencia}
                        label="Referencia"
                        variant="outlined"
                        fullWidth
                        name="referencia"
                        type="text"
                        onChange={OnTextInputChanged("referencia")}
                        error={Record.referencia.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.whatsApp}
                        label="Whatsapp"
                        variant="outlined"
                        fullWidth
                        name="whatsApp"
                        type="text"
                        onChange={OnTextInputChanged("whatsApp")}
                        error={Record.whatsApp.length <= 0}
                        required
                    />
                </Grid>
            </Grid>

        </Grid>
    );

}