import { IRecordLineaNegocio } from "../../../interfaces/Records/IRecordLineaNegocio";
import { IUseValidate } from "../IUseValidate";


export const useValidateLineaNegocio = (): IUseValidate<IRecordLineaNegocio> => {

    const validate = (record: IRecordLineaNegocio): boolean => {

        if (record.nombre.length <= 0) return false;
        if (record.clave.length <= 0) return false;
        if (record.equivalencia.length <= 0) return false;

        return true;

    }

    return {
        validate
    };

}