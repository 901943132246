import axios from "axios";
import { IRecordDatosContacto } from "../../../interfaces/Records/IRecordDatosContacto";
import { IResponseRecord } from "../../../interfaces/Responses/IResponseRecord";
import { IResponseRecords } from "../../../interfaces/Responses/IResponseRecords";
import { ServiceConnectorAPIBase } from "../../Core/ServiceConnectorAPIBase/ServiceConnectorAPIBase";
import { IServiceConnectorContacto } from "./IServiceConnectorContacto";



export class ServiceConnectorContacto extends ServiceConnectorAPIBase implements IServiceConnectorContacto {

    constructor() {

        super("v1/contacto");

    }

    public async GetAll(): Promise<IResponseRecords<IRecordDatosContacto>> {

        const response = await axios.get<IResponseRecords<IRecordDatosContacto>>(this.BaseUrl);
        console.info(response.data);
        return response.data;

    }

    public async GetById(id: string): Promise<IResponseRecord<IRecordDatosContacto>> {

        const response = await axios.get<IResponseRecord<IRecordDatosContacto>>(`${this.BaseUrl}/${id}`);
        return response.data;

    }

    public async Add(record: IRecordDatosContacto): Promise<IResponseRecord<IRecordDatosContacto>> {

        const response = await axios.post<IResponseRecord<IRecordDatosContacto>>(this.BaseUrl, {
            "telefono": record.telefono,
            "nombre": record.nombre,
        });
        return response.data;

    }

    public async Update(record: IRecordDatosContacto): Promise<IResponseRecord<IRecordDatosContacto>> {

        const response = await axios.put<IResponseRecord<IRecordDatosContacto>>(`${this.BaseUrl}`, record);
        return response.data;

    }

}
