import moment from "moment"
import { IRecordAppUser } from "../../../interfaces/Records/IRecordAppUser"
import { defaultRecordAppUserDetails } from "./defaultRecordAppUserDetails"


export const defaultRecordAppUser = (): IRecordAppUser => {

    return {
        clave: "",
        chainId: "",
        descripcion: "",
        id: "",
        nombre: "",
        appUserDetails: defaultRecordAppUserDetails(),
        email: "",
        password: "",
        passwordConfirm: "",
        roles: [],
        universidades: [],
        active: true,
        created: moment(),
        updated: moment(),
        rolesIds: [],
        moduleList: [],
    }

}



