import axios from "axios";
import { IRecordAppChainId } from "../../../interfaces/Records/IRecordAppChainId";
import { IRecordDatosCotizacion } from "../../../interfaces/Records/IRecordDatosCotizacion";
import { IResponseRecord } from "../../../interfaces/Responses/IResponseRecord";
import { ServiceConnectorAPIBase } from "../../Core/ServiceConnectorAPIBase/ServiceConnectorAPIBase";
import { IServiceConnectorCotizacion } from "./IServiceConnectorCotizacion";


export class ServiceConnectorCotizacion extends ServiceConnectorAPIBase implements IServiceConnectorCotizacion {

    constructor() {

        super("v1/cotizacion");

    }

    public async Add(record: IRecordDatosCotizacion): Promise<IResponseRecord<IRecordAppChainId>> {

        const response = await axios.post<IResponseRecord<IRecordAppChainId>>(this.BaseUrl, {
            "userId": record.UserId,
            "IdConfiguracionCarrera": record.IdConfiguracionCarrera,
        });
        return response.data;

    }


}
