import { Grid, Box, Typography, Divider, TextField } from "@mui/material";
import { useContextUniversidad } from "../../../contexts/ContextUniversidad/ContextUniversidad";
import { formatCurrency } from "../../../utils/MonetFormater/MoneyFormater";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophyStar } from "@fortawesome/pro-light-svg-icons";
import { relative } from "path";
import { useEffect, useState } from "react";
import { IRecordCarreraConfig } from "../../../interfaces/Records/IRecordCarreraConfig";
import { IRecordPromoBeca } from "../../../interfaces/Records/IRecordPromoBeca";


export interface IControlRenderResultPreguntaProps {

    record: IRecordCarreraConfig;

}


export const ControlRenderResultPromoBeca = ({

    record

}: IControlRenderResultPreguntaProps) => {

    const {
        LineaNegocio,
        Campus,
        Universidad,
        ConfigStyle,
        PromoPrecio,
        PromoBecas
    } = useContextUniversidad();

    const [promedio, setPromedio] = useState<number>(0);
    const [promosValidas, setPromosValidas] = useState<IRecordPromoBeca[]>([]);

    const getPromoPromedio = PromoBecas.filter((promo) => promo.recintoId === Campus.chainId && promo.lineaNegocioId === LineaNegocio.chainId);


    useEffect(() => {

        const promos = getPromoPromedio.filter((promo) => {

            return promedio >= promo.promedioMinimo && promedio <= promo.promedioMaximo;

        });

        setPromosValidas(promos);

    }, [promedio])


    return (
        <Grid container spacing={3} sx={{ marginTop: "2rem" }}>
            {

                getPromoPromedio.length > 0 ? (

                    <>
                        <Grid item xs={12} sx={{ position: "relative" }} textAlign="center">
                            <Divider />
                            <Typography variant="h4" textAlign="center" color="#538efa" sx={{
                                backgroundColor: "white",
                                margin: "-16px auto 0 auto",
                                width: {
                                    xs: "70%",
                                    md: "25%"
                                }
                            }}>
                                <FontAwesomeIcon icon={faTrophyStar} color="#ffcc00" /> !Premiamos tu esfuerzo!
                            </Typography>
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Typography variant="h6" textAlign="center">
                                Ingresa tu promedio para conocer tu colegiatura
                            </Typography>
                        </Grid>
                        <Grid item xs={12} textAlign="center" sx={{ marginBottom: "2rem" }}>
                            <TextField
                                value={promedio}
                                onChange={(e) => setPromedio(parseFloat(e.target.value))}
                                type="number"
                                label="Promedio"
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} container>
                            {
                                promosValidas.length > 0 ? (

                                    <>
                                        {
                                            promosValidas.map((promo) => {

                                                const montoDescuento = (promo.porcentajeDescuento / 100) * record.colegiatura;
                                                const nuevoPagoBeca = record.colegiatura - montoDescuento;

                                                return (
                                                    <>
                                                        <Grid item xs={12} md={4} />
                                                        <Grid item container spacing={1} xs={12} md={4} sx={{ background: "#fffaab", position: "relative", border: "solid 2.5px #e1e1e1", minHeight: "14rem" }}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h6" textAlign="center" fontWeight="bold" sx={{ paddingTop: "1rem" }}>
                                                                    Con tu Promedio tu colegiatura sería de:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h4" textAlign="center">
                                                                    {formatCurrency(nuevoPagoBeca)}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="body2" textAlign="center">
                                                                    Vigencia: {promo.vigenciaFin.format("DD/MM/YYYY")}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} md={4} />
                                                    </>
                                                )


                                            })
                                        }
                                    </>

                                ) : (
                                    <>
                                        <Grid item xs={12} md={4} />
                                        <Grid item container spacing={1} xs={12} md={4} sx={{ background: "#fffaab", position: "relative", border: "solid 2.5px #e1e1e1", minHeight: "14rem" }}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6" textAlign="center" fontWeight="bold" sx={{ paddingTop: "3rem" }}>
                                                    Contacta a un asesor para conocer promociones especiales para ti.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={4} />
                                    </>
                                )

                            }
                        </Grid>
                    </>

                ) : (
                    <>
                    </>
                )
            }
        </Grid>
    )

}