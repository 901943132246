import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";
import moment from "moment";
import { FORMATS } from "../../../config/FORMATS";
import { IRecordCarreraConfig } from "../../../interfaces/Records/IRecordCarreraConfig";
import { ITableRecordFormater } from "../../Shared/_TableCore/ITableRecordFormater";
import { TableDataCell } from "../../Shared/_TableCore/TableDataCell";
import { TableRowCell } from "../../Shared/_TableCore/TableRowCell";
import { TableDataTrueFalse } from "../TableDataTrueFalse/TableDataTrueFalse";
import { formatCurrency, formatDuracion } from "../../../utils/MonetFormater/MoneyFormater";


export const TableFormatAdminCarrerasConfig = ({

    record, OnEditRecord

}: ITableRecordFormater<IRecordCarreraConfig>) => {

    return (
        <TableRowCell hover>
            <TableDataCell>
                <Button variant="outlined" color="secondary" onClick={() => { OnEditRecord(record); }}>
                    <FontAwesomeIcon icon={faPencil} />
                </Button>
            </TableDataCell>
            <TableDataCell align="left">
                <Typography variant="body1">
                    {record.carreraNombre}
                </Typography>
            </TableDataCell>
            <TableDataCell align="left">
                <Typography variant="body1">
                    {record.modalidadNombre}
                </Typography>
            </TableDataCell>
            <TableDataCell align="left">
                <Typography variant="body1">
                    {record.carreraNombre}
                </Typography>
            </TableDataCell>
            <TableDataCell align="center">
                <Typography variant="body1">
                    {formatDuracion(record.duracion)}
                </Typography>
            </TableDataCell>
            <TableDataCell align="center">
                <Typography variant="body1">
                    {formatCurrency(record.primerPago)}
                </Typography>
            </TableDataCell>
            <TableDataCell align="center">
                <Typography variant="body1">
                    {formatCurrency(record.costoTotal)}
                </Typography>
            </TableDataCell>
            <TableDataCell align="center">
                <Typography variant="body1">
                    {formatCurrency(record.colegiatura)}
                </Typography>
            </TableDataCell>
            <TableDataCell align="center">
                <Typography variant="body1">
                    Inicio: {moment(record.vigenciaInicio).format(FORMATS.DATE_FORMAT_TO_TXT)}
                </Typography>
                <Typography variant="body1">
                    Fin: {moment(record.vigenciaFin).format(FORMATS.DATE_FORMAT_TO_TXT)}
                </Typography>
            </TableDataCell>
            <TableDataTrueFalse
                value={record.active}
                trueLabel="Activo"
                falseLabel="Inactivo" />
        </TableRowCell>
    );

};
