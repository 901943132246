import { IRecordDatosContacto } from "../../../interfaces/Records/IRecordDatosContacto";
import { IUseValidate } from "../IUseValidate";


export const useValidateDatosContacto = (): IUseValidate<IRecordDatosContacto> => {

    const validate = (record: IRecordDatosContacto): boolean => {

        if (record.nombre.length <= 5) return false;
        if (record.telefono.length < 10) return false;

        return true;

    }

    return {
        validate: validate
    };

}