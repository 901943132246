import { IRecordRecinto } from "../../../interfaces/Records/IRecordRecinto";
import { IUseValidate } from "../IUseValidate";


export const useValidateRecinto = (): IUseValidate<IRecordRecinto> => {

    const validateRecinto = (record: IRecordRecinto): boolean => {

        if (record.nombre === "") return false;
        if (record.calle === "") return false;
        if (record.numero === "") return false;
        if (record.delegacion === "") return false;
        if (record.colonia === "") return false;
        if (record.codigoPostal === "") return false;
        if (record.referencia === "") return false;
        if (record.whatsApp === "") return false;

        return true;

    }

    return {
        validate: validateRecinto
    };

}