import { IRecordUniversidadStyle } from "../../../interfaces/Records/IRecordUniversidadStyle";



export const defaultRecordUniversidadStyle = (): IRecordUniversidadStyle => {

    return {
        urlFondoBienvenida: "",
        urlLogo: "",
        fontColor: "",
        bgColor: "",
        lineaStyles: [],
        boxShadowColor: "",
        urlFondoCarreras: "",
        urlFondoResultado: ""
    };

};
