import { Grid, LinearProgress, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useContextApiService } from "../../../../contexts/ContextApiService/ContextApiService";
import { useIsLoading } from "../../../../hooks/Shared/useIsLoading/useIsLoading";
import { IRecordModalidad } from "../../../../interfaces/Records/IRecordModalidad";
import { ControlListCarreras } from "../../../Controls/ControlListCarreras/ControlListCarreras";
import { IFormModadlidadPickerProps } from "./IFormModadlidadPickerProps";
import { useContextUniversidad } from "../../../../contexts/ContextUniversidad/ContextUniversidad";


export const FormModalidadPicker = ({

    universidadId,
    lineanegocioId,
    onModalidadChanged,
    onCarreraChanged


}: IFormModadlidadPickerProps) => {

    const {
        LineaNegocio
    } = useContextUniversidad();
    const { isLoading, setIsLoading } = useIsLoading(true);
    const { ApiService } = useContextApiService();
    const [opciones, setOpciones] = useState<IRecordModalidad[]>([]);
    const [selectedRecord, setSelectedRecord] = useState<IRecordModalidad | undefined>(undefined);


    useEffect(() => {

        const getOpciones = async () => {

            const response = await ApiService.Admin.Modalidades.GetByUniversidadLineaNegocioId(
                universidadId,
                lineanegocioId
            );

            if (response.error) {
                return
            }

            setOpciones(response.registros);
            setIsLoading(false);

        };

        getOpciones().catch(console.error);

    }, [LineaNegocio]);


    useEffect(() => {

        if (opciones.length > 0) {

            setSelectedRecord(opciones[0]);
            onModalidadChanged(opciones[0]);

        }

    }, [opciones])

    return (
        <Grid container spacing={3} sx={{ padding: "1rem" }}>
            {
                isLoading ? (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                ) : (
                    <Grid item xs={12} container spacing={3} justifyContent="center">
                        <Grid item xs={12}>
                            <Tabs
                                value={selectedRecord?.chainId}
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                {
                                    opciones.map((opcion, index) => (
                                        <Tab key={index} label={opcion.nombre} value={opcion.chainId} onClick={() => {
                                            onModalidadChanged(opcion);
                                            setSelectedRecord(opcion)
                                        }} />
                                    ))
                                }
                            </Tabs>
                        </Grid>
                        {
                            selectedRecord === undefined ? (
                                <Grid item xs={12}>
                                    <Typography variant="h5" textAlign="center">
                                        Selecciona una Modalidad
                                    </Typography>
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <ControlListCarreras
                                        modalidad={selectedRecord}
                                        OnCarreraChange={onCarreraChanged}
                                    />
                                </Grid>
                            )
                        }
                    </Grid>
                )
            }
        </Grid>
    )

}