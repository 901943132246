import { Grid } from "@mui/material";
import { FormUserInitialForm } from "../../Forms/User/FormUserInitialForm/FormUserInitialForm";
import { useContextUniversidad } from "../../../contexts/ContextUniversidad/ContextUniversidad";



export const SectionFormularioInicial = () => {

    const {
        Universidad,
        ConfigStyle,
        UpdateContextProp
    } = useContextUniversidad();

    return (
        <Grid id="form-inicial" container sx={{
            height: '100vh',
            minHeight: '100vh',
        }}>
            <Grid item xs={12} md={6} container spacing={3} sx={{
                background: `url('${ConfigStyle.urlFondoBienvenida}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: 'auto'
            }} />
            <Grid item xs={12} md={6} container spacing={0} sx={{
                background: ConfigStyle.bgColor,
                display: "flex",
                alignContent: {
                    xs: "flex-start",
                    md: "center"
                },
            }}>
                <Grid item xs={12} textAlign="center" sx={{ paddingTop: "1rem", paddingBottom: "3rem" }}>
                    <img src={ConfigStyle.urlLogo} alt={Universidad.nombre} style={{ maxHeight: '120px', objectFit: 'none' }} />
                </Grid>
                <Grid item xs={12}>
                    <FormUserInitialForm
                        onRecordChanged={(record) => {
                            UpdateContextProp("AppUser", record);
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
        // <Grid id="form-inicial" container spacing={3} sx={{ height: '100vh' }}>
        //     <Grid item xs={12} container spacing={3} sx={{ display: { xs: "none", sm: "none", md: "inherit" } }}>
        //         <Grid item xs={12} md={6} sx={{
        //             background: `url('${ConfigStyle.urlFondoBienvenida}')`,
        //             backgroundSize: 'cover',
        //             backgroundPosition: 'center',
        //             backgroundRepeat: 'no-repeat',
        //         }}
        //         />
        //         <Grid item container spacing={3} xs={12} md={6} sx={{
        //             // paddingLeft: { xs: "80px !important", sm: "80px !important", md: "0 !important" },
        //             // paddingRight: "40px",
        //             // display: "flex",
        //             // alignContent: "flex-start",
        //             background: ConfigStyle.bgColor,
        //         }}>
        //             <Grid item xs={12}>
        //                 <img src={ConfigStyle.urlLogo} alt={Universidad.nombre} style={{ maxHeight: '120px', objectFit: 'none' }} />
        //             </Grid>
        //             <Grid item xs={12}>
        //                 <FormUserInitialForm
        //                     onRecordChanged={(record) => {
        //                         UpdateContextProp("AppUser", record);
        //                     }}
        //                 />
        //             </Grid>
        //         </Grid>
        //     </Grid>
        // </Grid >
    )

}