import { IFactoryRegister } from "./IFactoryRegister";

export class FactoryRegister<TKey, TType> implements IFactoryRegister<TKey, TType> {


    private instances = new Map<TKey, TType>();

    public GetContainer(): Map<TKey, TType> {

        return this.instances;

    }

    public GetAllInstances(): TType[] {

        const response: TType[] = [];
        this.instances.forEach((value) => {
            response.push(value);
        });

        return response;

    }

    public Register(keyId: TKey, instance: TType): void {

        if (this.instances) {
            this.instances.set(keyId, instance);
        }

    }

    public GetInstance(keyId: TKey): TType | undefined {

        return this.instances.get(keyId);

    }

}