import { faUniversity, faGear } from "@fortawesome/pro-light-svg-icons";
import { Grid, TextField, FormControlLabel } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useEffect } from "react";
import { defaultRecordPromoPrecio } from "../../../../data/defaults/records/defaultRecordPromoPrecio";
import { usePropChange } from "../../../../hooks/Shared/usePropChange/usePropChange";
import { useValidatePromoPrecio } from "../../../../hooks/validators/useValidatePromoBanner/useValidatePromoPrecio";
import { IRecordPromoPrecio } from "../../../../interfaces/Records/IRecordPromoPrecio";
import { IFormInteractionEditRecord } from "../../../../interfaces/Shared/Forms/IFormInteractionEditRecord";
import { IOSSwitch } from "../../../Shared/IOSSwitch/IOSSwitch";
import { IndicatorHeaderSection } from "../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";
import { ComboServiceSelector } from "../../../Shared/ComboServiceSelector/ComboServiceSelector";
import { IRecordTipoPrecio } from "../../../../interfaces/Records/IRecordTipoPrecio";
import { useContextApiService } from "../../../../contexts/ContextApiService/ContextApiService";
import { defaultRecordTipoPrecio } from "../../../../data/defaults/records/defaultRecordTipoPrecio";
import { IRecordRecinto } from "../../../../interfaces/Records/IRecordRecinto";
import { IRecordLineaNegocio } from "../../../../interfaces/Records/IRecordLineaNegocio";
import { defaultRecordRecinto } from "../../../../data/defaults/records/defaultRecordRecinto";
import { defaultRecordLineaNegocio } from "../../../../data/defaults/records/defaultRecordLineaNegocio";


export const FormAdminPromoPrecio = ({

    defaultRecord,
    onRecordChanged

}: IFormInteractionEditRecord<IRecordPromoPrecio>) => {

    const { ApiService } = useContextApiService();
    const { Record, OnTextInputChanged, OnCheckBoxChanged, OnPropChanged } = usePropChange<IRecordPromoPrecio>(
        defaultRecord ?? defaultRecordPromoPrecio()
    );
    const defaultTipoPrecio: IRecordTipoPrecio = {
        ...defaultRecordTipoPrecio(),
        chainId: Record.tipoPrecioId
    };

    const defaultRecionto: IRecordRecinto = {
        ...defaultRecordRecinto(),
        chainId: Record.recintoId
    };

    const defaultLineaNegocio: IRecordLineaNegocio = {
        ...defaultRecordLineaNegocio(),
        chainId: Record.lineaNegocioId
    };

    const { validate } = useValidatePromoPrecio();


    const onSelectionRecinto = (record: IRecordRecinto | undefined) => {

        if (record === undefined) {
            return;
        }

        OnPropChanged("recintoId", record.chainId);

    }

    const onSelectionLinea = (record: IRecordLineaNegocio | undefined) => {

        if (record === undefined) {
            return;
        }

        OnPropChanged("lineaNegocioId", record.chainId);

    }


    const onSelectionChange = (record: IRecordTipoPrecio | undefined) => {

        if (record === undefined) {
            return;
        }

        OnPropChanged("tipoPrecioId", record.chainId);

    }


    useEffect(() => {

        if (validate(Record)) {

            console.log("Record is valid", Record);
            onRecordChanged(Record)

        }

    }, [Record]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} container spacing={3}>
                <IndicatorHeaderSection
                    title="Datos Generales"
                    icon={faUniversity}
                />
                <Grid item xs={12}>
                    <TextField
                        value={Record.nombre}
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                        name="nombre"
                        type="text"
                        onChange={OnTextInputChanged("nombre")}
                        error={Record.nombre.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={Record.descripcion}
                        label="Descripcion"
                        variant="outlined"
                        fullWidth
                        name="descripcion"
                        type="text"
                        multiline
                        rows={5}
                        onChange={OnTextInputChanged("descripcion")}
                        error={Record.descripcion.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DateTimePicker
                        disablePast
                        label="Vigencia Inicio"
                        value={Record.vigenciaInicio}
                        onChange={(newValue) => {
                            OnPropChanged("vigenciaInicio", newValue);
                        }}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                error: Record.vigenciaInicio === null,
                                required: true
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DateTimePicker
                        disablePast
                        label="Vigencia Fin"
                        value={Record.vigenciaFin}
                        onChange={(newValue) => {
                            OnPropChanged("vigenciaFin", newValue);
                        }}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                error: Record.vigenciaFin === null,
                                required: true
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ComboServiceSelector<IRecordRecinto>
                        id="combo-campus"
                        label="Campus"
                        isRequired
                        queryService={() => {
                            return ApiService.Admin.Recintos.GetByUniversidadId(Record.universidadId);
                        }}
                        onSelectionChange={onSelectionRecinto}
                        defaultRecord={defaultRecionto}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ComboServiceSelector<IRecordLineaNegocio>
                        id="combo-linea"
                        label="Linea de Negocio"
                        isRequired
                        queryService={() => {
                            return ApiService.Admin.LineaNegocio.GetByUniversidaId(Record.universidadId);
                        }}
                        onSelectionChange={onSelectionLinea}
                        defaultRecord={defaultLineaNegocio}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ComboServiceSelector<IRecordTipoPrecio>
                        id="combo-tipo-precio"
                        label="Tipo Precio Aplicable"
                        queryService={() => {
                            return ApiService.Admin.TipoPrecio.GetAll();
                        }}
                        onSelectionChange={onSelectionChange}
                        defaultRecord={defaultTipoPrecio}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.porcentajeDescuento}
                        label="Porcentaje de Descuento"
                        variant="outlined"
                        fullWidth
                        name="porcentajeDescuento"
                        type="number"
                        onChange={OnTextInputChanged("porcentajeDescuento")}
                        error={Record.porcentajeDescuento <= 0 || Record.porcentajeDescuento > 100}
                        required
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={3}>
                <IndicatorHeaderSection
                    title="Configuración Registro"
                    icon={faGear}
                />
                <Grid item xs={12} md={4}>
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                checked={Record.active}
                                onChange={OnCheckBoxChanged("active")}
                            />
                        }
                        label="¿Registro Activo?"
                    />
                </Grid>
            </Grid>

        </Grid>
    );

}