import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";
import moment from "moment";
import { FORMATS } from "../../../config/FORMATS";
import { IRecordCarrera } from "../../../interfaces/Records/IRecordCarrera";
import { ITableRecordFormater } from "../../Shared/_TableCore/ITableRecordFormater";
import { TableDataCell } from "../../Shared/_TableCore/TableDataCell";
import { TableRowCell } from "../../Shared/_TableCore/TableRowCell";
import { TableDataTrueFalse } from "../TableDataTrueFalse/TableDataTrueFalse";



export const TableFormatAdminCarreras = ({

    record, OnEditRecord

}: ITableRecordFormater<IRecordCarrera>) => {

    return (
        <TableRowCell hover>
            <TableDataCell>
                <Button variant="outlined" color="secondary" onClick={() => { OnEditRecord(record); }}>
                    <FontAwesomeIcon icon={faPencil} />
                </Button>
            </TableDataCell>
            <TableDataCell align="left">
                <Typography variant="body1">
                    {record.nombre}
                </Typography>
            </TableDataCell>
            <TableDataCell align="left">
                <Typography variant="body1">
                    {record.clave}
                </Typography>
            </TableDataCell>
            <TableDataCell align="left">
                <Typography variant="body1">
                    {record.lineaNegocioNombre}
                </Typography>
            </TableDataCell>
            <TableDataTrueFalse
                value={record.active}
                trueLabel="Activo"
                falseLabel="Inactivo" />
            <TableDataCell align="center">
                <Typography variant="body1">
                    {moment(record.created).format(FORMATS.DATE_FORMAT_TO_TXT)}
                </Typography>
            </TableDataCell>
            <TableDataCell align="center">
                <Typography variant="body1">
                    {moment(record.updated).format(FORMATS.DATE_FORMAT_TO_TXT)}
                </Typography>
            </TableDataCell>
        </TableRowCell>
    );

};
