import { faCogs, faUsers } from "@fortawesome/pro-light-svg-icons";
import { MODULE_ADM_CATALOG } from "../../../config/MODULE_CATALOG";
import { IDrawerNavigationLink } from "../../../interfaces/Navigation/IDrawerNavigationLink";
import { ROUTES_KEYS } from "../../../routes/ROUTES_KEYS";



export const defaultNavDrawerAdminMenuConfig = (): IDrawerNavigationLink[] => {

    return [
        {
            moduleId: MODULE_ADM_CATALOG.UNIVERSIDADES_CONFIG,
            icon: faCogs,
            title: "Universidades Configuración",
            path: ROUTES_KEYS.ROOT_ADM_UNIVERSIDADES_CONFIG
        },
        {
            moduleId: MODULE_ADM_CATALOG.USUARIOS,
            icon: faUsers,
            title: "Usuarios y Perfiles",
            path: ROUTES_KEYS.ROOT_ADM_USUARIOS
        },
    ];

};
