import { useContextModalWindow } from "../ContextModalWindow/ContextModalWindow";
import { IUseModalDialogWindow } from "./IUseModalDialogWindow";

export const useModalDialogWindow = (): IUseModalDialogWindow => {

    const { ResetModal, ShowMessageModal } = useContextModalWindow();


    const showModalProgress = (textTitle: string): void => {

        ShowMessageModal({
            variant: "progress",
            title: textTitle,
            messages: [],
            isOpen: true
        });

    }

    const showModalEnviando = (): void => {

        ShowMessageModal({
            variant: "progress",
            title: "Enviando Registro",
            messages: [],
            isOpen: true
        });

    }


    const showModalSuccesUpdate = (): void => {

        ShowMessageModal({
            variant: "success",
            title: "Registro Actualizado",
            messages: ["El registro se actualizo con exito"],
            okLabel: "Cerrar",
            isOpen: true,
            onOkClick: () => {
                ResetModal()
            }
        });

    }


    const showModalSuccessNew = (): void => {

        ShowMessageModal({
            variant: "success",
            title: "Registro Enviado",
            messages: ["Registro creado con exito"],
            okLabel: "Cerrar",
            isOpen: true,
            onOkClick: () => {
                ResetModal()
            }
        });

    }


    const showModalError = (message: string): void => {

        ShowMessageModal({
            variant: "error",
            title: "Error",
            messages: [message],
            okLabel: "Cerrar",
            isOpen: true,
            onOkClick: () => {
                ResetModal()
            }
        });

    }

    const showModalYesNo = (title: string, message: string, okLabel: string, cancelLabel: string, onYesClick: () => void, onCancelClick: () => void): void => {

        ShowMessageModal({
            variant: "warning",
            title: title,
            messages: [message],
            isOpen: true,
            okLabel: okLabel,
            cancelLabel: cancelLabel,
            onOkClick: () => {
                onYesClick();
                ResetModal();
            },
            onCancelClick: () => {
                onCancelClick();
                ResetModal();
            }
        });

    }


    return {
        ShowModalEnviando: showModalEnviando,
        ShowModalSuccessEdit: showModalSuccesUpdate,
        ShowModalSuccessNew: showModalSuccessNew,
        ShowModalError: showModalError,
        ShowMessageModal: showModalYesNo,
        ShowModalProgress: showModalProgress,
        ResetModal: ResetModal
    }

}