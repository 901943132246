import { faBars } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppBar, Box, Drawer, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { GLOBALS } from "../../../../../config/GLOBALS";
import { defaultStateDrawerNavigation } from "../../../../../data/defaults/states/defaultStateDrawerNavigation";
import { IStateDrawerNavigation } from "../../../../../interfaces/States/IStateDrawerNavigation";
import { NavDrawerAdminMenu } from "../../../../Navigation/NavDrawerAdminMenu/NavDrawerAdminMenu";
import { StylerPageAdminDashboard } from "./StylerPageAdminDashboard";
import { RouteAuthProtection } from "../../../../../routes/RouteAuthProtection";
import { useContextModalWindow } from "../../../../Shared/ModalDialog/ContextModalWindow/ContextModalWindow";
import { ModalDialog } from "../../../../Shared/ModalDialog/ModalDialog";
import { useIsLoading } from "../../../../../hooks/Shared/useIsLoading/useIsLoading";


export const PageAdminDashboard = () => {

    const styler = StylerPageAdminDashboard;
    const { IsOpen, ModalState } = useContextModalWindow();
    const [containerState, setContainerState] = useState<IStateDrawerNavigation>(defaultStateDrawerNavigation());

    const handleDrawerOpen = (): void => {

        console.info("Set drawer open", containerState.isDrawerOpen);
        setContainerState({
            ...containerState,
            isDrawerOpen: !containerState.isDrawerOpen
        });

    }

    return (
        <RouteAuthProtection>
            <Box sx={styler.root}>
                <Box component="nav" sx={styler.drawerArea} aria-label="navigation menu">
                    <Drawer
                        variant="temporary"
                        sx={styler.drawerTemporary}
                        open={containerState.isDrawerOpen}
                        onClose={handleDrawerOpen}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        <NavDrawerAdminMenu />
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={styler.drawerPermanent}
                        open
                    >
                        <NavDrawerAdminMenu />
                    </Drawer>
                </Box>
                <Box component="main" sx={styler.contentArea}>
                    <AppBar position="relative" sx={styler.appBar}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                edge="start"
                                onClick={handleDrawerOpen}
                                sx={styler.menuButton}>
                                <FontAwesomeIcon icon={faBars} />
                            </IconButton>
                            <Typography variant="h6" noWrap sx={styler.appTitle}>
                                {/* <img src={AppLogo} style={{ margin: "1rem" }} /> */}
                            </Typography>
                            <Typography variant="subtitle1">
                                Ver: {GLOBALS.APP_VERSION}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Box component="section" sx={styler.sectionContent}>
                        <Grid container sx={styler.mainContentContainer}>
                            <Grid item xs={12} style={{ padding: "1rem" }}>
                                <Outlet />
                                <Toolbar />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
            <ModalDialog
                isOpen={IsOpen}
                messages={ModalState.messages}
                title={ModalState.title}
                variant={ModalState.variant}
                okLabelButton={ModalState.okLabel}
                cancelLabelButton={ModalState.cancelLabel}
                OnCancelClick={ModalState.onCancelClick}
                OnOkClick={ModalState.onOkClick}
            />
        </RouteAuthProtection>
    );

}