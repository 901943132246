import { faSave, faUserPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, LinearProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useContextApiService } from "../../../../../contexts/ContextApiService/ContextApiService";
import { defaultRecordAppUser } from "../../../../../data/defaults/records/defaultRecordAppUser";
import { useIsLoading } from "../../../../../hooks/Shared/useIsLoading/useIsLoading";
import { usePropChange } from "../../../../../hooks/Shared/usePropChange/usePropChange";
import { useValidateAppUser } from "../../../../../hooks/validators/useValidateAppUser/useValidateAppUser";
import { IRecordAppUser } from "../../../../../interfaces/Records/IRecordAppUser";
import { IResponseRecord } from "../../../../../interfaces/Responses/IResponseRecord";
import { FormAdminAppUser } from "../../../../Forms/Admin/FormAdminAppUser/FormAdminAppUser";
import { IndicatorHeaderSection } from "../../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";
import { useModalDialogWindow } from "../../../../Shared/ModalDialog/hook/useModalDialogWindow";



export const PageAdminUsuariosEdit = () => {

    const { Record, SetRecord } = usePropChange<IRecordAppUser>(defaultRecordAppUser());
    const { isLoading, setIsLoading, setLoadingState, stateLoading } = useIsLoading(true);
    const { ApiService } = useContextApiService();
    const { ShowModalEnviando, ShowModalError, ShowModalSuccessEdit } = useModalDialogWindow();
    const { validate: validateAppUser } = useValidateAppUser();
    const navigate = useNavigate();
    const { id } = useParams();

    const onUserChange = (record: IRecordAppUser) => {

        SetRecord(record);

    }

    const onSave = async () => {

        ShowModalEnviando();
        console.info("Record Updated", Record);
        const response = await ApiService.Admin.Usuarios.Update(Record);

        if (!response.success) {

            const errorMesage = response.error ?? "Error al guardar el registro";
            ShowModalError(errorMesage);
            return;

        }

        ShowModalSuccessEdit();
        navigate("../");

    }

    useEffect(() => {

        const getData = async () => {

            if (id === undefined) {
                navigate("/");
                return;
            }

            const record = await ApiService.Admin.Usuarios.GetByChainId(id);
            if (!record.success) {

                return;

            }

            SetRecord({
                ...record.registro,
                password: "",
                passwordConfirm: "",
            });


        }

        getData().catch(console.error);

    }, []);

    useEffect(() => {

        console.info("uSEeFFECT rECORD", Record);

        if (Record.chainId.length <= 0) {
            return;
        }

        if (stateLoading.initialLoading) {

            console.info("Loading", Record);
            setLoadingState({
                isLoading: false,
                initialLoading: false,
            });

        }

    }, [Record]);

    return (
        <Grid container  >
            <IndicatorHeaderSection
                title="Editar Registro"
                subtitle="Complete los campos para editar el registro"
                icon={faUserPlus}
            />
            <Grid item xs={12}>
                {
                    isLoading ? (
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    ) : (
                        <FormAdminAppUser
                            defaultRecord={Record}
                            onRecordChanged={onUserChange}
                        />
                    )
                }
            </Grid>
            <Grid item xs={12} container direction="row-reverse" sx={{ mt: "2rem" }}>
                <Grid item xs={12} md={4}>
                    <Button variant="contained" fullWidth disabled={!validateAppUser(Record)} onClick={onSave}>
                        <Typography variant="button">
                            <FontAwesomeIcon icon={faSave} /> Guardar
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )


}