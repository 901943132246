import moment from "moment";
import { IRecordRecinto } from "../../../interfaces/Records/IRecordRecinto";


export const defaultRecordRecinto = (): IRecordRecinto => {

    return {
        active: true,
        calle: "",
        chainId: "",
        clave: "",
        codigoPostal: "",
        colonia: "",
        created: moment(),
        delegacion: "",
        descripcion: "",
        nombre: "",
        numero: "",
        referencia: "",
        universidadId: "",
        universidadNombre: "",
        updated: moment(),
        urlImagen: "",
        whatsApp: "",
        urlGoogleMaps: ""
    };

};


