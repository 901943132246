import { Grid, Typography } from "@mui/material";
import { useContextUniversidad } from "../../../contexts/ContextUniversidad/ContextUniversidad";
import { IControlHeaderLogoProps } from "./IControlHeaderLogoProps";

export const ControlHeaderLogo = ({

    title

}: IControlHeaderLogoProps) => {

    const {
        Universidad,
        ConfigStyle,
    } = useContextUniversidad();

    return (
        <Grid container spacing={3} sx={{ background: ConfigStyle.bgColor, height: "9rem", padding: "1rem" }}>
            <Grid item xs={6} md={6}>
                <Typography variant="h6" color="white">
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
                <img src={ConfigStyle.urlLogo} alt={Universidad.nombre} style={{ width: '100%', maxHeight: '120px', objectFit: 'none' }} />
            </Grid>
        </Grid >
    );

}