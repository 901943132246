import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import { ReactNode } from "react";
import { DialogTitleError } from "../xStyled/DialogTitleError";
import { ModalDialogBrandingText } from "./ModalDialogBrandingText";



export class ModalDialogBrandingError extends ModalDialogBrandingText {


    constructor(icon: IconDefinition) {
        super(icon);
    }


    public GetDialogTitle(title: string): ReactNode {

        return (
            <DialogTitleError>
                <Typography variant="body1" style={{ flex: 10 }}>
                    {title}
                </Typography>
                <FontAwesomeIcon icon={this.icon} style={{ flex: 2 }} />
            </DialogTitleError>
        );

    }


}
