import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, Grid } from "@mui/material";
import { ReactNode } from "react";
import { DialogTitleInfo } from "../xStyled/DialogTitleInfo";
import { BaseModalDialogBranding } from "./BaseModalDialogBranding";


export class ModalDialogBrandingText extends BaseModalDialogBranding {

    constructor(icon: IconDefinition) {
        super(icon);
    }

    public GetDialogTitle(title: string): ReactNode {

        return (
            <DialogTitleInfo>
                <Typography variant="body1" style={{ flex: 10 }}>
                    {title}
                </Typography>
                <FontAwesomeIcon icon={this.icon} style={{ flex: 2 }} />
            </DialogTitleInfo>
        );

    }

    public GetContent(messages: string[]): ReactNode {

        return (
            <Grid container spacing={3}>
                {messages.map((message, index) => (
                    <Grid item xs={12} key={`msg-prop-${index}`}>
                        <Typography variant="subtitle1">
                            {message}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        );

    }

}
