import { faGear, faUniversity } from "@fortawesome/pro-light-svg-icons";
import { FormControlLabel, Grid, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useEffect } from "react";
import { defaultRecordPromoBanner } from "../../../../data/defaults/records/defaultRecordPromoBanner";
import { usePropChange } from "../../../../hooks/Shared/usePropChange/usePropChange";
import { useValidateModalidad } from "../../../../hooks/validators/useValidateModalidad/useValidateModalidad";
import { IRecordPromoBanner } from "../../../../interfaces/Records/IRecordPromoBanner";
import { IFormInteractionEditRecord } from "../../../../interfaces/Shared/Forms/IFormInteractionEditRecord";
import { IOSSwitch } from "../../../Shared/IOSSwitch/IOSSwitch";
import { IndicatorHeaderSection } from "../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";
import { useValidatePromoBanner } from "../../../../hooks/validators/useValidatePromoBanner/useValidatePromoBanner";



export const FormAdminPromoBanner = ({

    defaultRecord,
    onRecordChanged

}: IFormInteractionEditRecord<IRecordPromoBanner>) => {

    const { Record, OnTextInputChanged, OnCheckBoxChanged, OnPropChanged } = usePropChange<IRecordPromoBanner>(
        defaultRecord ?? defaultRecordPromoBanner()
    );
    const { validate } = useValidatePromoBanner();


    useEffect(() => {

        if (validate(Record)) {

            console.log("Record is valid", Record);
            onRecordChanged(Record)

        }

    }, [Record]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} container spacing={3}>
                <IndicatorHeaderSection
                    title="Datos Generales"
                    icon={faUniversity}
                />
                <Grid item xs={12}>
                    <TextField
                        value={Record.nombre}
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                        name="nombre"
                        type="text"
                        onChange={OnTextInputChanged("nombre")}
                        error={Record.nombre.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={Record.mensaje}
                        label="Mensaje"
                        variant="outlined"
                        fullWidth
                        name="mensaje"
                        type="text"
                        multiline
                        rows={5}
                        onChange={OnTextInputChanged("mensaje")}
                        error={Record.mensaje.length <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DateTimePicker
                        disablePast
                        label="Vigencia Inicio"
                        value={Record.vigenciaInicio}
                        onChange={(newValue) => {
                            OnPropChanged("vigenciaInicio", newValue);
                        }}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                error: Record.vigenciaInicio === null,
                                required: true
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DateTimePicker
                        disablePast
                        label="Vigencia Fin"
                        value={Record.vigenciaFin}
                        onChange={(newValue) => {
                            OnPropChanged("vigenciaFin", newValue);
                        }}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                error: Record.vigenciaFin === null,
                                required: true
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.urlImagen}
                        label="Url Imagen del Banner"
                        variant="outlined"
                        fullWidth
                        name="url"
                        type="url"
                        onChange={OnTextInputChanged("urlImagen")}
                        error={Record.urlImagen.length <= 0}
                        required
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={3}>
                <IndicatorHeaderSection
                    title="Configuración Registro"
                    icon={faGear}
                />
                <Grid item xs={12} md={4}>
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                checked={Record.active}
                                onChange={OnCheckBoxChanged("active")}
                            />
                        }
                        label="¿Registro Activo?"
                    />
                </Grid>
            </Grid>

        </Grid>
    );

}