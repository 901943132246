import { IRecordDatosContacto } from "../../../interfaces/Records/IRecordDatosContacto";



export const defaultRecordDatosContacto = (): IRecordDatosContacto => {

    return {
        chainId: "",
        nombre: "",
        telefono: "",
    };

};
