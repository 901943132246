import { faPlusCircle, faUsers } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useContextApiService } from "../../../../../contexts/ContextApiService/ContextApiService";
import { useIsLoading } from "../../../../../hooks/Shared/useIsLoading/useIsLoading";
import { IRecordCarrera } from "../../../../../interfaces/Records/IRecordCarrera";
import { IRecordLineaNegocio } from "../../../../../interfaces/Records/IRecordLineaNegocio";
import { ROUTES_KEYS } from "../../../../../routes/ROUTES_KEYS";
import { ComboServiceSelector } from "../../../../Shared/ComboServiceSelector/ComboServiceSelector";
import { IndicatorHeaderSection } from "../../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";
import { TableAdminCarreras } from "../../../../Tables/TableAdminCarreras/TableAdminCarreras";

export const PageAdminCarrerasHome = () => {

    const { ApiService } = useContextApiService();
    const { isLoading, setIsLoading } = useIsLoading(true);
    const [records, setRecords] = useState<IRecordCarrera[]>([]);
    const [selectedRecordLineaNegocio, setSelectedRecordLineaNegocio] = useState<IRecordLineaNegocio | undefined>(undefined);
    const navigate = useNavigate();


    const onSelectionLinea = async (record: IRecordLineaNegocio | undefined) => {


        if (record === undefined) {
            return;
        }

        console.log("Universidad", record);
        setIsLoading(true);
        const response = await ApiService.Admin.Carreras.GetByLineaNegocioId(record.chainId);
        setRecords(response.registros);
        setSelectedRecordLineaNegocio(record);
        setIsLoading(false);


    }


    const checkIfDisabled = () => {

        if (selectedRecordLineaNegocio === undefined) {
            return true;
        }

        if (selectedRecordLineaNegocio.chainId.length <= 0) {
            return true;
        }

        return false;

    }


    useEffect(() => {

        const getUsers = async () => {

            const response = await ApiService.Admin.Carreras.GetAll();
            console.info("Carreras", response.registros);
            setRecords(response.registros);
            setIsLoading(false);

        };

        getUsers().catch(console.error);

    }, []);


    return (
        <Grid container  >
            <IndicatorHeaderSection
                title="Carreras Registradas"
                icon={faUsers}
                color="secondary"
                subtitle="Gestión de Carreras"
            />
            <Grid container item xs={12}>
                <Grid item xs={12} md={6}>
                    <ComboServiceSelector<IRecordLineaNegocio>
                        id="combo-lineas-negocio"
                        label="Linea de Negocio"
                        queryService={() => {
                            return ApiService.Admin.LineaNegocio.GetAll();
                        }}
                        onSelectionChange={onSelectionLinea}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={12} md={4}>
                    <Button
                        component={Link}
                        to={`${ROUTES_KEYS.ROOT_ADM_NEW}/${selectedRecordLineaNegocio?.chainId}`}
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={checkIfDisabled()}>
                        <Typography variant="button">
                            <FontAwesomeIcon icon={faPlusCircle} /> Crear Registro
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <IndicatorHeaderSection
                    title="Carreras Registradas"
                    icon={faUsers}
                    color="warning"
                />
                {
                    isLoading ? (
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    ) : (
                        <TableAdminCarreras
                            records={records}
                            OnEditRecord={(record) => {
                                console.log("Edit", record);
                                navigate(`${ROUTES_KEYS.ROOT_ADM_EDIT}/${record.chainId}`)
                            }}
                        />
                    )
                }
            </Grid>
        </Grid>
    );

}