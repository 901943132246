import { Grid } from "@mui/material";
import { FormAdminLogin } from "../../../../Forms/Admin/FormAdminLogin/FormAdminLogin";
import { ModalDialog } from "../../../../Shared/ModalDialog/ModalDialog";
import { useContextModalWindow } from "../../../../Shared/ModalDialog/ContextModalWindow/ContextModalWindow";


export const PageAdminLogin = () => {

    const { IsOpen, ModalState } = useContextModalWindow();

    return (
        <>
            <Grid id="form-inicial" container spacing={5} sx={{ height: '100vh', marginTop: "0", background: "linear-gradient(180deg, rgba(39,63,92,1) 0%, rgba(42,40,37,1) 100%);" }}>
                <Grid item xs={12} container spacing={5}>
                    <Grid item xs={12} md={6} sx={{
                        paddingTop: "0 !important",
                        height: "100%",
                        display: { xs: "none", sm: "none", md: "flex" },
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        flexWrap: "wrap",
                    }}>
                        <img src={`${process.env.PUBLIC_URL}/CalculadoraLogoVerdeWhite.png`} alt="login calculadora" style={{ width: '80%', height: '80%', objectFit: 'cover' }} />
                    </Grid>
                    <Grid item container spacing={5} xs={12} md={6} sx={{
                        paddingLeft: { xs: "80px !important", sm: "80px !important", md: "0 !important" },
                        paddingRight: "40px",
                        display: "flex",
                        alignContent: "flex-start",
                    }}>
                        <Grid item xs={12}>
                            <FormAdminLogin />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ModalDialog
                isOpen={IsOpen}
                messages={ModalState.messages}
                title={ModalState.title}
                variant={ModalState.variant}
                okLabelButton={ModalState.okLabel}
                cancelLabelButton={ModalState.cancelLabel}
                OnCancelClick={ModalState.onCancelClick}
                OnOkClick={ModalState.onOkClick}
            />
        </>
    );
}