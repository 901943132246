

export const MODULE_ADM_CATALOG = {

    HOME_ROOT: "E43DCE88-9AE2-4EBE-BC37-1C1A05C20CF5",
    CATALOGOS_ROOT: "1B30469C-5096-4A14-8351-3E88EF79F80C",
    PROMO_ROOT: "5416E7F2-83C2-4878-97B3-73285321C423",
    CONFIG_ROOT: "547E53D8-5B73-48EB-A531-E50451CC826D",
    CARRERAS_CONFIG_ROOT: "AFC2BB70-140B-467C-B4E5-F8C485C8A363",
    LINEAS_NEGOCIO: "288E2BD6-E4AF-4346-91CD-2B7AE0F56DBE",
    UNIVERSIDADES: "726B41F7-F26D-4A0E-9F78-5FC8EA9B58AC",
    UNIVERSIDADES_CONFIG: "AEB0FC26-FF59-4252-8F88-753F74AD4FB4",
    CAMPUS: "6ECAA6B9-DCB2-43A7-AF6D-28205DC0D434",
    CARERAS: "EB43D9B8-D1E6-40C5-B8AC-252543122FDC",
    MODALIDADES: "F5E22EF9-F25C-41C9-B7B6-CB24E4F062A2",
    PROMO_BECAS: "700AEB6F-6B45-466D-8FBE-60FFCE511178",
    PROMO_PRECIO: "209B414E-6392-467A-899E-96FFA5619771",
    PROMO_BANNERS: "5428D06A-9B3A-4ADB-9980-00B3E84CAA9B",
    USUARIOS: "53CAD2EC-93B1-44D7-AB36-8A900BF84D56",

}