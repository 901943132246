import { IRecordAppUser } from "../../../interfaces/Records/IRecordAppUser";
import { IUseValidate } from "../IUseValidate";


export const useValidateAppUserLogin = (): IUseValidate<IRecordAppUser> => {

    const validateAppUser = (appUser: IRecordAppUser): boolean => {

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(appUser.email)) return false;
        if (appUser.password.length <= 8) return false;

        return true;

    }

    return {
        validate: validateAppUser
    };

}