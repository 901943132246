import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { Grid, LinearProgress } from "@mui/material";
import { ReactNode } from "react";
import { ModalDialogBrandingText } from "./ModalDialogBrandingText";



export class ModalDialogBrandingProgress extends ModalDialogBrandingText {

    constructor(icon: IconDefinition) {
        super(icon);
    }

    public GetContent(messages: string[]): ReactNode {

        const textContainer = super.GetContent(messages);

        return (
            <>
                {textContainer}
                <Grid container spacing={3} sx={{ mt: "2rem", mb: "2rem" }}>
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                </Grid>
            </>
        );

    }

}
