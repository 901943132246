import { IContextAuthState } from "../../../contexts/ContextAuthState/ContextAuthState"
import { defaultResponseAuth } from "../../../interfaces/Responses/IResponseAuth"



export const defaultContextAuthState = (): IContextAuthState => {

    return {
        User: defaultResponseAuth(),
        HasToken: false
    }

}


