import { IServiceConnectorAuth } from "../../Admin/ServiceConnectorAuth/IServiceConnectorAuth";
import { ServiceConnectorAuth } from "../../Admin/ServiceConnectorAuth/ServiceConnectorAuth";
import { IServiceConnectorCarreras } from "../../Admin/ServiceConnectorCarrera/IServiceConnectorCarreras";
import { ServiceConnectorCarreras } from "../../Admin/ServiceConnectorCarrera/ServiceConnectorCarrera";
import { IServiceConnectorCarrerasConfiguracion } from "../../Admin/ServiceConnectorCarrerasConfig/IServiceConnectorCarrerasConfiguracion";
import { ServiceConnectorCarrerasConfig } from "../../Admin/ServiceConnectorCarrerasConfig/ServiceConnectorCarrerasConfig";
import { IServiceConnectorContacto } from "../../Admin/ServiceConnectorContacto/IServiceConnectorContacto";
import { ServiceConnectorContacto } from "../../Admin/ServiceConnectorContacto/ServiceConnectorContacto";
import { IServiceConnectorCotizacion } from "../../Admin/ServiceConnectorCotizacion/IServiceConnectorCotizacion";
import { ServiceConnectorCotizacion } from "../../Admin/ServiceConnectorCotizacion/ServiceConnectorCotizacion";
import { IServiceConnectorLineaNegocio } from "../../Admin/ServiceConnectorLineaNegocio/IServiceConnectorLineaNegocio";
import { ServiceConnectorLineaNegocio } from "../../Admin/ServiceConnectorLineaNegocio/ServiceConnectorLineaNegocio";
import { IServiceConnectorModalidades } from "../../Admin/ServiceConnectorModalidades/IServiceConnectorModalidades";
import { ServiceConnectorModalidades } from "../../Admin/ServiceConnectorModalidades/ServiceConnectorModalidades";
import { IServiceConnectorPromosBanners } from "../../Admin/ServiceConnectorPromoBanners/IServiceConnectorPromosBanners";
import { ServiceConnectorPromoBanners } from "../../Admin/ServiceConnectorPromoBanners/ServiceConnectorPromoBanners";
import { IServiceConnectorPromosBeca } from "../../Admin/ServiceConnectorPromoBecas/IServiceConnectorPromosBeca";
import { ServiceConnectorPromoBecas } from "../../Admin/ServiceConnectorPromoBecas/ServiceConnectorPromoBecas";
import { IServiceConnectorPromoPrecio } from "../../Admin/ServiceConnectorPromoPrecio/IServiceConnectorPromoPrecio";
import { ServiceConnectorPromoPrecio } from "../../Admin/ServiceConnectorPromoPrecio/ServiceConnectorPromoPrecio";
import { IServiceConnectorRecintos } from "../../Admin/ServiceConnectorRecintos/IServiceConnectorRecintos";
import { ServiceConnectorRecintos } from "../../Admin/ServiceConnectorRecintos/ServiceConnectorRecintos";
import { IServiceConnectorRoles } from "../../Admin/ServiceConnectorRoles/IServiceConnectorRoles";
import { ServiceConnectorRoles } from "../../Admin/ServiceConnectorRoles/ServiceConnectorRoles";
import { IServiceConnectorTipoPrecio } from "../../Admin/ServiceConnectorTipoPrecio/IServiceConnectorTipoPrecio";
import { ServiceConnectorTipoPrecio } from "../../Admin/ServiceConnectorTipoPrecio/ServiceConnectorTipoPrecio";
import { IServiceConnectorUniversidadesConfiguracion } from "../../Admin/ServiceConnectorUniversidadConfig/IServiceConnectorUniversidadesConfiguracion";
import { ServiceConnectorUniversidadConfig } from "../../Admin/ServiceConnectorUniversidadConfig/ServiceConnectorUniversidadConfig";
import { IServiceConnectorUniversidades } from "../../Admin/ServiceConnectorUniversidades/IServiceConnectorUniversidades";
import { ServiceConnectorUniversidades } from "../../Admin/ServiceConnectorUniversidades/ServiceConnectorUniversidades";
import { IServiceConnectorUsuarios } from "../../Admin/ServiceConnectorUsuarios/IServiceConnectorUsuarios";
import { ServiceConnectorUsuarios } from "../../Admin/ServiceConnectorUsuarios/ServiceConnectorUsuarios";
import { IServiceConnectorAPIAdmin } from "./IServiceConnectorAPIAdmin";


export class ServiceConnectorAPIAdmin implements IServiceConnectorAPIAdmin {

    private _Auth: IServiceConnectorAuth;
    private _Usuarios: IServiceConnectorUsuarios;
    private _Roles: IServiceConnectorRoles;
    private _Universidades: IServiceConnectorUniversidades;
    private _Modalidades: IServiceConnectorModalidades;
    private _LineaNegocio: IServiceConnectorLineaNegocio;
    private _Recintos: IServiceConnectorRecintos;
    private _Carreras: IServiceConnectorCarreras;
    private _CarreraConfiguracion: IServiceConnectorCarrerasConfiguracion;
    private _UniversidadConfiguracion: IServiceConnectorUniversidadesConfiguracion;
    private _Contacto: IServiceConnectorContacto;
    private _Promos_Banners: IServiceConnectorPromosBanners;
    private _Promos_Becas: IServiceConnectorPromosBeca;
    private _Promos_Precios: IServiceConnectorPromoPrecio;
    private _TipoPrecio: IServiceConnectorTipoPrecio;
    private _Cotizacion: IServiceConnectorCotizacion;

    public get Cotizacion(): IServiceConnectorCotizacion {
        return this._Cotizacion;
    }
    public set Cotizacion(v: IServiceConnectorCotizacion) {
        this._Cotizacion = v;
    }


    public get TipoPrecio(): IServiceConnectorTipoPrecio {
        return this._TipoPrecio;
    }
    public set TipoPrecio(v: IServiceConnectorTipoPrecio) {
        this._TipoPrecio = v;
    }
    public get PromosPrecios(): IServiceConnectorPromoPrecio {
        return this._Promos_Precios;
    }
    public set PromosPrecios(v: IServiceConnectorPromoPrecio) {
        this._Promos_Precios = v;
    }
    public get PromosBecas(): IServiceConnectorPromosBeca {
        return this._Promos_Becas;
    }
    public set PromosBecas(v: IServiceConnectorPromosBeca) {
        this._Promos_Becas = v;
    }
    public get PromosBanners(): IServiceConnectorPromosBanners {
        return this._Promos_Banners;
    }
    public set PromosBanners(v: IServiceConnectorPromosBanners) {
        this._Promos_Banners = v;
    }
    public get Contacto(): IServiceConnectorContacto {
        return this._Contacto;
    }
    public set Contacto(v: IServiceConnectorContacto) {
        this._Contacto = v;
    }
    public get UniversidadConfiguracion(): IServiceConnectorUniversidadesConfiguracion {
        return this._UniversidadConfiguracion;
    }
    public set UniversidadConfiguracion(v: IServiceConnectorUniversidadesConfiguracion) {
        this._UniversidadConfiguracion = v;
    }
    public get CarreraConfiguracion(): IServiceConnectorCarrerasConfiguracion {
        return this._CarreraConfiguracion;
    }
    public set CarreraConfiguracion(v: IServiceConnectorCarrerasConfiguracion) {
        this._CarreraConfiguracion = v;
    }
    public get Carreras(): IServiceConnectorCarreras {
        return this._Carreras;
    }
    public set Carreras(v: IServiceConnectorCarreras) {
        this._Carreras = v;
    }

    public get Recintos(): IServiceConnectorRecintos {
        return this._Recintos;
    }
    public set Recintos(v: IServiceConnectorRecintos) {
        this._Recintos = v;
    }

    public get LineaNegocio(): IServiceConnectorLineaNegocio {
        return this._LineaNegocio;
    }
    public set LineaNegocio(v: IServiceConnectorLineaNegocio) {
        this._LineaNegocio = v;
    }
    public get Modalidades(): IServiceConnectorModalidades {
        return this._Modalidades;
    }
    public set Modalidades(v: IServiceConnectorModalidades) {
        this._Modalidades = v;
    }

    public get Universidades(): IServiceConnectorUniversidades {
        return this._Universidades;
    }
    public set Universidades(v: IServiceConnectorUniversidades) {
        this._Universidades = v;
    }

    public get Roles(): IServiceConnectorRoles {
        return this._Roles;
    }
    public set Roles(v: IServiceConnectorRoles) {
        this._Roles = v;
    }


    public get Usuarios(): IServiceConnectorUsuarios {
        return this._Usuarios;
    }
    public set Usuarios(v: IServiceConnectorUsuarios) {
        this._Usuarios = v;
    }


    public get Auth(): IServiceConnectorAuth {
        return this._Auth;
    }
    public set Auth(v: IServiceConnectorAuth) {
        this._Auth = v;
    }


    constructor() {

        this._Auth = new ServiceConnectorAuth();
        this._Usuarios = new ServiceConnectorUsuarios();
        this._Roles = new ServiceConnectorRoles();
        this._Universidades = new ServiceConnectorUniversidades();
        this._Modalidades = new ServiceConnectorModalidades();
        this._LineaNegocio = new ServiceConnectorLineaNegocio();
        this._Recintos = new ServiceConnectorRecintos();
        this._Carreras = new ServiceConnectorCarreras();
        this._CarreraConfiguracion = new ServiceConnectorCarrerasConfig();
        this._UniversidadConfiguracion = new ServiceConnectorUniversidadConfig();
        this._Contacto = new ServiceConnectorContacto();
        this._Promos_Banners = new ServiceConnectorPromoBanners();
        this._Promos_Becas = new ServiceConnectorPromoBecas();
        this._Promos_Precios = new ServiceConnectorPromoPrecio();
        this._TipoPrecio = new ServiceConnectorTipoPrecio();
        this._Cotizacion = new ServiceConnectorCotizacion();

    }

}