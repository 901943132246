import { styled, TableRow, TableRowProps } from "@mui/material";


export const TableRowCell = styled(TableRow)<TableRowProps>(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },

    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
