import { STYLES_APP } from "../../../../../theme/STYLES_APP";

const drawerWidth = STYLES_APP.DRAWER_WIDTH;

export const StylerPageAdminDashboard = {
    root: {
        display: 'flex',
        backgroundColor: STYLES_APP.ROOT_BODY_BG,
        maxHeight: '958px',
        height: '958px',
        with: '100%',
    },
    appBar: {

    },
    drawerArea: {
        width: { md: drawerWidth },
        flexShrink: { md: 0 },
        backgroundColor: STYLES_APP.COLOR_30,
        minHeight: "100vh",
    },
    contentArea: {
        flex: "1 1 0%",
        maxWidth: "100%",
        flexDirection: 'column',
        width: { xs: "100%", md: `calc(100% - ${drawerWidth}px)` },
        // paddingLeft: '50px',
        // paddingRight: '35px',
    },
    drawerTemporary: {
        display: { xs: 'block', md: 'none' },
        '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            position: "absolute",
            backgroundColor: STYLES_APP.COLOR_30,
            border: 'none',
            overflow: 'hidden',
        },
    },
    drawerPermanent: {
        display: { xs: 'none', md: 'block' },
        '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            position: "relative",
            backgroundColor: 'transparent',
            border: 'none',
            overflow: 'hidden',
        },
    },
    menuButton: {
        marginRight: 2,
        display: { md: 'none' }
    },
    appTitle: {
        flexGrow: 1,
    },
    sectionContent: {
        width: "99%",
        height: "93%",
        overflowY: "scroll",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        marginLeft: "1rem",
        marginRight: "1rem"
    },
    mainContentContainer: {
        flexGrow: 1,
        borderRadius: "1rem",
        backgroundColor: STYLES_APP.MAIN_CONTAINER_PANEL,
        margin: "1rem 0 0 0",
        border: `0.5rem solid ${STYLES_APP.MAIN_CONTAINER_PANEL_BORDER_COLOR}`,
        width: "99%"
    }
};
