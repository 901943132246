import { createContext, PropsWithChildren, useState, useContext } from "react";
import { IFactoryRegister } from "../../../../utils/FactoryRegister/IFactoryRegister";
import { ModalDialogVariantType } from "../ModalDialogVariantType";
import { IContextModalWindowHandlers } from "./IContextModalWindowHandlers";
import { IContextModalWindowInitializers } from "./IContextModalWindowInitializers";
import { IModalBrandingProps } from "./IModalBrandingProps";
import { IStateModalWindow } from "./IStateModalWindow";
import { defaultModalWindowState } from "./defaultModalWindowState";

export const ContextModalWindow = createContext<IContextModalWindowHandlers>({} as IContextModalWindowHandlers);

export const ContextModalWindowProvider = ({ children, modalFactory }: PropsWithChildren<IContextModalWindowInitializers>): JSX.Element => {

    const avaliableModals: IFactoryRegister<ModalDialogVariantType, IModalBrandingProps> = modalFactory;
    const [modalState, setModalState] = useState<IStateModalWindow>(defaultModalWindowState());

    const resetModal = (): void => {

        setModalState(defaultModalWindowState());

    }

    const showMessageModal = (showModalParams: IStateModalWindow): void => {

        console.info("Update Context Modal")
        const newModalState = showModalParams;
        newModalState.isOpen = true;
        setModalState(newModalState);

    }

    return (
        <ContextModalWindow.Provider value={{
            IsOpen: modalState.isOpen,
            ModalState: modalState,
            ResetModal: resetModal,
            ShowMessageModal: showMessageModal,
            AvaliableModals: avaliableModals
        }
        }>
            {children}
        </ContextModalWindow.Provider>
    );

}


export const useContextModalWindow = (): IContextModalWindowHandlers => {

    const context = useContext(ContextModalWindow);

    if (context === undefined) {
        throw new Error('useContextContextModalWindow debe usarse dentro de ContextModalWindowProvider');
    }

    return context;

}