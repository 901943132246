import { IRecordModalidad } from "../../../interfaces/Records/IRecordModalidad";
import { IUseValidate } from "../IUseValidate";



export const useValidateModalidad = (): IUseValidate<IRecordModalidad> => {

    const validate = (record: IRecordModalidad): boolean => {

        if (record.nombre.length <= 0) return false;
        if (record.clave.length <= 0) return false;

        return true;

    }

    return {
        validate
    };

}
