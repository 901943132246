import { styled, DialogTitle, DialogTitleProps } from "@mui/material";



export const DialogTitleInfo = styled(DialogTitle)<DialogTitleProps>(({ theme }) => ({
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    marginBottom: "1rem",
    display: "flex",
}));
