import { faBan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@mui/material";


export const PageUserNotFound = () => {

    return (
        <Grid container  >
            <Grid item xs={12}>
                <Typography variant="h4" component="h1">
                    <FontAwesomeIcon icon={faBan} /> Pagina no encontrada
                </Typography>
            </Grid>
        </Grid>
    );

}