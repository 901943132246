import axios from "axios";
import { END_POINT_CATALOG } from "../../../config/END_POINT_CATALOG";
import { IFilterAppUsers } from "../../../interfaces/Filters/IFilterAppUsers";
import { IRecordAppUser } from "../../../interfaces/Records/IRecordAppUser";
import { IResponseRecord } from "../../../interfaces/Responses/IResponseRecord";
import { IResponseRecords } from "../../../interfaces/Responses/IResponseRecords";
import { ServiceConnectorAPIBase } from "../../Core/ServiceConnectorAPIBase/ServiceConnectorAPIBase";
import { IServiceConnectorUsuarios } from "./IServiceConnectorUsuarios";



export class ServiceConnectorUsuarios extends ServiceConnectorAPIBase implements IServiceConnectorUsuarios {

    constructor() {

        super(`v1/${END_POINT_CATALOG.USUARIOS}`);

    }

    public async GetActive(): Promise<IResponseRecords<IRecordAppUser>> {

        const response = await axios.get(`${this.BaseUrl}/activos`);
        return response.data;

    }

    public async GetById(id: number): Promise<IResponseRecords<IRecordAppUser> | undefined> {

        const response = await axios.get(`${this.BaseUrl}/${id}`);
        return response.data;

    }

    public async GetByChainId(chainId: string): Promise<IResponseRecord<IRecordAppUser>> {

        const response = await axios.get(`${this.BaseUrl}/chain/${chainId}`);

        console.info("GetByChainId", response.data);

        return response.data;



    }

    public async Add(record: IRecordAppUser): Promise<IResponseRecord<IRecordAppUser>> {

        const response = await axios.post(this.BaseUrl, {
            "email": record.email,
            "password": record.password,
            "appUserDetails": {
                "nombre": record.appUserDetails.nombre,
                "apellidoPaterno": record.appUserDetails.apellidoPaterno,
                "apellidoMaterno": record.appUserDetails.apellidoMaterno,
            },
            "rolesIds": record.rolesIds,
            "universidades": record.universidades
        });

        return response.data;

    }


    public async GetAll(): Promise<IResponseRecords<IRecordAppUser>> {

        const response = await axios.get(this.BaseUrl);
        return response.data;

    }


    GetByFilter(filter: IFilterAppUsers): Promise<IRecordAppUser> {
        throw new Error("Method not implemented.");
    }

    public async Update(record: IRecordAppUser): Promise<IResponseRecord<IRecordAppUser>> {

        const response = await axios.put(this.BaseUrl, {
            "email": record.email,
            "password": record.password,
            "appUserDetails": {
                "nombre": record.appUserDetails.nombre,
                "apellidoPaterno": record.appUserDetails.apellidoPaterno,
                "apellidoMaterno": record.appUserDetails.apellidoMaterno,
            },
            "rolesIds": record.rolesIds,
            "universidades": record.universidades
        });

        return response.data;

    }

}
