import { faUserPlus, faSave } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useContextApiService } from "../../../../../contexts/ContextApiService/ContextApiService";
import { defaultRecordPromoBanner } from "../../../../../data/defaults/records/defaultRecordPromoBanner";
import { usePropChange } from "../../../../../hooks/Shared/usePropChange/usePropChange";
import { useValidatePromoBanner } from "../../../../../hooks/validators/useValidatePromoBanner/useValidatePromoBanner";
import { IRecordPromoBanner } from "../../../../../interfaces/Records/IRecordPromoBanner";
import { FormAdminPromoBanner } from "../../../../Forms/Admin/FormAdminPromoBanner/FormAdminPromoBanner";
import { IndicatorHeaderSection } from "../../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";
import { useModalDialogWindow } from "../../../../Shared/ModalDialog/hook/useModalDialogWindow";
import { IRecordPromoBeca } from "../../../../../interfaces/Records/IRecordPromoBeca";
import { defaultRecordPromoBeca } from "../../../../../data/defaults/records/defaultRecordPromoBeca";
import { useValidatePromoBecas } from "../../../../../hooks/validators/useValidatePromoBanner/useValidatePromoBecas";
import { FormAdminPromoBecas } from "../../../../Forms/Admin/FormAdminPromoBecas/FormAdminPromoBecas";

export const PageAdminPromoBecasNew = () => {

    const { id } = useParams();
    const { Record, SetRecord } = usePropChange<IRecordPromoBeca>({
        ...defaultRecordPromoBeca(),
        universidadId: id ?? ""
    });
    const { ApiService } = useContextApiService();
    const { ShowModalEnviando, ShowModalError, ShowModalSuccessNew } = useModalDialogWindow();
    const { validate } = useValidatePromoBecas();
    const navigate = useNavigate();

    const onChange = (record: IRecordPromoBeca) => {

        if (id !== undefined) {
            record.universidadId = id;
            SetRecord(record);
        }

    }

    const onSave = async () => {

        ShowModalEnviando();
        const response = await ApiService.Admin.PromosBecas.Add(Record);

        if (!response.success) {

            const errorMesage = response.error ?? "Error al guardar el registro";
            ShowModalError(errorMesage);
            return;

        }

        ShowModalSuccessNew();
        navigate("../");

    }

    useEffect(() => {

        if (id === undefined) {
            ShowModalError("No se ha seleccionado una universidad");
            navigate("../");
        }

    }, []);

    return (
        <Grid container  >
            <IndicatorHeaderSection
                title="Nuevo Registro"
                subtitle="Complete los campos para agregar un registro"
                icon={faUserPlus}
            />
            <Grid item xs={12}>
                <FormAdminPromoBecas
                    defaultRecord={Record}
                    onRecordChanged={onChange}
                />
            </Grid>
            <Grid item xs={12} container direction="row-reverse" sx={{ mt: "2rem" }}>
                <Grid item xs={12} md={4}>
                    <Button variant="contained" fullWidth disabled={!validate(Record)} onClick={onSave}>
                        <Typography variant="button">
                            <FontAwesomeIcon icon={faSave} /> Guardar
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )

}