import moment from "moment";
import { IRecordPromoBanner } from "../../../interfaces/Records/IRecordPromoBanner";


export const defaultRecordPromoBanner = (): IRecordPromoBanner => {


    return {
        active: true,
        chainId: "",
        clave: "",
        descripcion: "",
        nombre: "",
        created: moment(),
        urlImagen: "",
        mensaje: "",
        universidadId: "",
        universidadNombre: "",
        updated: moment(),
        vigenciaFin: moment(),
        vigenciaInicio: moment()
    };

};
