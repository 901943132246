import { IRecordUniversidad } from "../../../interfaces/Records/IRecordUniversidad";
import { IUseValidate } from "../IUseValidate";

export const useValidateUniversidad = (): IUseValidate<IRecordUniversidad> => {

    const validateUniversidad = (record: IRecordUniversidad): boolean => {

        if (record.nombre === "") return false;
        if (record.cssName === "") return false;
        if (record.urlPolitica === "") return false;
        if (record.urlCrm === "") return false;
        if (record.urlLogo === "") return false;
        if (record.whatsapp === "") return false;

        return true;

    }

    return {
        validate: validateUniversidad
    };

}


